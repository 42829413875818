import React from 'react';
import { Box, Text } from '@chakra-ui/react';

import { Colors } from '~/constants/colors';

interface ChooseOptionItemProps {
  label: string;
  isSelected?: boolean;
  onSelect: () => void;
}

export const ChooseOptionItem: React.FC<ChooseOptionItemProps> = function ChooseOptionItem({
  label,
  isSelected,
  onSelect,
}) {
  return (
    <Box
      onClick={onSelect}
      minWidth="320px"
      minHeight="60px"
      padding="20px 24px"
      borderRadius="20px"
      border="1px"
      borderColor={isSelected ? Colors.Orange : Colors.Beige}
      backgroundColor={Colors.Beige}
      cursor="pointer"
      fontWeight="500"
      _hover={isSelected ? undefined : { borderColor: Colors.Orange }}
    >
      <Text align="left" fontSize="20px" lineHeight="26px" color={Colors.Brown}>
        {label}
      </Text>
    </Box>
  );
};
