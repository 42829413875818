import React from 'react';

import { Box, Flex, Spinner } from '@chakra-ui/react';

import { Colors } from '~/constants/colors';

export const OverlayLoader: React.FC = function OverlayLoader() {
  return (
    <Flex position="fixed" direction="column" alignItems="center" justifyContent="center" w="100%" h="100%" zIndex="2">
      <Box position="absolute" w="100%" h="100%" bg={Colors.White} opacity={0.4} />
      <Spinner color={Colors.Orange} />
    </Flex>
  );
};
