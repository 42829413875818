import React, { ReactElement } from 'react';

export interface StepProps {
  step?: number | string;
}

export const Step: React.FC<React.PropsWithChildren<StepProps>> = function Step({ children }) {
  return <>{children}</>;
};

export const isStepElement = (element: any): element is ReactElement<StepProps> =>
  ['string', 'number'].includes(typeof element?.props?.step);
