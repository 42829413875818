import { InputProps } from '@chakra-ui/input/dist/input';
import { SelectProps } from '@chakra-ui/react';

import { Colors } from '~/constants/colors';
import React from 'react';

export const COMMON_INPUT_STYLES: InputProps & SelectProps = {
  width: { base: '327px', lg: '400px' },
  color: Colors.Brown,
};

export const OUTLINE_INPUT_STYLES: InputProps & SelectProps = {
  borderRadius: '16px',
  size: 'lg',
  height: '60px',
  _placeholder: { color: Colors.GreySemiLight, fontSize: '20px', lineHeight: '36px', fontWeight: '700' },
  fontSize: '20px',
  lineHeight: '20px',
  fontWeight: '500',
  borderColor: Colors.Transparent,
  focusBorderColor: Colors.Orange,
  _hover: {},
  color: Colors.Brown,
  backgroundColor: Colors.White,
};

export const FLUSHED_INPUT_STYLES: InputProps & SelectProps = {
  fontSize: '24px',
  lineHeight: '36px',
  paddingBottom: '16px',
  borderBottomColor: Colors.GreySemiLight,
  focusBorderColor: Colors.Orange,
  borderBottomWidth: '2px',
  _focus: { borderBottomWidth: '1px' },
  _placeholder: {
    color: Colors.GreySemiLight,
    fontWeight: '700',
  },
};

export function useInputStyle(variant: InputProps['variant']): InputProps & SelectProps {
  const additionalStyles = React.useMemo(() => {
    switch (variant) {
      case 'flushed':
        return FLUSHED_INPUT_STYLES;
      case 'outline':
        return OUTLINE_INPUT_STYLES;
      default:
        return;
    }
  }, [variant]);

  return React.useMemo(
    () => ({
      ...COMMON_INPUT_STYLES,
      ...additionalStyles,
    }),
    [additionalStyles],
  );
}
