import { PolicyCodes } from '@shared/constants/policyCodes';

import { useSelectedPolicyData } from '~/hooks/insurance-data-provider/policy/useSelectedPolicyData';
import { useIsInsuranceDataContextDogPetType } from '~/hooks/insurance-data-provider/useIsInsuranceDataContextDogPetType';

import { cleanPolicyPerksToDisplay } from '~/helpers/insuranceCoveragePolicies/insuranceCoveragePolicies';

export function useSelectedPolicyPerks(policyCode: PolicyCodes) {
  const selectedPolicyData = useSelectedPolicyData(policyCode);
  const isDogPetType = useIsInsuranceDataContextDogPetType();

  return selectedPolicyData && cleanPolicyPerksToDisplay(selectedPolicyData.productBenefits, isDogPetType);
}
