import React from 'react';

import { useUiStateContextPatchUiState } from '~/hooks/useUiStateContextUpdateUi';

export function useUiStateUpdateIsLoading() {
  const patchUiState = useUiStateContextPatchUiState();

  const updateUiIsLoading = React.useCallback(
    (isLoading: boolean) => {
      patchUiState && patchUiState({ isLoading });
    },
    [patchUiState],
  );

  return updateUiIsLoading;
}
