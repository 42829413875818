import React, { SVGProps } from 'react';
import DatePicker from 'react-date-picker/dist/entry.nostyle';
import { useField } from 'formik';
import { Box } from '@chakra-ui/react';

import moment from 'moment';

import {
  InsuranceFormValues,
  POLICY_START_DATE_FIELD,
} from '~/components/insurance-questionnaire/insurance-questionnaire.schema';

import { Colors } from '~/constants/colors';

import { getCurrentMoment } from '~/helpers/date';

import { ReactComponent as ArrowRightIcon } from '~/assets/icons/arrow-right.svg';
import { ReactComponent as ArrowLeftIcon } from '~/assets/icons/arrow-left.svg';

import '~/components/insurance-questionnaire/policy-start-date-question-view/policy-start-date-calendar-input/policyStartDateCalendarInput.scss';

const DAY_NAMES_LABEL_LIST = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
const TODAY_DATE = getCurrentMoment().utc();
const MIN_CALENDAR_DATE = TODAY_DATE.toDate();
const MAX_CALENDAR_DATE = TODAY_DATE.add(30, 'days').toDate();

interface PickerControlButtonProps {
  Icon: React.FC<SVGProps<SVGSVGElement>>;
}

export const PolicyStartDateCalendarInput: React.FC = function PolicyStartDateCalendarInput() {
  const [field, , formikHelper] =
    useField<InsuranceFormValues[typeof POLICY_START_DATE_FIELD]>(POLICY_START_DATE_FIELD);

  const setStartPolicyDate = React.useCallback(
    (date: Date) => {
      formikHelper.setTouched(true);
      formikHelper.setValue(moment(date).utc().format());
    },
    [formikHelper],
  );

  const formatShortNameWeekday = React.useCallback((_: string, date: Date) => {
    return DAY_NAMES_LABEL_LIST[date.getDay()];
  }, []);

  return (
    <Box className="policy-start-date" width="336px" height="337px">
      <DatePicker
        isOpen
        value={field.value ? new Date(field.value) : null}
        onChange={setStartPolicyDate}
        minDate={MIN_CALENDAR_DATE}
        maxDate={MAX_CALENDAR_DATE}
        prevLabel={<PickerControlButton Icon={ArrowLeftIcon} />}
        nextLabel={<PickerControlButton Icon={ArrowRightIcon} />}
        formatShortWeekday={formatShortNameWeekday}
      />
    </Box>
  );
};

const PickerControlButton: React.FC<PickerControlButtonProps> = function PickerControlButton({ Icon }) {
  return (
    <Box width="9px" height="17px">
      <Icon width="100%" height="100%" color={Colors.Black} />
    </Box>
  );
};
