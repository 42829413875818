import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ProductQuoteSchema, QuoteStorageDto } from '@shared/models/product';

import { InsuranceFormValues } from '~/components/insurance-questionnaire/insurance-questionnaire.schema';
import { InsurancePolicyFormValues } from '~/components/insurance-policy-display-form/insurance-policy-display-questionnaire.schema';

import { useUiStateContextPatchUiState } from '~/hooks/useUiStateContextUpdateUi';
import { useInvokeErrorModal } from '~/hooks/useInvokeErrorModal';

import {
  fetchQuoteById,
  getInsuranceFormValuesFromQuote,
  getPolicyFormValuesFromQuote,
  getPolicyListFromQuote,
} from '~/helpers/quote/quote';
import { getPolicyFormValuesStoredLocally } from '~/helpers/quote/locallyStoredPolicyFormValues';

interface ExternalQuoteRedirectParams {
  setQuoteId: (quoteId: string) => void;
  processQuotesList: (quotes: ProductQuoteSchema[]) => void;
  setFilledInsuranceFormValues: (values: InsuranceFormValues) => void;
  setFilledInsurancePolicyFormValues: (values: InsurancePolicyFormValues) => void;
}

export function useExternalQuoteRedirect({
  setQuoteId,
  setFilledInsuranceFormValues,
  setFilledInsurancePolicyFormValues,
  processQuotesList,
}: ExternalQuoteRedirectParams) {
  const patchUiState = useUiStateContextPatchUiState();
  const { invokeErrorModal } = useInvokeErrorModal();
  const navigate = useNavigate();

  const { quoteId } = useParams();

  const handleQuoteResponse = React.useCallback(
    (response: QuoteStorageDto) => {
      const insuranceFormValues = getInsuranceFormValuesFromQuote(response);
      setFilledInsuranceFormValues(insuranceFormValues);

      const policyFormValues = getPolicyFormValuesFromQuote(response);
      setFilledInsurancePolicyFormValues(policyFormValues);

      const quotes = getPolicyListFromQuote(response);
      processQuotesList(quotes);

      setQuoteId(response.quoteId);
      patchUiState && patchUiState({ insuranceForm: { isCalendarView: true } });

      const isPolicyFormFulfilled = Boolean(getPolicyFormValuesStoredLocally());
      navigate('/quote', { state: { isPolicyFormFulfilled }, replace: true });
    },
    [
      navigate,
      patchUiState,
      processQuotesList,
      setQuoteId,
      setFilledInsuranceFormValues,
      setFilledInsurancePolicyFormValues,
    ],
  );

  React.useEffect(() => {
    if (quoteId) {
      patchUiState && patchUiState({ isQuoteDataForExternalRouteLoading: true, isLoading: true });

      fetchQuoteById(quoteId)
        .then(handleQuoteResponse)
        .catch((e) => {
          invokeErrorModal(e);
        })
        .finally(() => {
          patchUiState && patchUiState({ isQuoteDataForExternalRouteLoading: false, isLoading: false });
        });
    }
  }, [quoteId, patchUiState, handleQuoteResponse, invokeErrorModal]);
}
