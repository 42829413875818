import React from 'react';
import { useField } from 'formik';

// apply callback method on `KeyDown` event
export function useKeyEnterFormikHandler(formikName: string, actionToCall?: () => void) {
  const [, meta, formikHelper] = useField(formikName);

  // don't use `React.useMemo` here, because we will update .value / .error fields on every render
  const isActionDisabled = Boolean(!meta.value || meta.error);

  return React.useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key !== 'Enter') {
        return;
      }

      formikHelper.setTouched(true);

      if (!actionToCall || isActionDisabled) {
        return;
      }

      actionToCall();
    },
    [formikHelper, actionToCall, isActionDisabled],
  );
}
