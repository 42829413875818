import React from 'react';

import { Box, Flex, HStack, Image, Stack, Text, Wrap, WrapItem } from '@chakra-ui/react';

import { FluffyAppBenefits } from '@shared/data/policyBenefits';

import { Header } from '~/components/common/Header';
import { ScreenWithTitleContainer } from '~/components/common/ScreenWithTitleContainer';
import { TitleText } from '~/components/common/TitleText';
import { PerkItem } from '~/components/common/PerkItem';

import { openUrlInNewTab } from '~/helpers/common';
import { trackAdEvent, trackEvent } from '~/helpers/analytics/trackEvent';
import { cleanLocallyStoredQuoteData } from '~/helpers/quote/locallyStoredPolicyFormValues';

import { AdEventType, AnalyticsEvent } from '~/constants/analyticsEvent';
import { LocalStorageKey } from '~/constants/localStorageKey';
import { APP_STORE_APP_PAGE_LINK, PLAY_MARKET_APP_PAGE_LINK } from '~/constants/mobileApp';
import { Colors } from '~/constants/colors';

import { AppPerkImageConfig } from '~/configs/perkInfoConfig';

function openAppStoreAppPage() {
  openUrlInNewTab(APP_STORE_APP_PAGE_LINK);
}

function openGooglePlayAppPage() {
  openUrlInNewTab(PLAY_MARKET_APP_PAGE_LINK);
}

export const ThankYouPage: React.FC = function ThankYouPage() {
  React.useEffect(() => {
    if (localStorage.getItem(LocalStorageKey.SubmittedPolicyFormAt)) {
      trackAdEvent(AdEventType.Purchase);
      trackEvent({ eventName: AnalyticsEvent.QuoteNavigatedBackToThankYouPage });
      localStorage.removeItem(LocalStorageKey.SubmittedPolicyFormAt);
    }
  }, []);

  React.useEffect(() => {
    cleanLocallyStoredQuoteData();
  }, []);

  return (
    <>
      <Header />
      <Flex marginTop={{ base: '60px', lg: '84px' }} direction="column" align="center" padding="0 16px 0 16px">
        <ScreenWithTitleContainer title="Congrats, you’re now part of the family!" isActionButtonHidden>
          <Box marginTop="36px" maxWidth={{ lg: '600px' }}>
            <TitleText
              text="To access these pawesome perks, download the Fluffy app and check your inbox for signup instructions"
              type="SUBTITLE"
            />
          </Box>
          <Stack spacing={{ base: '48px', lg: '64px' }} marginTop="36px" align="center">
            <HStack spacing={{ base: '24px', lg: '56px' }}>
              <GetOnStoreActionButton imgSrc="/images/action-tabs/get-on-app-store.png" onPress={openAppStoreAppPage} />
              <GetOnStoreActionButton
                imgSrc="/images/action-tabs/get-on-google-play.png"
                onPress={openGooglePlayAppPage}
              />
            </HStack>

            <Wrap spacing="16px" maxW={{ lg: '471px' }} justify="center">
              {Object.values(FluffyAppBenefits).map((perkName, idx) => (
                <WrapItem key={idx}>
                  <PerkItem imgSrc={AppPerkImageConfig[perkName]} title={perkName} />
                </WrapItem>
              ))}
            </Wrap>
          </Stack>
        </ScreenWithTitleContainer>

        <Box marginTop={{ base: '16px', lg: '32px' }}>
          <Text fontSize="16px" lineHeight="32px" fontWeight="400" color={Colors.GreyPale}>
            Your policy documents will be arriving in your email shortly. If you do not receive your Welcome Email
            within a few minutes, please check your Spam or Junk folder just in case the email got filtered there
            instead of your Inbox. Please contact 0344 273 2777.
          </Text>
        </Box>
      </Flex>
    </>
  );
};

const GetOnStoreActionButton: React.FC<{ imgSrc: string; onPress: () => void }> = function GetOnStoreActionButton({
  imgSrc,
  onPress,
}) {
  return (
    <Box as="button" _hover={{ opacity: 0.8 }} onClick={onPress}>
      <Image w="148px" height="46px" src={imgSrc} />
    </Box>
  );
};
