import { useSelectedPolicyData } from '~/hooks/insurance-data-provider/policy/useSelectedPolicyData';

import { PolicyCodes } from '@shared/constants/policyCodes';

import { FEE_CONFIG } from '~/configs/fee';

const EXCESS_LABEL_INDEX = 0;

export function useSelectedPolicyCoverLabels(policyCode: PolicyCodes) {
  const selectedPolicyData = useSelectedPolicyData(policyCode);

  if (selectedPolicyData) {
    const labels = [
      'Lifetime Vet Fee cover is for illnesses and injuries. Subject to the underwriter offering renewal and premiums being paid on time with no break in cover, illnesses and injuries will continue to be covered. Pre-existing conditions are excluded from cover. Terms and conditions apply. ',
    ];

    let excessLabel;

    const { percentageExcess, fixedExcess } = selectedPolicyData;

    // in case we need to apply extra percentage to the excess
    if (percentageExcess !== 0) {
      excessLabel = `You only have a ${FEE_CONFIG['ADMIN'].currency}${fixedExcess} excess and a ${percentageExcess}% co-payment of the total claim amount. `;
    } else {
      excessLabel = `You only have a ${FEE_CONFIG['ADMIN'].currency}${fixedExcess} excess on claims. `;
    }

    labels[EXCESS_LABEL_INDEX] += excessLabel;

    return labels;
  }
}
