import React from 'react';
import { StackProps, Stack } from '@chakra-ui/react';
import { useField } from 'formik';

import { PolicyCodes } from '@shared/constants/policyCodes';

import { ActionButton } from '~/components/common/ActionButton';
import { POLICY_CODE_FIELD } from '~/components/insurance-policy-display-form/insurance-policy-display-questionnaire.schema';

import { POLICY_DOCUMENT_STATEMENTS_URL, PRIVACY_POLICY_FLUFFY_URL } from '~/constants/documents';

import { useSelectedPolicyData } from '~/hooks/insurance-data-provider/policy/useSelectedPolicyData';

import { getPolicyBenefitData } from '~/helpers/insuranceCoveragePolicies/insuranceCoveragePolicies';
import { openUrlInNewTab } from '~/helpers/common';

import { ProductInfoLinksConfig, VetFees } from '~/configs/productInfoLinksConfig';

interface ProductButtonsProps {
  style?: StackProps;
}

export const ProductButtons: React.FC<ProductButtonsProps> = function ProductButtons({ style }) {
  const [selectedPolicyCodeField] = useField<PolicyCodes>(POLICY_CODE_FIELD);
  const selectedPlanData = useSelectedPolicyData(selectedPolicyCodeField.value);

  const openPrivacyPolicy = () => {
    openUrlInNewTab(PRIVACY_POLICY_FLUFFY_URL);
  };

  const openProductInformation = () => {
    if (!selectedPlanData) {
      return;
    }

    const vetFeeValue = getPolicyBenefitData(selectedPlanData, 'VET_FEES');

    if (!vetFeeValue) {
      return;
    }

    openUrlInNewTab(ProductInfoLinksConfig[vetFeeValue.amount as VetFees]);
  };

  const openPolicyDocument = () => {
    openUrlInNewTab(POLICY_DOCUMENT_STATEMENTS_URL);
  };

  return (
    <Stack direction={{ base: 'column', lg: 'row' }} spacing="12px" justify="center" {...style}>
      <ActionButton
        labelStyle={{ fontSize: '12px', lineHeight: '24px' }}
        label="Privacy Policy"
        isSecondary
        onPress={openPrivacyPolicy}
      />
      <ActionButton
        labelStyle={{ fontSize: '12px', lineHeight: '24px' }}
        label="Insurance Product Information Document"
        isSecondary
        onPress={openProductInformation}
      />
      <ActionButton
        labelStyle={{ fontSize: '12px', lineHeight: '24px' }}
        label="Policy Document"
        isSecondary
        onPress={openPolicyDocument}
      />
    </Stack>
  );
};
