import React from 'react';
import { Stack } from '@chakra-ui/react';
import { useField } from 'formik';

import { PolicyViewStepProps } from '~/components/insurance-policy-display-form/insurance-policy-display-questionnaire/InsurancePolicyDisplayQuestionnaire';
import { ScreenWithTitleContainer } from '~/components/common/ScreenWithTitleContainer';
import { PolicyStartDateCalendarInput } from '~/components/insurance-questionnaire/policy-start-date-question-view/policy-start-date-calendar-input/PolicyStartDateCalendarInput';
import { ChooseOptionItem } from '~/components/questions/ChooseOptionItem';
import { ChooseOptionFormikItem } from '~/components/questions/ChooseOptionFormikItem';
import {
  InsuranceFormValues,
  POLICY_START_DATE_FIELD,
} from '~/components/insurance-questionnaire/insurance-questionnaire.schema';

import { useUiStateContextUiSchema } from '~/hooks/useUiStateContextUiSchema';
import { useUiStateContextPatchUiState } from '~/hooks/useUiStateContextUpdateUi';

import { getCurrentMoment } from '~/helpers/date';

const TODAY_DATE = getCurrentMoment().utc();

const TODAY_DATE_VALID_FORMAT = TODAY_DATE.format();
const TOMORROW_DATE_VALID_FORMAT = TODAY_DATE.add(1, 'days').format();

export const PolicyStartDateQuestionView: React.FC<PolicyViewStepProps> = function PolicyStartDateQuestionView({
  onValueSelect,
}) {
  const [, meta, formikHelper] = useField<InsuranceFormValues[typeof POLICY_START_DATE_FIELD]>(POLICY_START_DATE_FIELD);

  const patchUiState = useUiStateContextPatchUiState();
  const isCalendarView = Boolean(useUiStateContextUiSchema()?.insuranceForm?.isCalendarView);

  const showCalendar = React.useCallback(() => {
    formikHelper.setValue(null);

    patchUiState && patchUiState({ insuranceForm: { isCalendarView: true } });
  }, [formikHelper, patchUiState]);

  const shouldShowCalendar = isCalendarView;
  const title = shouldShowCalendar
    ? 'Please pick a date when you want your policy to start'
    : 'When would you like your insurance to start?';

  return (
    <ScreenWithTitleContainer
      title={title}
      isActionButtonHidden={!shouldShowCalendar}
      isButtonDisabled={Boolean(meta.error)}
      buttonLabel="Confirm"
      onActionButtonClick={onValueSelect}
    >
      {shouldShowCalendar ? (
        <PolicyStartDateCalendarInput />
      ) : (
        <Stack spacing="12px">
          <ChooseOptionFormikItem
            name={POLICY_START_DATE_FIELD}
            value={TODAY_DATE_VALID_FORMAT}
            label="Today"
            onSelect={onValueSelect}
          />
          <ChooseOptionFormikItem
            name={POLICY_START_DATE_FIELD}
            value={TOMORROW_DATE_VALID_FORMAT}
            label="Tomorrow"
            onSelect={onValueSelect}
          />
          <ChooseOptionItem label="Future date" onSelect={showCalendar} />
        </Stack>
      )}
    </ScreenWithTitleContainer>
  );
};
