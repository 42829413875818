import moment from 'moment';

export function getTodaysDate() {
  return moment().startOf('day');
}

export function getCurrentMoment() {
  return moment();
}

export function getDateStartOfDay(value: string) {
  return moment(value).startOf('day');
}
