import React from 'react';

import { FeaturesConfigSchema } from '@shared/models/featureFlags';

import { FeatureFlagsContext } from '~/providers/FeatureFlagsProvider';

import { getFeatureFlag } from '~/helpers/getFeatureFlag';

// todo [refactoring]: provide a cool return type, based on condition if defaultValue is existing
export function useFeatureFlag<F extends keyof FeaturesConfigSchema>(
  featureFlagName: F,
  defaultValue?: FeaturesConfigSchema[F],
): Optional<FeaturesConfigSchema[F]> {
  const featureFlagsContext = React.useContext(FeatureFlagsContext);

  if (!featureFlagsContext) {
    // Should be handled during development
    throw new Error('Trying to use "featureFlagsContext" without "FeatureFlagsProvider"');
  }

  return getFeatureFlag(featureFlagsContext, featureFlagName, defaultValue);
}
