import React, { PropsWithChildren } from 'react';
import { Checkbox, Text, Stack, CheckboxGroup, HStack, FormLabel } from '@chakra-ui/react';

import { useField, useFormikContext } from 'formik';

import {
  ARE_TERMS_OF_COVER_CONFIRMED_FIELD,
  ARE_POLICY_AND_BUSINESS_DOCUMENTS_READ_FIELD,
  InsurancePolicyFormValues,
} from '~/components/insurance-policy-display-form/insurance-policy-display-questionnaire.schema';
import { ExternalLink } from '~/components/common/ExternalLink';

import { Colors } from '~/constants/colors';
import {
  INSURANCE_ASSUMPTIONS_URL,
  POLICY_DOCUMENT_STATEMENTS_URL,
  PRIVACY_POLICY_CM_URL,
  TERMS_OF_BUSINESS_URL,
} from '~/constants/documents';

export const PolicyStatementsCheckboxSection: React.FC<{
  fieldsWithWarning: Record<string, boolean>;
}> = function PolicyStatementsCheckboxSection({ fieldsWithWarning }) {
  return (
    <CheckboxGroup size="lg" colorScheme={Colors.Orange}>
      <Stack spacing="16px" width="340px">
        <CheckboxWithStatement
          formikFieldName={ARE_TERMS_OF_COVER_CONFIRMED_FIELD}
          showEmptyTickWarning={fieldsWithWarning[ARE_TERMS_OF_COVER_CONFIRMED_FIELD]}
        >
          The terms of the cover we will offer you are based on certain assumptions, which you must read before
          purchasing this Pet Insurance. Please tick to confirm you have read and accept the{' '}
          <ExternalLink url={INSURANCE_ASSUMPTIONS_URL}>Assumptions</ExternalLink>. You accept that the underwriter
          reserves the right to decline in the event of a claim if your dog does not have a registered microchip number
          unless you have an exemption certificate from your vet.
        </CheckboxWithStatement>

        <CheckboxWithStatement
          formikFieldName={ARE_POLICY_AND_BUSINESS_DOCUMENTS_READ_FIELD}
          showEmptyTickWarning={fieldsWithWarning[ARE_POLICY_AND_BUSINESS_DOCUMENTS_READ_FIELD]}
        >
          You should also read the <ExternalLink url={POLICY_DOCUMENT_STATEMENTS_URL}>Policy Document</ExternalLink> and
          Cover-More Blue Insurance Services Limited{' '}
          <ExternalLink url={TERMS_OF_BUSINESS_URL}>Terms of Business</ExternalLink> for your information. Please tick
          to confirm you have read these documents. For further details on how your data is processed by Cover-More Blue
          Insurance Services Limited, please see their{' '}
          <ExternalLink url={PRIVACY_POLICY_CM_URL}>Privacy Policy</ExternalLink>
        </CheckboxWithStatement>
      </Stack>
    </CheckboxGroup>
  );
};

interface CheckboxWithStatementProps extends PropsWithChildren {
  formikFieldName: string;
  showEmptyTickWarning?: boolean;
}

const CheckboxWithStatement: React.FC<CheckboxWithStatementProps> = function CheckboxWithStatement({
  children,
  formikFieldName,
  showEmptyTickWarning,
}) {
  const formik = useFormikContext<InsurancePolicyFormValues>();
  const [field] = useField(formikFieldName);

  const changeFormikFieldValue = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      formik.setFieldValue(formikFieldName, e.target.checked);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formik.setFieldValue, formikFieldName],
  );

  return (
    <Stack spacing="4px">
      <HStack spacing="16px">
        <Checkbox
          id={`${formikFieldName}`}
          isChecked={field.value}
          onChange={changeFormikFieldValue}
          iconColor={Colors.Orange}
          color={Colors.Orange}
          borderColor={Colors.Orange}
          alignItems="flex-start"
        />

        <Text
          as={FormLabel}
          htmlFor={`${formikFieldName}`}
          textAlign="left"
          fontSize="12px"
          lineHeight="14px"
          color={Colors.Black}
          fontWeight="400"
        >
          {children}
        </Text>
      </HStack>

      {showEmptyTickWarning && (
        <Text textAlign="left" fontSize="12px" lineHeight="16px" color={Colors.Red}>
          You need to confirm the statement
        </Text>
      )}
    </Stack>
  );
};
