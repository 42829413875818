import React from 'react';
import { useFormikContext } from 'formik';

import { Flex, Stack } from '@chakra-ui/react';

import { ScreenWithTitleContainer } from '~/components/common/ScreenWithTitleContainer';
import { FormikInput } from '~/components/form/formik-input/FormikInput';
import { LOGIN_FIELD, PASSWORD_FIELD } from '~/components/auth-form/auth-form.schema';

export const AuthFormContent: React.FC = function AuthFormContent() {
  const formik = useFormikContext();

  return (
    <Flex dir="column" justify="center" h="100vh">
      <ScreenWithTitleContainer title="Login" onActionButtonClick={formik.submitForm} buttonLabel="Log in">
        <Stack spacing="12px">
          <FormikInput name={LOGIN_FIELD} placeholder="Login" type="text" variant="outline" />
          <FormikInput name={PASSWORD_FIELD} placeholder="Password" type="password" variant="outline" />
        </Stack>
      </ScreenWithTitleContainer>
    </Flex>
  );
};
