export enum AnalyticsEvent {
  QuotePetNameConfirmed = 'quote_pet_name_confirmed',
  QuotePetTypeConfirmed = 'quote_pet_type_confirmed',
  QuotePetGenderConfirmed = 'quote_pet_gender_confirmed',
  QuotePetBreedConfirmed = 'quote_pet_breed_confirmed',
  QuotePetAgeFullConfirmed = 'quote_pet_age_full_confirmed',
  QuotePetIsSpayedConfirmed = 'quote_pet_isspayed_confirmed',
  QuotePetIsMicrochipedConfirmed = 'quote_pet_ismicrochipped_confirmed',
  QuoteOwnerPostcodeConfirmed = 'quote_owner_postcode_confirmed',
  QuoteOwnerEmailConfirmed = 'quote_owner_email_confirmed',
  QuoteOwnerAddressConfirmed = 'quote_owner_address_confirmed ',
  QuoteOwnerInfoConfirmed = 'quote_owner_info_confirmed',
  QuoteOwnerOverEighteenConfirmed = 'quote_owner_over_eighteen_confirmed',
  QuoteOwnerDateOfBirthConfirmed = 'quote_owner_date_of_birth_confirmed',
  QuotePolicyStartDateConfirmed = 'quote_policy_start_date_confirmed',
  QuotePetPurchaseValueConfirmed = 'quote_pet_purchasevalue_confirmed',
  QuotePricingPlanLt1Confirmed = 'quote_pricing_plan_lt1_confirmed',
  QuotePricingPlanLt2Confirmed = 'quote_pricing_plan_lt2_confirmed',
  QuotePricingPlanLt3Confirmed = 'quote_pricing_plan_lt3_confirmed',
  QuotePolicySummaryConfirmed = 'quote_policy_summary_confirmed',
  QuotePolicyDeclarationConfirmed = 'quote_policy_declaration_confirmed',
  QuotePurchaseClick = 'quote_purchase_click',
  QuoteNavigateToPartnersURL = 'quote_navigate_to_partners_url',
  QuoteNavigatedBackToThankYouPage = 'quote_navigated_back_to_thank_you_page',
  QuoteCanNotFindBreed = 'quote_can_not_find_breed',
  QuoteHasAlreadyInsurancePolicy = 'quote_has_already_insurance_policy',
  QuoteSelectNotSupportedBreed = 'quote_select_not_supported_breed',
  QuoteOpenJoinBreedWaitListLink = 'quote_open_join_breed_waitlist_link',
  QuoteOpenJoinPostcodeWaitListLink = 'quote_open_join_postcode_waitlist_link',

  // misc
  QuoteOpenChat = 'quote_open_chat',
  QuoteCloseChat = 'quote_close_chat',

  // modal
  QuoteModalOpen = 'quote_modal_open',
  QuoteModalClose = 'quote_modal_close',
  QuoteModalExecutePrimaryAction = 'quote_modal_execute_primary_action',
  QuoteModalExecuteSecondaryAction = 'quote_modal_execute_secondary_action',
}

export enum AdEventType {
  Lead,
  AddToCard,
  Purchase,
}

type AdKeys = {
  redditKey: string;
  tiktokKey: string;
  googleAdsKey: string;
};

export const AD_EVENTS: Record<AdEventType, AdKeys> = {
  [AdEventType.Lead]: {
    redditKey: 'Lead',
    tiktokKey: 'AddPaymentInfo',
    googleAdsKey: 'AW-434805021/gL-fCMaZ4pgYEJ2yqs8B',
  },
  [AdEventType.AddToCard]: {
    redditKey: 'AddToCart',
    tiktokKey: 'AddToCart',
    googleAdsKey: 'AW-434805021/Kl3HCO3l3JgYEJ2yqs8B',
  },
  [AdEventType.Purchase]: {
    redditKey: 'Purchase',
    tiktokKey: 'CompletePayment',
    googleAdsKey: 'AW-434805021/epzgCKnm3JgYEJ2yqs8B',
  },
};
