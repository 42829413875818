import { PET_NAME_FIELD } from '~/components/insurance-questionnaire/insurance-questionnaire.schema';

import { useFilledInsuranceFormValuesField } from '~/hooks/insurance-data-provider/useFilledInsuranceFormValuesField';

import { formatPetNameForDisplay } from '~/helpers/pet/petName';

export function useFilledInsuranceFormValuesFormattedPetName(): string {
  const inputPetName = useFilledInsuranceFormValuesField(PET_NAME_FIELD);

  return formatPetNameForDisplay(String(inputPetName));
}
