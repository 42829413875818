import React from 'react';
import { Stack, Text } from '@chakra-ui/react';

import { Colors } from '~/constants/colors';

interface PlanBenefitItemProps {
  benefit: string;
}

export const PlanBenefitItem: React.FC<PlanBenefitItemProps> = function PlanBenefitItem({ benefit }) {
  return (
    <Stack direction={{ base: 'column', lg: 'row' }} spacing="12px" display="flex" alignItems="center" height="auto">
      <Text textAlign={{ base: 'center', lg: 'left' }} fontSize="14px" lineHeight="18px" color={Colors.Brown}>
        {benefit}
      </Text>
    </Stack>
  );
};
