import { AutoCompleteOption } from '~/components/form/formik.schema';

export function getMatchedOptions(options: AutoCompleteOption[], inputValue: string) {
  const trimmedValueWithoutSpecCharacters = inputValue.trim().replace(/[^a-zA-Z0-9- ]/g, '');

  if (!trimmedValueWithoutSpecCharacters) {
    return options;
  }

  // search for an exact string occurrence, case-insensitive
  const regExp = new RegExp(trimmedValueWithoutSpecCharacters, 'i');

  return options.filter(({ label }) => regExp.test(label));
}
