import { DOG_PET_TYPE_ID } from '@joinfluffy/common';

import { useFilledInsuranceFormValues } from '~/hooks/insurance-data-provider/useFilledInsuranceFormValues';

import { getPetTypeByPetTypeValue } from '~/helpers/pet';

import { CAT_OR_DOG_FIELD } from '~/components/insurance-questionnaire/insurance-questionnaire.schema';

export function useIsInsuranceDataContextDogPetType() {
  const insuranceFormData = useFilledInsuranceFormValues();
  const petType = getPetTypeByPetTypeValue(insuranceFormData[CAT_OR_DOG_FIELD]);

  return petType?.id === DOG_PET_TYPE_ID;
}
