import moment from 'moment';

import { findPetBreedByName, DOG_PET_TYPE_ID } from '@joinfluffy/common';

import { ProductBenefitCode, ProductBenefitSchema, ProductQuoteSchema } from '@shared/models/product';
import { Currency } from '@shared/constants/currency';
import { isSupportedPolicyCode } from '@shared/constants/policyCodes';

import { PolicyPricingSummarySchema } from '~/components/insurance-policy-display-form/insurance-policy-display-questionnaire.schema';
import {
  CAT_OR_DOG_FIELD,
  InsuranceFormValues,
  PET_BREED_FIELD,
  POLICY_START_DATE_FIELD,
} from '~/components/insurance-questionnaire/insurance-questionnaire.schema';

import { getPetTypeByPetTypeValue } from '~/helpers/pet';

import { FEE_CONFIG } from '~/configs/fee';

const NUMBERS_REGEXP = new RegExp('\\d');
const MILLION_ZERO_REGEXP = '000000';
const MILLION_LETTER = 'M';
const DOGS_ONLY_LABEL_MARKER_REGEXP = '(Dogs Only)';
const EMPTY_STRING = '';
const POLICY_SUMMARY_DATE_FORMAT = 'DD/MM/yyyy';

export function cleanUnsupportedPolicies(policies: ProductQuoteSchema[]) {
  return policies.filter((policy) => isSupportedPolicyCode(policy.productCode));
}

export function normalizePoliciesOrderByVetFeeAmount(policies: ProductQuoteSchema[]) {
  return policies.sort((firstPolicy, secondPolicy) => {
    const firstPolicyVetFeeBenefit = getPolicyBenefitData(firstPolicy, 'VET_FEES');
    const secondPolicyVetFeeBenefit = getPolicyBenefitData(secondPolicy, 'VET_FEES');

    return Number(firstPolicyVetFeeBenefit?.amount) - Number(secondPolicyVetFeeBenefit?.amount);
  });
}

export function getPolicyBenefitData(
  policy: ProductQuoteSchema,
  benefitCode: ProductBenefitCode,
): ProductBenefitSchema | undefined {
  return policy.productBenefits.find((benefit) => benefit.code === benefitCode);
}

export function cleanPolicyPerksToDisplay(
  benefits: ProductBenefitSchema[],
  isDogPetType?: boolean,
): ProductBenefitSchema[] {
  const selectedBenefitCodes: ProductBenefitCode[] = [
    'VET_FEES',
    'VET_HELPLINE',
    'THEFT',
    'ILLNESS_DEATH',
    'ADVERTISING',
    'BOARDING',
    ...(isDogPetType ? ['THIRD_PARTY_LIABILITY' as ProductBenefitCode] : []),
    'HOLIDAY_CANCELLATION',
    'EMERGENCY_ABROAD',
  ];

  return benefits.filter((benefit) => selectedBenefitCodes.includes(benefit.code));
}

export function formatPolicyPerkNameLabel(name: string) {
  return name.replace(DOGS_ONLY_LABEL_MARKER_REGEXP, EMPTY_STRING).trim();
}

export function formatPolicyPerkAmountLabel(amount: string) {
  if (NUMBERS_REGEXP.test(amount)) {
    const shortenedAmount = amount.replace(MILLION_ZERO_REGEXP, MILLION_LETTER);

    return `Up to ${Currency.GBP}${shortenedAmount}`;
  }

  return 'Free';
}

export function buildPolicySummary(petFormData?: InsuranceFormValues, policyData?: ProductQuoteSchema) {
  if (!policyData || !petFormData) {
    return;
  }

  const policySummary = new Map<string, string>();

  const vetFeesAmount = getPolicyBenefitData(policyData, 'VET_FEES')?.amount;
  const petType = getPetTypeByPetTypeValue(petFormData[CAT_OR_DOG_FIELD]);

  if (!vetFeesAmount || !petType) {
    return;
  }

  policySummary.set('Cover type', 'Lifetime');
  policySummary.set('Vet fees', `${Currency.GBP}${vetFeesAmount}`);
  policySummary.set('Start date', moment(petFormData[POLICY_START_DATE_FIELD]).format(POLICY_SUMMARY_DATE_FORMAT));
  policySummary.set('Pet type', getPetTypeLabel(petFormData[PET_BREED_FIELD], petType.id));
  policySummary.set('Breed', petFormData[PET_BREED_FIELD]);

  return policySummary;
}

export function buildPolicyPricingSummary(policyData?: ProductQuoteSchema): PolicyPricingSummarySchema | undefined {
  if (!policyData) {
    return;
  }

  const policyMonthlyPrice = policyData.monthlyPrice;
  const policyAnnualPrice = policyData.annualPrice;

  const feeConfig = FEE_CONFIG['ADMIN'];
  const currency = feeConfig.currency;
  const feeAmount = feeConfig.amount;

  return {
    monthlyPrice: {
      displayKey: 'Monthly Price:',
      value: `${currency}${policyMonthlyPrice.toFixed(2)}`,
    },
    annualInsurancePrice: {
      displayKey: 'Annual insurance price:',
      value: buildAnnualInsurancePriceString(policyData),
    },
    adminFee: {
      displayKey: 'Admin fee:',
      value: `${currency}${feeAmount} (non-refundable)`,
    },
    discount: {
      displayKey: 'Discount:',
      value: `-${currency}${getPolicyDiscount(policyData)}`,
    },
    totalAnnualPrice: {
      displayKey: 'Total annual price:',
      value: `${currency}${policyAnnualPrice}`,
    },
  };
}

export function buildAnnualInsurancePriceString(policyData?: ProductQuoteSchema) {
  if (!policyData) {
    return '';
  }

  const totalAnnualPrice = policyData.annualPrice;
  const discountAmount = getPolicyDiscount(policyData);

  if (!totalAnnualPrice) {
    return '';
  }
  const { currency, amount: feeAmount } = FEE_CONFIG['ADMIN'];

  const insuranceAnnualPrice = totalAnnualPrice - feeAmount + discountAmount;

  return `${currency}${insuranceAnnualPrice} (incl 12% IPT)`;
}

function getPetTypeLabel(breedValue: string, petTypeId: string) {
  const breed = findPetBreedByName(breedValue);
  const isDog = petTypeId === DOG_PET_TYPE_ID;

  let type = 'Pedigree';
  if (breed?.isMixed) {
    type = 'Mix-breed';
  }
  if (breed?.isCrossBreed) {
    type = 'Cross-breed';
  }

  return `${type} ${isDog ? 'dog' : 'cat'}`;
}

function getPolicyDiscount(policyData: ProductQuoteSchema): number {
  return (policyData.multipetDiscount ?? 0) + (policyData.promotionDiscount ?? 0);
}
