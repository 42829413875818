import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Formik } from 'formik';

import { AuthFormContent } from '~/components/auth-form/auth-form-content/AuthFormContent';
import {
  AuthFormValues,
  AUTH_FORM_INITIAL_VALUE,
  LOGIN_FIELD,
  PASSWORD_FIELD,
} from '~/components/auth-form/auth-form.schema';

import { captureAndLogException } from '~/helpers/monitoring/captureAndLogException';

import { getHttpsCallableFunction } from '~/hooks/useInitFirebase';
import { useUiStateUpdateIsLoading } from '~/hooks/useUiStateUpdateIsLoading';

import { LocalStorageKey } from '~/constants/localStorageKey';
import { CloudFunction } from '~/constants/functionName';

export const AuthForm: React.FC = function AuthForm() {
  const navigate = useNavigate();
  const updateUiIsLoading = useUiStateUpdateIsLoading();

  const handleFormSubmit = React.useCallback(
    async (values: AuthFormValues) => {
      updateUiIsLoading(true);

      const login = values[LOGIN_FIELD];
      const password = values[PASSWORD_FIELD];

      await invokeAuthUserApi(login, password)
        .then((isAuth) => {
          if (isAuth) {
            localStorage.setItem(LocalStorageKey.Authorization, 'true');
            navigate('/quote');
          }
        })
        .finally(() => {
          updateUiIsLoading(false);
        });
    },
    [navigate, updateUiIsLoading],
  );

  return (
    <Formik initialValues={AUTH_FORM_INITIAL_VALUE} onSubmit={handleFormSubmit}>
      <AuthFormContent />
    </Formik>
  );
};

async function invokeAuthUserApi(login: string, password: string) {
  const authorizeUser = getHttpsCallableFunction(CloudFunction.authorizeUser);

  return await authorizeUser({ credentials: { login, password } })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      captureAndLogException(`[authUser]: Unable to auth user: ${error}`, 'Error');
    });
}
