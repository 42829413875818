import {
  MAX_VET_COVER_PRODUCT_INFORMATION_URL,
  MID_VET_COVER_PRODUCT_INFORMATION_URL,
  MIN_VET_COVER_PRODUCT_INFORMATION_URL,
} from '~/constants/documents';

export type VetFees = '5000' | '7500' | '10000';

export const ProductInfoLinksConfig: Record<VetFees, string> = {
  '5000': MIN_VET_COVER_PRODUCT_INFORMATION_URL,
  '7500': MID_VET_COVER_PRODUCT_INFORMATION_URL,
  '10000': MAX_VET_COVER_PRODUCT_INFORMATION_URL,
};
