import {
  CAT_MAX_AGE_FOR_INSURANCE_IN_YEARS,
  DOG_MAX_AGE_FOR_INSURANCE_IN_YEARS,
  MIN_AGE_FOR_INSURANCE_IN_WEEKS,
} from '@shared/constants/validation';
import moment from 'moment';

import { AnyObject } from 'yup/lib/types';

import {
  CAT_OR_DOG_FIELD,
  IS_PET_DATE_OF_BIRTH_DD_MISSED_FIELD,
  IS_PET_DATE_OF_BIRTH_DD_MM_MISSED_FIELD,
} from '~/components/insurance-questionnaire/insurance-questionnaire.schema';

import {
  DATE_OF_BIRTH_FORMAT,
  MISSED_DD_DATE_OF_BIRTH_FORMAT,
  MISSED_DD_MM_DATE_OF_BIRTH_FORMAT,
} from '~/constants/date';
import { DD_MM_YYYY_REGEXP, MM_YYYY_REGEXP, YYYY_REGEXP } from '@shared/constants/regexp';

import { getTodaysDate } from '~/helpers/date';

export function checkPetIsOldEnoughForInsurance(value?: string | null, context?: AnyObject) {
  if (!context) {
    return false;
  }

  const date = getPetDateOfBirthMomentDate(context, value);

  return date.isSameOrBefore(getTodaysDate().subtract(MIN_AGE_FOR_INSURANCE_IN_WEEKS, 'weeks'));
}

export function checkPetIsNotTooOldForInsurance(value?: string | null, context?: AnyObject) {
  if (!context) {
    return false;
  }

  const date = getPetDateOfBirthMomentDate(context, value);
  const petType = String(context.parent[CAT_OR_DOG_FIELD]);

  return date.isAfter(
    getTodaysDate().subtract(
      petType === 'dog' ? DOG_MAX_AGE_FOR_INSURANCE_IN_YEARS : CAT_MAX_AGE_FOR_INSURANCE_IN_YEARS,
      'years',
    ),
  );
}

export function checkPetDateIsValid(value?: string | null, context?: AnyObject) {
  if (!context) {
    return false;
  }

  const format = getPetDateFormat(context);

  const date = moment(value, format);

  return date.isValid() && !date.isAfter(moment());
}

export function checkPetDateFormatIsCorrect(value?: string | null, context?: AnyObject) {
  if (!context) {
    return false;
  }

  const isDateOfPetBirthMissed = Boolean(context.parent[IS_PET_DATE_OF_BIRTH_DD_MISSED_FIELD]);
  const isDateAndMonthOfPetBirthMissed = Boolean(context.parent[IS_PET_DATE_OF_BIRTH_DD_MM_MISSED_FIELD]);

  let formatRegExp = DD_MM_YYYY_REGEXP;
  let format = DATE_OF_BIRTH_FORMAT;
  if (isDateOfPetBirthMissed) {
    formatRegExp = MM_YYYY_REGEXP;
    format = MISSED_DD_DATE_OF_BIRTH_FORMAT;
  }
  if (isDateAndMonthOfPetBirthMissed) {
    formatRegExp = YYYY_REGEXP;
    format = MISSED_DD_MM_DATE_OF_BIRTH_FORMAT;
  }

  if (value?.match(formatRegExp)) {
    return true;
  } else {
    return context.createError({ message: `Date format should be: ${format}` });
  }
}

function getPetDateFormat(context: AnyObject) {
  const isDateOfPetBirthMissed = Boolean(context.parent[IS_PET_DATE_OF_BIRTH_DD_MISSED_FIELD]);
  const isDateAndMonthOfPetBirthMissed = Boolean(context.parent[IS_PET_DATE_OF_BIRTH_DD_MM_MISSED_FIELD]);

  let format = DATE_OF_BIRTH_FORMAT;
  if (isDateOfPetBirthMissed) {
    format = MISSED_DD_DATE_OF_BIRTH_FORMAT;
  }
  if (isDateAndMonthOfPetBirthMissed) {
    format = MISSED_DD_MM_DATE_OF_BIRTH_FORMAT;
  }

  return format;
}

function getPetDateOfBirthMomentDate(context: AnyObject, value?: string | null): moment.Moment {
  const format = getPetDateFormat(context);

  return moment(value, format);
}
