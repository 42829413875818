import moment from 'moment';

import { findPetBreedById, findPetBreedByName, PetPedigree, petGenders, petSubTypes } from '@joinfluffy/common';

import { CustomerDto, PartialQuoteCustomerDto, PartialQuotePetPolicyDto, PetPolicyDto } from '@shared/models/dto';

import {
  InsuranceFormValues,
  EMAIL_FIELD,
  POLICY_START_DATE_FIELD,
  PET_DATE_OF_BIRTH_FIELD,
  CAT_OR_DOG_FIELD,
  GENDER_FIELD,
  IS_NEUTERED_FIELD,
  PET_VALUE_FIELD,
  PET_BREED_FIELD,
  PET_NAME_FIELD,
  POSTCODE_FIELD,
  IS_PET_DATE_OF_BIRTH_DD_MISSED_FIELD,
  IS_PET_DATE_OF_BIRTH_DD_MM_MISSED_FIELD,
} from '~/components/insurance-questionnaire/insurance-questionnaire.schema';

import { captureAndLogException } from '~/helpers/monitoring/captureAndLogException';
import { getPetTypeByPetTypeValue } from '~/helpers/pet';
import { cleanPetValueCurrency } from '~/helpers/pet/petValue';

import { DATE_OF_BIRTH_FORMAT } from '~/constants/date';
import {
  ADDRESS_LINE_1_FIELD,
  DATE_OF_OWNER_BIRTH_FIELD,
  FIRST_NAME_FIELD,
  IS_MICROCHIPPED_FIELD,
  InsurancePolicyFormValues,
  LAST_NAME_FIELD,
  TITLE_FIELD,
} from '~/components/insurance-policy-display-form/insurance-policy-display-questionnaire.schema';
import { PHONE_NUMBER_FIELD } from '~/components/insurance-policy-display-form/insurance-policy-display-questionnaire.schema';
import { ADDRESS_LINE_2_FIELD } from '~/components/insurance-policy-display-form/insurance-policy-display-questionnaire.schema';
import { ADDRESS_LINE_3_FIELD } from '~/components/insurance-policy-display-form/insurance-policy-display-questionnaire.schema';

export interface DtoResultSchema {
  customerDto: PartialQuoteCustomerDto;
  petPolicyDto: PartialQuotePetPolicyDto;
}

const DEFAULT_DD_PREFIX = '01/';

export function createPartialQuoteDtosFromFormValues(formValues: InsuranceFormValues): DtoResultSchema | undefined {
  try {
    const {
      petTypeValue,
      petPedigreeValue,
      genderValue,
      isNeuteredValue,
      petValue,
      email,
      petName,
      petBreedAsInt,
      formattedPetDateOfBirth,
      policyStartDate,
      postcode,
    } = validateInsuranceFormValues(formValues);

    const customerDto: PartialQuoteCustomerDto = {
      email,
      postcode,
    };

    const petPolicyDto: PartialQuotePetPolicyDto = {
      pet: {
        type: petTypeValue,
        gender: genderValue,
        pedigree: petPedigreeValue,
        name: petName,
        dateOfBirth: formattedPetDateOfBirth,
        isNeutered: isNeuteredValue,
        purchaseValue: petValue,
        breedId: petBreedAsInt,
      },
      policyStartDate: policyStartDate,
    };

    return { customerDto, petPolicyDto };
  } catch (e) {
    captureAndLogException(
      '[createPartialQuoteDtosFromFormValues]: exception when trying to create DTOs: ' + JSON.stringify(e),
      'Error',
    );
  }
}

export function createQuoteDtoFromFormsValues(
  insuranceFormValues: InsuranceFormValues,
  policyFormValues: InsurancePolicyFormValues,
) {
  try {
    const {
      petTypeValue,
      petPedigreeValue,
      genderValue,
      isNeuteredValue,
      petValue,
      petBreedAsInt,
      petName,
      email,
      formattedPetDateOfBirth,
      policyStartDate,
      postcode,
    } = validateInsuranceFormValues(insuranceFormValues);

    const { title, ownerDateOfBirth, isMicroChipped, firstName, lastName, address1, phoneNumber } =
      validateInsurancePolicyFormValues(policyFormValues);

    const customerDto: CustomerDto = {
      firstName,
      lastName,
      title: title!,
      dateOfBirth: ownerDateOfBirth.utc().format(),
      mobileNumber: phoneNumber,
      email: email,
      address1,
      address2: policyFormValues[ADDRESS_LINE_2_FIELD],
      address3: policyFormValues[ADDRESS_LINE_3_FIELD],
      postcode,
    };

    const petPolicyDto: PetPolicyDto = {
      pet: {
        type: petTypeValue,
        gender: genderValue,
        pedigree: petPedigreeValue,
        name: petName,
        dateOfBirth: formattedPetDateOfBirth,
        isNeutered: isNeuteredValue,
        purchaseValue: petValue,
        breedId: petBreedAsInt,
        isChipped: isMicroChipped!,
      },
      policyStartDate: policyStartDate,
    };

    return { customerDto, petPolicyDto };
  } catch (e) {
    captureAndLogException(
      '[createQuoteDtoFromFormsValues]: exception when trying to create DTOs: ' + JSON.stringify(e),
      'Error',
    );
  }
}

function validateInsuranceFormValues(formValues: InsuranceFormValues) {
  const gender = petGenders.find((gender) => gender.gender == formValues[GENDER_FIELD]);
  const isNeutered = formValues[IS_NEUTERED_FIELD];
  const petValue = cleanPetValueCurrency(formValues[PET_VALUE_FIELD]);
  const email = formValues[EMAIL_FIELD];
  const petName = formValues[PET_NAME_FIELD];

  let petBreed = findPetBreedByName(formValues[PET_BREED_FIELD]);
  if (petBreed?.aliasToBreedId) {
    petBreed = findPetBreedById(petBreed.aliasToBreedId);
  }

  let petPedigree: PetPedigree = 'pedigree';
  if (petBreed?.isCrossBreed) {
    petPedigree = 'crossed';
  }
  if (petBreed?.isMixed) {
    petPedigree = 'mixed';
  }

  const petType = getPetTypeByPetTypeValue(formValues[CAT_OR_DOG_FIELD]);
  const petSubType = petSubTypes.find(({ type, pedigree }) => type === petType?.value && pedigree === petPedigree);

  const policyStartDate = formValues[POLICY_START_DATE_FIELD];
  const postcode = formValues[POSTCODE_FIELD].trim();

  let petDateOfBirthValue = formValues[PET_DATE_OF_BIRTH_FIELD];
  if (formValues[IS_PET_DATE_OF_BIRTH_DD_MISSED_FIELD]) {
    // user provided only DD/YYYY of their pet's date of birth
    petDateOfBirthValue = `${DEFAULT_DD_PREFIX}${formValues[PET_DATE_OF_BIRTH_FIELD]}`;
  }
  if (formValues[IS_PET_DATE_OF_BIRTH_DD_MM_MISSED_FIELD] && policyStartDate) {
    // user provided only YYYY of their pet's date of birth
    // we replace the year of the policyStartDate with the provided year value
    // todo [INSR-40]: add unit tests (and move it as a separate method)
    //  also need to have a Cypress test to be sure this place is safe even if we changed format of data
    petDateOfBirthValue = moment(policyStartDate)
      .set('year', Number(formValues[PET_DATE_OF_BIRTH_FIELD]))
      .format(DATE_OF_BIRTH_FORMAT);
  }
  const petDateOfBirth = moment(petDateOfBirthValue, DATE_OF_BIRTH_FORMAT, true);

  let hasMissingValues = false;

  if (!petType) {
    console.error('Missing petType value');
    hasMissingValues = true;
  }

  if (!petSubType) {
    console.error('Missing petSubType value');
    hasMissingValues = true;
  }

  if (gender === null) {
    console.error('Missing gender value');
    hasMissingValues = true;
  }

  if (email.length === 0) {
    console.error('Missing email value');
    hasMissingValues = true;
  }

  if (petName.length === 0) {
    console.error('Missing petName value');
    hasMissingValues = true;
  }

  if (isNeutered === null) {
    console.error('Missing isNeutered value');
    hasMissingValues = true;
  }

  if (Number.isNaN(petValue) || Number(petValue) < 0) {
    console.error('Invalid petValue value');
    hasMissingValues = true;
  }

  if (!petBreed) {
    console.error('Missing petBreed value');
    hasMissingValues = true;
  }

  if (!petDateOfBirth.isValid()) {
    captureAndLogException(
      '[validateFormValues]: invalid petDateOfBirth value: ' + formValues[PET_DATE_OF_BIRTH_FIELD],
      'Error',
    );
    hasMissingValues = true;
  }

  if (formValues[IS_NEUTERED_FIELD] === null) {
    console.error('Missing isNeutered value');
    hasMissingValues = true;
  }

  if (!policyStartDate) {
    console.error('Missing policyStartDate value');
    hasMissingValues = true;
  }

  if (!postcode) {
    console.error('Missing postcode value');
    hasMissingValues = true;
  }

  if (hasMissingValues) {
    throw 'Some of the values from the InsuranceForm are missing or are invalid. Inspect above log outputs to determine the particular values causing the issue';
  }

  return {
    petTypeValue: petType!.value,
    email,
    petName,
    petPedigreeValue: petSubType!.pedigree,
    genderValue: gender!.gender,
    isNeuteredValue: Boolean(isNeutered),
    petValue: Number(petValue),
    petBreedAsInt: parseInt(petBreed!.id),
    formattedPetDateOfBirth: petDateOfBirth.utc().format(),
    policyStartDate: policyStartDate!,
    postcode,
  };
}

function validateInsurancePolicyFormValues(policyFormValues: InsurancePolicyFormValues) {
  const title = policyFormValues[TITLE_FIELD];
  const ownerDateOfBirth = moment(policyFormValues[DATE_OF_OWNER_BIRTH_FIELD], DATE_OF_BIRTH_FORMAT, true);
  const isMicroChipped = policyFormValues[IS_MICROCHIPPED_FIELD];
  const firstName = policyFormValues[FIRST_NAME_FIELD];
  const lastName = policyFormValues[LAST_NAME_FIELD];
  const address1 = policyFormValues[ADDRESS_LINE_1_FIELD];
  const phoneNumber = policyFormValues[PHONE_NUMBER_FIELD];

  let hasMissingValues = false;

  if (title === null) {
    console.error('[validateInsurancePolicyFormValues] Missing title value');
    hasMissingValues = true;
  }

  if (!ownerDateOfBirth.isValid()) {
    captureAndLogException(
      '[validateInsurancePolicyFormValues]: invalid ownerDateOfBirth value: ' +
        policyFormValues[DATE_OF_OWNER_BIRTH_FIELD],
      'Error',
    );
    hasMissingValues = true;
  }

  if (isMicroChipped === null) {
    console.error('[validateInsurancePolicyFormValues] Missing isMicrochipped value');
    hasMissingValues = true;
  }

  if (firstName.length === 0) {
    console.error('[validateInsurancePolicyFormValues] Missing firstName value');
    hasMissingValues = true;
  }

  if (lastName.length === 0) {
    console.error('[validateInsurancePolicyFormValues] Missing lastName value');
    hasMissingValues = true;
  }

  if (address1.length === 0) {
    console.error('[validateInsurancePolicyFormValues] Missing address1 value');
    hasMissingValues = true;
  }

  if (phoneNumber.length === 0) {
    console.error('[validateInsurancePolicyFormValues] Missing phoneNumber value');
    hasMissingValues = true;
  }

  if (hasMissingValues) {
    throw 'Some of the values from the InsurancePolicyForm are missing or are invalid. Inspect above log outputs to determine the particular values causing the issue';
  }

  return {
    title,
    ownerDateOfBirth,
    isMicroChipped,
    firstName,
    lastName,
    address1,
    phoneNumber,
  };
}
