import React from 'react';
import { Box, Button } from '@chakra-ui/react';

import { PopoverInfoButton } from '~/components/common/PopoverInfoButton';

import { Colors } from '~/constants/colors';

import { ReactComponent as Logo } from '~/assets/icons/policy-cover-logo.svg';
import { ReactComponent as ArrowBack } from '~/assets/icons/arrow-back.svg';

interface HeaderProps {
  popoverTextContent?: string;
  isBackButtonVisible?: boolean;
  onBackButtonPress?: () => void;
}

export const Header: React.FC<HeaderProps> = function Header({
  popoverTextContent,
  isBackButtonVisible,
  onBackButtonPress,
}) {
  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Button
        _active={{}} // resets default chakra _active styles
        _hover={{ opacity: 0.5 }}
        bg={Colors.Transparent}
        height={{ base: '24px', lg: '50px' }}
        width={{ base: '24px', lg: '50px' }}
        padding={0}
        visibility={!isBackButtonVisible ? 'hidden' : undefined}
        onClick={onBackButtonPress}
      >
        <ArrowBack width="100%" height="100%" />
      </Button>

      <Box
        position="absolute"
        marginLeft="auto"
        marginRight="auto"
        top={0}
        left={0}
        right={0}
        height={{ base: '59px', lg: '56px' }}
        width={{ base: '125px', lg: '159px' }}
      >
        <Logo width="100%" height="100%" />
      </Box>

      <PopoverInfoButton popoverTextContent={popoverTextContent} />
    </Box>
  );
};
