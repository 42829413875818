import { FormikContextType } from 'formik';

import {
  InsurancePolicyFormValues,
  PolicyDisplayFormField,
} from '~/components/insurance-policy-display-form/insurance-policy-display-questionnaire.schema';
import {
  InsuranceFormValues,
  InsuranceFormField,
  EMAIL_FIELD,
} from '~/components/insurance-questionnaire/insurance-questionnaire.schema';

const QUOTING_PREFIX = 'quoting_';

export function normalizeIdentityObject(formValues: InsuranceFormValues | InsurancePolicyFormValues) {
  const normalizedKeyValues = Object.entries(formValues).map(([key, value]) => [QUOTING_PREFIX + key, value]);

  return Object.fromEntries(normalizedKeyValues);
}

export function getBinaryQuestionInsuranceQuestionnaireFormTraits(
  formField: InsuranceFormField | PolicyDisplayFormField,
  value: string | boolean,
) {
  return { [buildQuotingIdentityFieldName(formField)]: value };
}

export function getCurrentInsuranceQuestionnaireFormQuestionTraits(
  formFields: InsuranceFormField | InsuranceFormField[],
  formik: FormikContextType<InsuranceFormValues>,
) {
  const data = Array.isArray(formFields) ? formFields : [formFields];

  return data.reduce((acc, formFieldName: InsuranceFormField) => {
    acc[buildQuotingIdentityFieldName(formFieldName)] = getSingleFormikFieldValue(formFieldName, formik);

    return acc;
  }, {} as Record<string, unknown>);
}

function getSingleFormikFieldValue(formField: InsuranceFormField, formik: FormikContextType<InsuranceFormValues>) {
  return formik.values[formField];
}

export function buildQuotingIdentityFieldName(fieldName: string) {
  return QUOTING_PREFIX + fieldName;
}

export function getUserInsuranceQuestionnaireFormEmail(formik: FormikContextType<InsuranceFormValues>) {
  const email = String(getSingleFormikFieldValue(EMAIL_FIELD, formik));

  return email.length > 0 ? email : undefined;
}

export function getCurrentInsurancePolicyQuestionnaireFormQuestionTraits(
  formFields: PolicyDisplayFormField | PolicyDisplayFormField[],
  formik: FormikContextType<InsurancePolicyFormValues>,
) {
  const data = Array.isArray(formFields) ? formFields : [formFields];

  return data.reduce((acc, formFieldName: PolicyDisplayFormField) => {
    acc[buildQuotingIdentityFieldName(formFieldName)] = formik.values[formFieldName];

    return acc;
  }, {} as Record<string, unknown>);
}
