import React from 'react';

export function useKeyEnterScreenHandler(params?: { actionToCall?: () => void; isActionDisabled?: boolean }) {
  const { actionToCall, isActionDisabled = false } = params || {};

  const keyboardEventHandler = React.useCallback(
    (event: KeyboardEvent) => {
      if (event.key !== 'Enter') {
        return;
      }

      if (isActionDisabled || !actionToCall) {
        return;
      }

      actionToCall();
    },
    [actionToCall, isActionDisabled],
  );

  React.useEffect(() => {
    document.addEventListener('keydown', keyboardEventHandler);

    return () => {
      document.removeEventListener('keydown', keyboardEventHandler);
    };
  }, [actionToCall, keyboardEventHandler]);
}
