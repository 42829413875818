export enum PolicyCodes {
  LT1 = 'LT1',
  LT2 = 'LT2',
  LT3 = 'LT3',
}

export function isSupportedPolicyCode(
  supportedPolicyCodeCandidate: string,
): supportedPolicyCodeCandidate is PolicyCodes {
  return supportedPolicyCodeCandidate in PolicyCodes;
}
