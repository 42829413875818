import React from 'react';
import { RouterProvider, createBrowserRouter, Navigate } from 'react-router-dom';

import { AppWrapper } from '~/components/app-wrapper/AppWrapper';
import { ErrorPage } from '~/components/pages/ErrorPage';
import { ThankYouPage } from '~/components/pages/ThankYouPage';
import { AuthForm } from '~/components/auth-form/AuthForm';
import { OverlayLoader } from '~/components/common/OverlayLoader';

import { useUiStateIsLoading } from '~/hooks/useUiStateIsLoading';

import { InsuranceDataProvider } from '~/providers/InsuranceDataProvider';

import { isUserAuthorized } from '~/helpers/auth';

const ProtectedRoute: React.FC<React.PropsWithChildren> = function ProtectedRoute({ children }) {
  if (!isUserAuthorized()) {
    return <Navigate to="/auth" replace />;
  }

  return <>{children}</>;
};

const QuotingForm = (
  <ProtectedRoute>
    <InsuranceDataProvider>
      <AppWrapper />
    </InsuranceDataProvider>
  </ProtectedRoute>
);

const router = createBrowserRouter([
  {
    path: '/',
    element: <Navigate to="/quote" />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/quote',
    element: QuotingForm,
  },
  {
    path: '/quote/:quoteId',
    element: QuotingForm,
  },
  {
    path: '/thank-you',
    element: <ThankYouPage />,
  },
  {
    path: '/auth',
    element: <AuthForm />,
  },
]);

export function AppRouter() {
  const isLoading = useUiStateIsLoading();

  return (
    <>
      {isLoading && <OverlayLoader />}

      <RouterProvider router={router} />
    </>
  );
}
