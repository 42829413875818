import React from 'react';

import { FirebaseApp, initializeApp } from '@firebase/app';
import { getFunctions, httpsCallable, HttpsCallableOptions } from '@firebase/functions';

import { CloudFunction } from '~/constants/functionName';

// TODO [STRL-1368]: check Security Rules to ensure the keys don't expose excessive access
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMEN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID, // for Firebase JS SDK v7.20.0 and later, measurementId is optional
};

const DEFAULT_CLOUD_FUNCTION_REGION = 'europe-west2';

let app: FirebaseApp | undefined;

export function useInitFirebase() {
  const [initFinished, setInitFinished] = React.useState<boolean>(false);

  React.useEffect(() => {
    // Initialize Firebase
    app = initializeApp(firebaseConfig);
    setInitFinished(true);
  }, []);

  return { initFinished };
}

export function getHttpsCallableFunction(functionName: CloudFunction, options?: HttpsCallableOptions) {
  const functions = getFunctions(app, DEFAULT_CLOUD_FUNCTION_REGION);
  return httpsCallable(functions, functionName, options);
}
