import { useField } from 'formik';

import {
  FIRST_NAME_FIELD,
  LAST_NAME_FIELD,
  PHONE_NUMBER_FIELD,
  TITLE_FIELD,
} from '~/components/insurance-policy-display-form/insurance-policy-display-questionnaire.schema';

export function useIsPolicyOwnerInfoFormActionButtonEnabled() {
  const [titleField, titleFieldMeta] = useField(TITLE_FIELD);
  const [firstNameField, firstNameFieldMeta] = useField(FIRST_NAME_FIELD);
  const [lastNameField, lastNameFieldMeta] = useField(LAST_NAME_FIELD);
  const [phoneNumberField, phoneNumberFieldMeta] = useField(PHONE_NUMBER_FIELD);

  return Boolean(
    titleField.value &&
      !titleFieldMeta.error &&
      firstNameField.value &&
      !firstNameFieldMeta.error &&
      lastNameField.value &&
      !lastNameFieldMeta.error &&
      phoneNumberField.value &&
      !phoneNumberFieldMeta.error,
  );
}
