import { captureAndLogException } from '~/helpers/monitoring/captureAndLogException';

import { LocallyStoredInsurancePolicyFormValues } from '~/components/insurance-policy-display-form/insurance-policy-display-questionnaire.schema';

import { LocalStorageKey } from '~/constants/localStorageKey';

export function savePolicyFormValuesLocally(formValues: LocallyStoredInsurancePolicyFormValues) {
  localStorage.setItem(LocalStorageKey.SavedInsurancePolicyFormValues, JSON.stringify(formValues));
}

export function getPolicyFormValuesStoredLocally(): LocallyStoredInsurancePolicyFormValues | null {
  let result: LocallyStoredInsurancePolicyFormValues | null = null;
  try {
    const storageItem = localStorage.getItem(LocalStorageKey.SavedInsurancePolicyFormValues);

    if (storageItem) {
      result = JSON.parse(storageItem);
    }
  } catch (err) {
    captureAndLogException(
      `Error while parsing stored locally policy form: "${JSON.stringify(err instanceof Error ? err.message : err)}"`,
      'Error',
    );
  }

  return result;
}

export function cleanLocallyStoredQuoteData() {
  localStorage.removeItem(LocalStorageKey.SavedQuoteId);
  localStorage.removeItem(LocalStorageKey.SavedInsurancePolicyFormValues);
}
