export const quotingUrlDev = 'https://staging.insureyourpaws.co.uk/api/QuoteService';
export const quotingUrlPrd = 'https://www.insureyourpaws.co.uk/api/QuoteService';
export const paymentTypeParam = 'paymentType';

export const MAX_ANNUAL_DISCOUNT = 100;
export const AFFILIATE_IN_PROMO_LENGTH = 6;
export const OFF_MARK_IN_PROMO_LENGTH = 3;

export const DISCOUNT_CODE_LABELS = [
  'FLUFFY1OFF',
  'FLUFFY2OFF',
  'FLUFFY3OFF',
  'FLUFFY4OFF',
  'FLUFFY5OFF',
  'FLUFFY6OFF',
  'FLUFFY7OFF',
  'FLUFFY8OFF',
  'FLUFFY9OFF',
  'FLUFFY10OFF',
  'FLUFFY11OFF',
  'FLUFFY12OFF',
  'FLUFFY13OFF',
  'FLUFFY14OFF',
  'FLUFFY15OFF',
  'FLUFFY16OFF',
  'FLUFFY17OFF',
  'FLUFFY18OFF',
  'FLUFFY19OFF',
  'FLUFFY20OFF',
  'FLUFFY21OFF',
  'FLUFFY22OFF',
  'FLUFFY23OFF',
  'FLUFFY24OFF',
  'FLUFFY25OFF',
  'FLUFFY26OFF',
  'FLUFFY27OFF',
  'FLUFFY28OFF',
  'FLUFFY29OFF',
  'FLUFFY30OFF',
] as const;

// further codes to be added in the future
export type AffiliateCode = 'FLUFFY';
export type DiscountCode = (typeof DISCOUNT_CODE_LABELS)[number];

export const fluffyAffiliateCode: AffiliateCode = 'FLUFFY';
export const discountCode10: DiscountCode = 'FLUFFY10OFF';
export const paymentTypeMonthly = 'M';
