import React from 'react';

import { OverlayLoader } from '~/components/common/OverlayLoader';

import { useInitMonitoring } from '~/hooks/useInitMonitoring';
import { useInitFirebase } from '~/hooks/useInitFirebase';
import { useInitSegmentAnalytics } from '~/hooks/useInitSegmentAnalytics';

export const AnalyticsProviders: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { initFinished: sentryInitFinished } = useInitMonitoring();
  const { initFinished: firebaseInitFinished } = useInitFirebase();
  const { initFinished: segmentInitFinished } = useInitSegmentAnalytics();

  if (!sentryInitFinished || !firebaseInitFinished || !segmentInitFinished) {
    return <OverlayLoader />;
  }

  return <>{children}</>;
};
