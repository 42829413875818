import React from 'react';

import { Box, Image, Modal, ModalContent, ModalFooter, ModalOverlay, Stack, Text } from '@chakra-ui/react';

import { ActionButton } from '~/components/common/ActionButton';

import { trackEvent } from '~/helpers/analytics/trackEvent';

import { Colors } from '~/constants/colors';
import { ModalTypes } from '~/constants/modalTypes';
import { AnalyticsEvent } from '~/constants/analyticsEvent';

import { ReactComponent as CrossIcon } from '~/assets/icons/cross.svg';

interface BaseModalProps {
  type: ModalTypes;
  isOpen: boolean;
  title: string;
  description: string;
  imgSrc: string;
  actionButtonLabel?: string;
  secondaryActionButtonLabel?: string;
  onClose: () => void;
  onActionButtonPress?: () => void;
  onSecondaryActionButtonPress?: () => void;
}

export const BaseModal: React.FC<BaseModalProps> = function BaseModal({
  type,
  isOpen,
  title,
  description,
  imgSrc,
  actionButtonLabel,
  secondaryActionButtonLabel,
  onClose,
  onActionButtonPress,
  onSecondaryActionButtonPress,
}) {
  function onCloseWithEvent() {
    trackEvent({
      eventName: AnalyticsEvent.QuoteModalClose,
      modalType: type,
    });

    onClose();
  }

  function onActionButtonPressWithEvent() {
    trackEvent({
      eventName: AnalyticsEvent.QuoteModalExecutePrimaryAction,
      modalType: type,
    });

    onActionButtonPress && onActionButtonPress();
  }

  function onSecondaryActionButtonPressWithEvent() {
    trackEvent({
      eventName: AnalyticsEvent.QuoteModalExecuteSecondaryAction,
      modalType: type,
    });

    onSecondaryActionButtonPress && onSecondaryActionButtonPress();
  }

  return (
    <Modal isOpen={isOpen} onClose={onCloseWithEvent}>
      <ModalOverlay bg={Colors.DarkOverlay} />
      <ModalContent
        alignItems="center"
        padding="0 24px 12px 24px"
        maxWidth={{ base: '340px', lg: '768px' }}
        minHeight={{ base: '500px', lg: '640px' }}
        bg={Colors.PrimaryBackground}
        borderRadius="32px"
        justifyContent="space-between"
      >
        <Box as="button" position="absolute" top="16px" right="16px" onClick={onCloseWithEvent}>
          <CrossIcon width="24px" height="24px" color={Colors.Brown} opacity={0.48} />
        </Box>
        <Box height={{ base: '340px', lg: '450px' }} width={{ base: '340px', lg: '450px' }}>
          <Image src={imgSrc} height="100%" width="100%" />
        </Box>

        <Stack spacing="12px" align="center">
          <Box maxWidth={{ base: '296px', lg: '500px' }}>
            <Text fontSize="20px" lineHeight="32px" color={Colors.Brown} fontWeight="700" textAlign="center">
              {title}
            </Text>
          </Box>
          <Box>
            <Text
              fontSize={{ base: '12px', lg: '16px' }}
              lineHeight={{ base: '16px', lg: '24px' }}
              color={Colors.Brown}
              fontWeight="400"
              textAlign="center"
            >
              {description}
            </Text>
          </Box>
        </Stack>

        <ModalFooter>
          <Stack marginTop={{ base: '24px', bg: '12px' }} spacing="12px" align="center">
            {actionButtonLabel && onActionButtonPress && (
              <ActionButton
                label={actionButtonLabel}
                onPress={onActionButtonPressWithEvent}
                style={{ width: '295px' }}
              />
            )}
            {secondaryActionButtonLabel && onSecondaryActionButtonPress && (
              <ActionButton
                label={secondaryActionButtonLabel}
                onPress={onSecondaryActionButtonPressWithEvent}
                isSecondary
              />
            )}
          </Stack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
