import React from 'react';

import { useUiStateContextPatchUiState } from '~/hooks/useUiStateContextUpdateUi';

import { trackEvent } from '~/helpers/analytics/trackEvent';

import { ModalTypes } from '~/constants/modalTypes';
import { AnalyticsEvent } from '~/constants/analyticsEvent';

export function useInvokeModal() {
  const patchUi = useUiStateContextPatchUiState();

  const invokeModal = React.useCallback(
    (modalType: ModalTypes, modalParams: any) => {
      trackEvent({
        eventName: AnalyticsEvent.QuoteModalOpen,
        modalType,
        params: modalParams,
      });

      patchUi && patchUi({ modal: { type: modalType, params: modalParams } });
    },
    [patchUi],
  );

  return { invokeModal };
}
