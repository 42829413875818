import React from 'react';
import { Stack } from '@chakra-ui/react';

import { PolicySummaryKeyValue } from '~/components/insurance-policy-display-form/policy-summary-view/policy-summary-key-value/PolicySummaryKeyValue';

import { PolicySummaryContainerWithTitle } from '~/components/insurance-policy-display-form/policy-summary-view/policy-summary-container-with-title/PolicySummaryContainerWithTitle';

interface PolicySummaryProps {
  summaryValues: Map<string, string>;
}

export const PolicySummary: React.FC<PolicySummaryProps> = function PolicySummary({ summaryValues }) {
  const summaryKeys = Array.from(summaryValues.keys());

  return (
    <PolicySummaryContainerWithTitle title="Policy summary">
      <Stack spacing="2px" marginTop="8px">
        {summaryKeys.map((key, idx) => (
          <PolicySummaryKeyValue key={idx} summaryKey={key} value={summaryValues.get(key)} />
        ))}
      </Stack>
    </PolicySummaryContainerWithTitle>
  );
};
