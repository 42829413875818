import React from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import { cleanLocallyStoredQuoteData } from '~/helpers/quote/locallyStoredPolicyFormValues';

import { LocalStorageKey } from '~/constants/localStorageKey';

export function useCheckForExistingQuote() {
  const navigate = useNavigate();

  const wasHandledRef = React.useRef<boolean>(false);

  React.useEffect(() => {
    if (wasHandledRef.current) {
      return;
    }

    const savedQuoteId = localStorage.getItem(LocalStorageKey.SavedQuoteId);
    const submittedPolicyFormAtStr = localStorage.getItem(LocalStorageKey.SubmittedPolicyFormAt);

    if (!savedQuoteId || !submittedPolicyFormAtStr) {
      // Just do nothing - form is in progress
      return;
    }

    const submittedPolicyFormAt = Number(submittedPolicyFormAtStr);
    const isOldFormData = moment(submittedPolicyFormAt).isBefore(moment().subtract(24, 'hours'));

    if (isNaN(submittedPolicyFormAt) || isOldFormData) {
      // Something is wrong with the data we have set OR the form data is old enough
      // Need to clean this data
      cleanLocallyStoredQuoteData();
      localStorage.removeItem(LocalStorageKey.SubmittedPolicyFormAt);

      return;
    }

    wasHandledRef.current = true;
    navigate(`/quote/${savedQuoteId}`, { replace: true });
  }, [navigate]);
}
