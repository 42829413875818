import { Currency } from '@shared/constants/currency';

export type Fee = 'ADMIN';

export interface FeeConfigSchema {
  currency: Currency;
  amount: number;
}

export const FEE_CONFIG: Record<Fee, FeeConfigSchema> = {
  ADMIN: {
    currency: Currency.GBP,
    amount: 8,
  },
};
