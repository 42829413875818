import moment from 'moment';

import { findPetBreedById } from '@joinfluffy/common';

import { ProductQuoteSchema, QuoteSchema, QuoteStorageDto, StoragePetQuoteSchema } from '@shared/models/product';
import { paymentTypeMonthly, paymentTypeParam } from '@shared/constants/url';
import { Currency } from '@shared/constants/currency';
import { CustomerDto, PartialQuoteCustomerDto, PartialQuotePetPolicyDto, PetPolicyDto } from '@shared/models/dto';
import { PolicyCodes } from '@shared/constants/policyCodes';

import { getPolicyFormValuesStoredLocally } from '~/helpers/quote/locallyStoredPolicyFormValues';
import { captureAndLogException } from '~/helpers/monitoring/captureAndLogException';
import { trackAdEvent, trackEvent } from '~/helpers/analytics/trackEvent';

import { getHttpsCallableFunction } from '~/hooks/useInitFirebase';
import {
  CAT_OR_DOG_FIELD,
  CONFIRM_EMAIL_FIELD,
  CURRENT_QUESTION_STEP_IDX,
  EMAIL_FIELD,
  GENDER_FIELD,
  InsuranceFormValues,
  IS_NEUTERED_FIELD,
  IS_OVER_EIGHTEEN_FIELD,
  IS_PET_DATE_OF_BIRTH_DD_MISSED_FIELD,
  IS_PET_DATE_OF_BIRTH_DD_MM_MISSED_FIELD,
  LAST_QUESTION_STEP_IDX,
  PET_BREED_FIELD,
  PET_DATE_OF_BIRTH_FIELD,
  PET_NAME_FIELD,
  PET_VALUE_FIELD,
  POLICY_START_DATE_FIELD,
  POSTCODE_FIELD,
} from '~/components/insurance-questionnaire/insurance-questionnaire.schema';

import { CloudFunction } from '~/constants/functionName';
import { DATE_OF_BIRTH_FORMAT } from '~/constants/date';
import { LocalStorageKey } from '~/constants/localStorageKey';
import { AdEventType, AnalyticsEvent } from '~/constants/analyticsEvent';
import {
  ADDRESS_LINE_1_FIELD,
  ADDRESS_LINE_2_FIELD,
  ADDRESS_LINE_3_FIELD,
  ARE_POLICY_AND_BUSINESS_DOCUMENTS_READ_FIELD,
  ARE_TERMS_OF_COVER_CONFIRMED_FIELD,
  DATE_OF_OWNER_BIRTH_FIELD,
  FIRST_NAME_FIELD,
  HAS_ALREADY_INSURANCE_POLICY,
  InsurancePolicyFormValues,
  IS_MICROCHIPPED_FIELD,
  IS_POLICY_SUMMARY_CONFIRMED_FIELD,
  LAST_NAME_FIELD,
  PHONE_NUMBER_FIELD,
  POLICY_CODE_FIELD,
  TITLE_FIELD,
} from '~/components/insurance-policy-display-form/insurance-policy-display-questionnaire.schema';

export async function fetchQuoteById(quoteId: string): Promise<QuoteStorageDto> {
  const fetchQuoteById = getHttpsCallableFunction(CloudFunction.fetchQuoteById);

  return fetchQuoteById({ quoteId })
    .then((result) => result.data as QuoteStorageDto)
    .catch((e) => {
      captureAndLogException(e, 'Error');

      throw e;
    });
}

export function getInsuranceFormValuesFromQuote(quote: QuoteStorageDto) {
  const { customerData, petQuotes } = quote;
  const petData = getPetDataFromPetQuotes(petQuotes);

  const insuranceFormValues: InsuranceFormValues = {
    [PET_NAME_FIELD]: petData.name,
    [CAT_OR_DOG_FIELD]: petData.type,
    [GENDER_FIELD]: petData.gender,
    [PET_BREED_FIELD]: findPetBreedById(String(petData.breedId))?.breedName ?? '',
    [IS_NEUTERED_FIELD]: petData.isNeutered,
    [PET_VALUE_FIELD]: `${Currency.GBP}${petData.purchaseValue}`,
    [POSTCODE_FIELD]: customerData.postcode,
    [PET_DATE_OF_BIRTH_FIELD]: formatUtcDateToUiFormatDate(petData.dateOfBirth),
    [POLICY_START_DATE_FIELD]: new Date(quote.policyStartDate).toISOString(),
    [EMAIL_FIELD]: customerData.email,
    [CONFIRM_EMAIL_FIELD]: customerData.email,
    [IS_PET_DATE_OF_BIRTH_DD_MISSED_FIELD]: false,
    [IS_PET_DATE_OF_BIRTH_DD_MM_MISSED_FIELD]: false,
    [IS_OVER_EIGHTEEN_FIELD]: true,
    [CURRENT_QUESTION_STEP_IDX]: LAST_QUESTION_STEP_IDX,
  };

  return insuranceFormValues;
}

export function getPolicyFormValuesFromQuote(quote: QuoteStorageDto): InsurancePolicyFormValues {
  const { customerData, petQuotes } = quote;
  const petData = getPetDataFromPetQuotes(petQuotes);

  const locallyStoredPolicyFormValues = getPolicyFormValuesStoredLocally();
  const policyFormValues: InsurancePolicyFormValues = {
    [HAS_ALREADY_INSURANCE_POLICY]: undefined,
    [POLICY_CODE_FIELD]: PolicyCodes.LT2,
    [IS_POLICY_SUMMARY_CONFIRMED_FIELD]: false,
    [ARE_TERMS_OF_COVER_CONFIRMED_FIELD]: false,
    [ARE_POLICY_AND_BUSINESS_DOCUMENTS_READ_FIELD]: false,
    [IS_MICROCHIPPED_FIELD]: petData.isChipped,
    [ADDRESS_LINE_1_FIELD]: customerData.address1,
    [ADDRESS_LINE_2_FIELD]: customerData.address2 ?? '',
    [ADDRESS_LINE_3_FIELD]: customerData.address3 ?? '',
    [DATE_OF_OWNER_BIRTH_FIELD]: formatUtcDateToUiFormatDate(customerData.dateOfBirth),
    [TITLE_FIELD]: customerData.title,
    [FIRST_NAME_FIELD]: customerData.firstName,
    [LAST_NAME_FIELD]: customerData.lastName,
    [PHONE_NUMBER_FIELD]: customerData.mobileNumber,
  };

  return {
    ...policyFormValues,
    ...locallyStoredPolicyFormValues,
  };
}

export function getPolicyListFromQuote(quote: QuoteStorageDto): ProductQuoteSchema[] {
  return quote.petQuotes[0].productQuotes;
}

function getPetDataFromPetQuotes(petQuotes: StoragePetQuoteSchema[]) {
  return petQuotes[0].petData;
}

function formatUtcDateToUiFormatDate(date: string) {
  return moment(new Date(date)).format(DATE_OF_BIRTH_FORMAT);
}

/**
 * Call a Cloud Function that will return quoteData for the particular customer and pet
 * @param customerDto
 * @param petPolicyDto
 */
export const invokeFetchQuoteWithPartialRequestDataApi = async (
  customerDto: PartialQuoteCustomerDto,
  petPolicyDto: PartialQuotePetPolicyDto,
) => {
  const fetchQuoteWithPartialRequestData = getHttpsCallableFunction(CloudFunction.fetchQuoteWithPartialRequestData);

  return fetchQuoteWithPartialRequestData({ customer: customerDto, petPolicies: [petPolicyDto] }).then(
    (result) => result.data,
  );
};

export const invokeFetchQuoteDataApi = async (
  customerDto: CustomerDto,
  petPolicyDto: PetPolicyDto,
  quoteId: string,
) => {
  const fetchQuoteData = getHttpsCallableFunction(CloudFunction.fetchQuoteData);

  return fetchQuoteData({ customer: customerDto, petPolicies: [petPolicyDto], existingQuoteId: quoteId }).then(
    (result) => result.data,
  );
};

export function navigateToQuotePaymentPage(selectedPolicyCode: PolicyCodes, quoteData: QuoteSchema) {
  const selectedPolicy = quoteData.petQuotes[0].productQuotes.find(
    ({ productCode }) => productCode === selectedPolicyCode,
  );

  if (!selectedPolicy) {
    throw `Policy with code: ${selectedPolicyCode} is not defined in quote: ${quoteData}`;
  }

  // navigate to partner's purchase URL after a timeout with the monthly payment type
  const purchaseUrl = new URL(selectedPolicy.purchaseUrl);
  purchaseUrl.searchParams.append(paymentTypeParam, paymentTypeMonthly);

  localStorage.setItem(LocalStorageKey.SubmittedPolicyFormAt, Date.now().toString());

  trackAdEvent(AdEventType.AddToCard);
  trackEvent({ eventName: AnalyticsEvent.QuoteNavigateToPartnersURL, redirectUrl: purchaseUrl.toString() });
  window.location.replace(purchaseUrl);
}
