import { useFilledInsuranceFormValues } from '~/hooks/insurance-data-provider/useFilledInsuranceFormValues';

import { openUrlInNewTab } from '~/helpers/common';

import { POSTCODE_FIELD } from '~/components/insurance-questionnaire/insurance-questionnaire.schema';

import { trackEvent } from '~/helpers/analytics/trackEvent';

import { AnalyticsEvent } from '~/constants/analyticsEvent';
import {
  BE_POSTCODE_ERROR_MESSAGE,
  CLIENT_POSTCODE_ERROR_MESSAGE,
  NOT_MICROCHIPPED_ERROR_MESSAGE,
  OWNER_UNDER_EIGHTEEN_ERROR_MESSAGE,
} from '~/constants/errorMessage';

interface ErrorInfoContentSchema {
  title: string;
  subTitle: string;
  primaryButtonLabel?: string;
  primaryButtonAction?: () => void;
}

function openJoinPostcodeWaitlistTypeform() {
  trackEvent({ eventName: AnalyticsEvent.QuoteOpenJoinPostcodeWaitListLink });
  openUrlInNewTab('https://quiz.typeform.com/to/GEbgU8iX');
}

function getContactSupportAction(errorMessage?: string) {
  return () =>
    (window.location.href = `mailto:support@joinfluffy.com?subject=Quoting%20flow%error&body=${errorMessage}`);
}

export function useErrorInfoContent(errorMessage?: string): ErrorInfoContentSchema {
  const insuranceFormData = useFilledInsuranceFormValues();

  switch (errorMessage) {
    case BE_POSTCODE_ERROR_MESSAGE: {
      const postcode = insuranceFormData?.[POSTCODE_FIELD];
      const subTitle = `Fluffy isn’t available in ${
        postcode ? 'the ' + postcode : 'your'
      } area just yet. Please check the postcode you've entered or join our waitlist below to hear as soon as we’re active in your area!`;

      return {
        title: 'Looks like Fluffy isn’t available in your area yet',
        subTitle,
        primaryButtonLabel: 'Join waitlist',
        primaryButtonAction: openJoinPostcodeWaitlistTypeform,
      };
    }

    case NOT_MICROCHIPPED_ERROR_MESSAGE: {
      return {
        title: 'All dogs aged 8 weeks and over in England, Scotland, Wales and Northern Ireland must be microchipped.',
        subTitle:
          'The underwriter reserves the right to decline in the event of a claim if your dog does not have a registered microchip number unless you have an exemption certificate from your vet.',
        primaryButtonLabel: 'Agree and continue',
      };
    }

    case OWNER_UNDER_EIGHTEEN_ERROR_MESSAGE: {
      return {
        title: 'Insurance unavailable',
        subTitle: 'We cannot offer insurance to customers under the age of 18',
        primaryButtonLabel: 'Agree and continue',
      };
    }

    case CLIENT_POSTCODE_ERROR_MESSAGE: {
      return {
        title: 'Oops! Invalid postcode format',
        subTitle: `Looks like the postcode you've provided has invalid format. Please close this popup, navigate back to the address form and provide a valid postcode. Alternatively, email our support team.`,
        primaryButtonLabel: 'Email support',
        primaryButtonAction: getContactSupportAction(errorMessage),
      };
    }

    // default content
    default: {
      return {
        title: 'Looks like there was an issue processing your request',
        subTitle: 'Please try completing the form again or email our support team.',
        primaryButtonLabel: 'Email support',
        primaryButtonAction: getContactSupportAction(errorMessage),
      };
    }
  }
}
