import React from 'react';
import { Formik, useFormikContext } from 'formik';

import { Box } from '@chakra-ui/react';

import { CatOrDog, PetGender } from '@joinfluffy/common';

import { Stepper } from '~/components/stepper/Stepper';
import { Step } from '~/components/stepper/Step';
import { Header } from '~/components/common/Header';

import { BinaryQuestion } from '~/components/questions/BinaryQuestion';
import { BreedQuestion } from '~/components/questions/BreedQuestion';
import { PetAgeQuestion } from '~/components/questions/PetAgeQuestion';
import { PetValueQuestion } from '~/components/questions/PetValueQuestion';
import { TextInputQuestion } from '~/components/questions/TextInputQuestion';
import { PolicyStartDateQuestionView } from '~/components/insurance-questionnaire/policy-start-date-question-view/PolicyStartDateQuestionView';
import { InsuranceEmailQuestion } from '~/components/insurance-policy-display-form/insurance-email-question/InsuranceEmailQuestion';

import { insuranceQuestionnaireValidationSchema } from './insurance-questionnaire.validation';

import {
  CAT_OR_DOG_FIELD,
  CURRENT_QUESTION_STEP_IDX,
  EMAIL_FIELD,
  GENDER_FIELD,
  InsuranceFormQuestionType,
  InsuranceFormValues,
  IS_NEUTERED_FIELD,
  IS_OVER_EIGHTEEN_FIELD,
  LAST_QUESTION_STEP_IDX,
  PET_BREED_FIELD,
  PET_NAME_FIELD,
  POSTCODE_FIELD,
} from './insurance-questionnaire.schema';

import { useFormikInsuranceFormDisplayFormatPetName } from '~/hooks/useFormikInsuranceFormDisplayFormatPetName';
import { useInsurancePetDataFormSubmitHandler } from '~/hooks/insurance-data-provider/useInsurancePetDataFormSubmitHandler';
import { useFilledInsuranceFormValues } from '~/hooks/insurance-data-provider/useFilledInsuranceFormValues';
import { useUiStateContextFormBackButtonPressHandler } from '~/hooks/useUiStateContextFormBackButtonPressHandler';
import { useInvokeErrorModal } from '~/hooks/useInvokeErrorModal';

import { debounce } from '~/helpers/common';
import { identify } from '~/helpers/analytics/identify';
import { trackAdEvent, trackEventAndIdentify } from '~/helpers/analytics/trackEvent';
import {
  getBinaryQuestionInsuranceQuestionnaireFormTraits,
  getCurrentInsuranceQuestionnaireFormQuestionTraits,
  getUserInsuranceQuestionnaireFormEmail,
  normalizeIdentityObject,
} from '~/helpers/analytics/userAnalyticsIdentity';
import { uppercaseFormatter } from '~/helpers/formatter';

import { AnalyticsWithoutParamsSchema } from '~/models/analyticsParams';

import { AdEventType } from '~/constants/analyticsEvent';

import { INSURANCE_FORM_IDENTITY_FIELDS_CONFIG, INSURANCE_FORM_TRACKING_EVENTS_CONFIG } from '~/configs/tracking';
import { OWNER_UNDER_EIGHTEEN_ERROR_MESSAGE } from '~/constants/errorMessage';

import '~/components/insurance-questionnaire/InsuranceQuestionnaire.scss';

const FIRST_QUESTION_IDX = 0;
const DEBOUNCE_TIMEOUT = 300;

const POPOVER_CONTENT_CONFIG: Record<number, string> = {
  3: 'If your pet is a pedigree, where both parents are of the same breed, please enter this breed.\n\nIf your pet is a crossbreed, where the parents are of two different breeds, please enter the crossbreed or most dominant breed. If neither breed appears on this list, please contact us to proceed.\n\n If your pet is a mix of three or more breeds or neither breed is dominant, please select “I do not know what breed my pet is” and select the option which most closely matches the breed of your pet.',
  4: "If your pet's age is unknown, please select the approximate age of your pet. To start a new policy, your pet must be older than eight weeks and younger than eight years of age for dogs and younger than ten years of age for cats.",
};

export const InsuranceQuestionnaire: React.FC = function InsuranceQuestionnaire() {
  const initialFormValues = useFilledInsuranceFormValues();
  const formSubmitHandler = useInsurancePetDataFormSubmitHandler();

  const handleSubmit = React.useCallback(
    async (values: InsuranceFormValues) => {
      const identityValues = normalizeIdentityObject(values);

      await identify(values[EMAIL_FIELD], identityValues);

      formSubmitHandler(values);
    },
    [formSubmitHandler],
  );

  React.useEffect(() => {
    trackAdEvent(AdEventType.Lead);
  }, []);

  return (
    <Formik
      initialValues={initialFormValues}
      validationSchema={insuranceQuestionnaireValidationSchema}
      onSubmit={handleSubmit}
    >
      <InsuranceQuestionnaireSteps />
    </Formik>
  );
};

const InsuranceQuestionnaireSteps = function InsuranceQuestionnaireSteps() {
  const formik = useFormikContext<InsuranceFormValues>();

  const [currentStep, setCurrentStep] = React.useState<number>(formik.values[CURRENT_QUESTION_STEP_IDX]);

  const { invokeErrorModal } = useInvokeErrorModal();

  const backButtonPressHandler = useUiStateContextFormBackButtonPressHandler();

  const trackAnalytics = (currentQuestionType: InsuranceFormQuestionType, traits?: object) => {
    trackEventAndIdentify(
      {
        eventName: INSURANCE_FORM_TRACKING_EVENTS_CONFIG[currentQuestionType],
      } as AnalyticsWithoutParamsSchema,
      traits,
      getUserInsuranceQuestionnaireFormEmail(formik),
    );
  };

  const openNextQuestion =
    ({ currentQuestionType }: { currentQuestionType: InsuranceFormQuestionType }) =>
    () => {
      const traits = {
        ...getCurrentInsuranceQuestionnaireFormQuestionTraits(
          INSURANCE_FORM_IDENTITY_FIELDS_CONFIG[currentQuestionType],
          formik,
        ),
      };

      trackAnalytics(currentQuestionType, traits);

      openNextStep();
    };

  const openNextStep = React.useCallback(() => {
    if (currentStep < LAST_QUESTION_STEP_IDX) {
      setCurrentStep((prevStep) => prevStep + 1);
    } else {
      formik.submitForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.submitForm, currentStep]);

  const openPrevStep = React.useCallback(() => setCurrentStep((prevStep) => prevStep - 1), []);

  const binaryQuestionOpenNextStep = (
    { currentQuestionType }: { currentQuestionType: InsuranceFormQuestionType },
    value?: string | boolean,
  ) => {
    const fields = INSURANCE_FORM_IDENTITY_FIELDS_CONFIG[currentQuestionType];
    const traits =
      !Array.isArray(fields) && value !== undefined
        ? getBinaryQuestionInsuranceQuestionnaireFormTraits(fields, value)
        : undefined;

    trackAnalytics(currentQuestionType, traits);

    debounce(openNextStep, DEBOUNCE_TIMEOUT)();
  };

  React.useEffect(() => {
    // call on every currentStep value change
    formik.setFieldValue(CURRENT_QUESTION_STEP_IDX, currentStep);

    // we should not have "formik" as a dependency here, because otherwise we will have infinite effect re-call
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep, formik.setFieldValue]);

  React.useEffect(() => {
    // start the next question from the top of the page
    window.scrollTo(0, 0);
  }, [currentStep]);

  const petName = useFormikInsuranceFormDisplayFormatPetName();

  return (
    <>
      <Header
        isBackButtonVisible={currentStep !== FIRST_QUESTION_IDX}
        onBackButtonPress={backButtonPressHandler ?? openPrevStep}
        popoverTextContent={POPOVER_CONTENT_CONFIG[currentStep]}
      />
      <Box
        maxWidth={{ base: '320px', lg: '600px' }}
        marginX="auto"
        alignSelf="center"
        marginTop={{ base: '40px', lg: '65px' }}
      >
        <Stepper step={currentStep}>
          <Step>
            <TextInputQuestion
              name={PET_NAME_FIELD}
              questionText="What’s your pet’s name?"
              placeholder="Name of your pet"
              actionButtonLabel="Continue"
              onActionButtonClick={openNextQuestion({ currentQuestionType: 'PET_NAME' })}
            />
          </Step>

          <Step>
            <BinaryQuestion<CatOrDog>
              name={CAT_OR_DOG_FIELD}
              questionText={`Is ${petName} a cat or a dog?`}
              options={[
                { label: 'Dog', value: 'dog' },
                { label: 'Cat', value: 'cat' },
              ]}
              onSelect={(value?: string | boolean) =>
                binaryQuestionOpenNextStep({ currentQuestionType: 'PET_TYPE' }, value)
              }
            />
          </Step>

          <Step>
            <BinaryQuestion<PetGender>
              name={GENDER_FIELD}
              questionText={`What is ${petName}’s gender?`}
              options={[
                { label: 'Boy', value: 'male' },
                { label: 'Girl', value: 'female' },
              ]}
              onSelect={(value?: string | boolean) =>
                binaryQuestionOpenNextStep({ currentQuestionType: 'PET_GENDER' }, value)
              }
            />
          </Step>

          <Step>
            <BreedQuestion
              formikName={PET_BREED_FIELD}
              fullListQuestionText={`What breed is ${petName}?`}
              mixedBreedQuestionText={`What size is ${petName}?`}
              placeholder="Pet breed"
              actionButtonLabel="Continue"
              onActionButtonClick={openNextQuestion({ currentQuestionType: 'PET_BREED' })}
            />
          </Step>

          <Step>
            <PetAgeQuestion
              actionButtonLabel="Continue"
              onActionButtonClick={openNextQuestion({ currentQuestionType: 'PET_BIRTH_DATE' })}
            />
          </Step>

          <Step>
            <PetValueQuestion
              actionButtonLabel="Continue"
              onActionButtonClick={openNextQuestion({ currentQuestionType: 'PET_VALUE' })}
            />
          </Step>

          <Step>
            <BinaryQuestion<boolean>
              name={IS_NEUTERED_FIELD}
              questionText={`Is ${petName} spayed/neutered?`}
              options={[
                { label: 'Yes', value: true },
                { label: 'No', value: false },
              ]}
              onSelect={(value?: string | boolean) =>
                binaryQuestionOpenNextStep({ currentQuestionType: 'IS_PET_NEUTERED' }, value)
              }
            />
          </Step>

          <Step>
            <TextInputQuestion
              name={POSTCODE_FIELD}
              questionText="Provide your postcode"
              placeholder="Postcode"
              actionButtonLabel="Continue"
              inputFormatter={uppercaseFormatter}
              onActionButtonClick={openNextQuestion({ currentQuestionType: 'POST_CODE' })}
            />
          </Step>

          <Step>
            <PolicyStartDateQuestionView
              onValueSelect={() => binaryQuestionOpenNextStep({ currentQuestionType: 'PET_POLICY_START_DATE' })}
            />
          </Step>

          <Step>
            <BinaryQuestion<boolean>
              name={IS_OVER_EIGHTEEN_FIELD}
              questionText="Are you over 18 years old?"
              options={[
                { label: 'Yes', value: true },
                { label: 'No', value: false },
              ]}
              onSelect={(isOverEighteen) => {
                if (isOverEighteen) {
                  binaryQuestionOpenNextStep({ currentQuestionType: 'IS_PET_OWNER_OVER_EIGHTEEN' });
                } else {
                  invokeErrorModal(new Error(OWNER_UNDER_EIGHTEEN_ERROR_MESSAGE));
                }
              }}
            />
          </Step>

          <Step>
            <InsuranceEmailQuestion openNextStep={openNextQuestion({ currentQuestionType: 'EMAIL' })} />
          </Step>
        </Stepper>
      </Box>
    </>
  );
};
