import { getAnalyticsBrowser } from '~/helpers/analytics/analytics';
import { captureAndLogException } from '~/helpers/monitoring/captureAndLogException';

export async function identify(email?: string, traits?: object) {
  const analyticsBrowser = getAnalyticsBrowser();

  if (analyticsBrowser) {
    await analyticsBrowser.identify(email, { email, ...traits });
  } else {
    captureAndLogException('[identify]: Analytics browser is not initialized', 'Warn');
  }
}
