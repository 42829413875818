import * as Sentry from '@sentry/react';

import { getAnalyticsBrowser } from '~/helpers/analytics/analytics';
import { captureAndLogException } from '~/helpers/monitoring/captureAndLogException';
import { isDevEnv } from '~/helpers/common';
import { identify } from '~/helpers/analytics/identify';

import { AnalyticsParamsSchema } from '~/models/analyticsParams';

import { AD_EVENTS, AdEventType } from '~/constants/analyticsEvent';

/**
 * Method to track analytics events via Segment
 * - Facebook Pixel is set up as a Destination point for the all Segment events
 * - Google Ads (Classic) is set up as a Destination point for the Segment events from the Mapping
 * @param analyticsParams
 */
export function trackEvent(analyticsParams: AnalyticsParamsSchema) {
  const { eventName, ...eventParams } = analyticsParams;

  Sentry.addBreadcrumb({
    category: 'event_breadcrumb',
    message: eventName,
    data: eventParams,
    level: 'info',
  });

  // Segment event handling
  handleSegmentAnalyticsEvent(analyticsParams);
}

export function trackEventAndIdentify(analyticsParams: AnalyticsParamsSchema, traits?: object, email?: string) {
  trackEvent(analyticsParams);

  identify(email, traits);
}

export function trackAdEvent(eventType: AdEventType) {
  // only send the events in PRD environment
  if (!isDevEnv()) {
    // @ts-ignore
    window.gtag('event', 'conversion', { send_to: AD_EVENTS[eventType].googleAdsKey });
    // @ts-ignore
    window.rdt('track', AD_EVENTS[eventType].redditKey);
    // @ts-ignore
    window.ttq.track(AD_EVENTS[eventType].tiktokKey);
  }
}

function handleSegmentAnalyticsEvent({ eventName, ...eventParams }: AnalyticsParamsSchema): void {
  const analyticsBrowser = getAnalyticsBrowser();

  if (analyticsBrowser) {
    analyticsBrowser.track(eventName, eventParams);
  } else {
    captureAndLogException('[trackEvent - Segment]: Analytics browser is not initialized', 'Warn');
  }
}
