import React, { ReactElement } from 'react';

import { isStepElement, StepProps } from './Step';

import { getValueWithFrameLimit } from '~/helpers/numbers.utils';

interface StepperProps {
  step: number | string;
}

export const Stepper: React.FC<React.PropsWithChildren<StepperProps>> = function Stepper({ children, step }) {
  const childrenArray = React.Children.toArray(children) as ReactElement<StepProps>[];
  const stepIndex = React.useMemo(() => {
    let index;
    if (typeof step === 'number') {
      index = step;
    } else {
      index = childrenArray.findIndex((child) => isStepElement(child) && child.props.step === step);
    }

    return getValueWithFrameLimit(0, Math.max(childrenArray.length - 1, 0), index);
  }, [step, childrenArray]);

  return <div key={stepIndex}>{childrenArray[stepIndex]}</div>;
};
