import React from 'react';
import { Formik } from 'formik';

import { InsurancePolicyDisplayQuestionnaire } from '~/components/insurance-policy-display-form/insurance-policy-display-questionnaire/InsurancePolicyDisplayQuestionnaire';

import { InsurancePolicyFormValues } from '~/components/insurance-policy-display-form/insurance-policy-display-questionnaire.schema';
import { insurancePolicyDisplayQuestionnaireValidationSchema } from '~/components/insurance-policy-display-form/insurance-policy-display-questionnaire.validation';

import { useFilledInsuranceFormValues } from '~/hooks/insurance-data-provider/useFilledInsuranceFormValues';
import { useInsurancePolicyFormSubmitHandler } from '~/hooks/insurance-data-provider/useInsurancePolicyFormSubmitHandler';
import { useFilledInsurancePolicyFormValues } from '~/hooks/insurance-data-provider/useFilledInsurancePolicyFormValues';

import { identify } from '~/helpers/analytics/identify';
import { normalizeIdentityObject } from '~/helpers/analytics/userAnalyticsIdentity';

export const InsurancePolicyDisplayForm: React.FC = function InsurancePolicyDisplayForm() {
  const insuranceFormData = useFilledInsuranceFormValues();
  const insurancePolicyFormSubmitHandler = useInsurancePolicyFormSubmitHandler();
  const insurancePolicyFormInitValues = useFilledInsurancePolicyFormValues();

  const handleFormSubmit = React.useCallback(
    async (values: InsurancePolicyFormValues) => {
      const normalizedFormData = normalizeIdentityObject(values);

      await identify(insuranceFormData?.email, normalizedFormData);

      await insurancePolicyFormSubmitHandler(values);
    },
    [insuranceFormData?.email, insurancePolicyFormSubmitHandler],
  );

  return (
    <Formik
      initialValues={insurancePolicyFormInitValues}
      onSubmit={handleFormSubmit}
      validationSchema={insurancePolicyDisplayQuestionnaireValidationSchema}
    >
      <InsurancePolicyDisplayQuestionnaire />
    </Formik>
  );
};
