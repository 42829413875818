import React, { ChangeEvent } from 'react';
import { Field, useField, useFormikContext } from 'formik';

import { Input, Stack, Text } from '@chakra-ui/react';
import { InputProps } from '@chakra-ui/input/dist/input';

import { FormikInputErrorText } from '~/components/form/formik-input-error-text/FormikInputErrorText';

import { Colors } from '~/constants/colors';

import { getMatchedOptions } from '~/components/form/auto-complete-input/auto-complete-input.helpers';
import type { AutoCompleteOption } from '~/components/form/formik.schema';
import { FLUSHED_INPUT_STYLES } from '~/components/form/formik.style';

import { useKeyEnterFormikHandler } from '~/hooks/formik-common/useKeyEnterFormikHandler';

interface AutoCompleteInputProps extends InputProps {
  name: string; // re-declare here to make it required
  options: AutoCompleteOption[];
  keyEnterHandler?: () => void;
}

const DISPLAY_ITEMS_COUNT_LIMIT = 7;

export const AutoCompleteInput: React.FC<AutoCompleteInputProps> = function AutoCompleteInput({
  name,
  options,
  keyEnterHandler,
  ...inputProps
}) {
  const formik = useFormikContext();
  const [formikField] = useField(name);

  const [inputValue, setInputValue] = React.useState<string>(formikField.value ?? '');
  const inputChangeHandler = React.useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setInputValue(value);
  }, []);

  const [isFocused, setIsFocused] = React.useState<boolean>(false);
  const inputFocusHandler = React.useCallback(() => setIsFocused(true), []);

  const inputBlurHandler = React.useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      if (suggestsContainerRef.current?.contains(event.relatedTarget)) {
        // click inside container with the suggested options
        return;
      }

      setIsFocused(false);
      setInputValue(formikField.value); // set the last selected value if the user clicks out
      formik.setFieldTouched(name);
    },
    [formik, formikField.value, name],
  );

  const inputKeyEnterHandler = useKeyEnterFormikHandler(name, keyEnterHandler);

  const suggestsContainerRef = React.useRef<HTMLDivElement | null>(null);

  const { suggestedOptions, totalItems } = React.useMemo(() => {
    const matchedOptions = getMatchedOptions(options, inputValue);

    return {
      suggestedOptions: matchedOptions.slice(0, DISPLAY_ITEMS_COUNT_LIMIT),
      totalItems: matchedOptions.length,
    };
  }, [inputValue, options]);

  return (
    <Stack spacing={1} position="relative" width={{ base: '327px', lg: '400px' }}>
      <Field
        as={Input}
        id={name}
        {...inputProps}
        {...formikField}
        {...FLUSHED_INPUT_STYLES}
        value={inputValue}
        onChange={inputChangeHandler}
        onFocus={inputFocusHandler}
        onBlur={inputBlurHandler}
        onKeyDown={keyEnterHandler ? inputKeyEnterHandler : undefined}
        autoComplete="off"
      />

      {isFocused && (
        <Stack
          spacing={0}
          position="absolute"
          top="120%"
          left={0}
          right={0}
          padding="8px"
          borderRadius="8px"
          backgroundColor={Colors.Beige}
          zIndex={1}
          textAlign="left"
          ref={(current) => (suggestsContainerRef.current = current)}
        >
          {suggestedOptions.map((option) => (
            <Text
              tabIndex={0} // to be "focusable"
              key={option.value}
              onClick={() => {
                formik.setFieldTouched(name);
                formik.setFieldValue(name, option.value);
                setInputValue(option.label);
                setIsFocused(false);
              }}
              fontSize="16px"
              lineHeight="20px"
              padding="4px 8px"
              borderRadius="4px"
              color={Colors.SecondaryBrown}
              _hover={{ opacity: 0.5, cursor: 'pointer' }}
            >
              {option.label}
            </Text>
          ))}

          <Text
            fontSize="13px"
            lineHeight="15px"
            color={Colors.Brown}
            paddingLeft="8px"
            paddingTop={totalItems ? '8px' : 0}
          >
            {totalItems === 0 ? 'No results' : `Showing ${suggestedOptions.length} of ${totalItems} item(s)`}
          </Text>
        </Stack>
      )}

      <FormikInputErrorText fieldName={name} />
    </Stack>
  );
};
