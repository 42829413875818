import { useField } from 'formik';

import type { InsuranceFormValues } from '~/components/insurance-questionnaire/insurance-questionnaire.schema';
import {
  IS_PET_DATE_OF_BIRTH_DD_MISSED_FIELD,
  IS_PET_DATE_OF_BIRTH_DD_MM_MISSED_FIELD,
} from '~/components/insurance-questionnaire/insurance-questionnaire.schema';

// be sure you call this hook inside the Formik provider
// otherwise you will have an error
export function usePetBirthDateMissedFormikFields() {
  const [isDdMissedField, , ddMissedHelper] = useField<
    InsuranceFormValues[typeof IS_PET_DATE_OF_BIRTH_DD_MISSED_FIELD]
  >(IS_PET_DATE_OF_BIRTH_DD_MISSED_FIELD);

  const [isDdMmMissedField, , ddMmMissedHelper] = useField<
    InsuranceFormValues[typeof IS_PET_DATE_OF_BIRTH_DD_MM_MISSED_FIELD]
  >(IS_PET_DATE_OF_BIRTH_DD_MM_MISSED_FIELD);

  return {
    isDdMissedField,
    ddMissedHelper,
    isDdMmMissedField,
    ddMmMissedHelper,
  };
}
