import React from 'react';
import { Stack, Text } from '@chakra-ui/react';

import { PlanBenefitItem } from '~/components/insurance-policy-display-form/policy-display-plans-view/plan-benefits-section/plan-benefit-item/PlanBenefitItem';
import { ProductButtons } from '~/components/insurance-policy-display-form/product-buttons/ProductButtons';

import { Colors } from '~/constants/colors';

interface PlanBenefitsSectionProps {
  benefits: string[];
}

export const PlanBenefitsSection: React.FC<PlanBenefitsSectionProps> = function PlanBenefitsSection({ benefits }) {
  return (
    <Stack
      spacing="12px"
      display="flex"
      flexDir="column"
      alignItems={{ base: 'center', lg: 'flex-start' }}
      width={{
        base: '332px',
        lg: '520px',
      }}
    >
      <Text fontSize="18px" lineHeight="32px" color={Colors.Brown} fontWeight="900">
        We&#39;ve got you covered
      </Text>

      <Stack spacing="16px" marginTop="8px">
        {benefits.map((benefit, idx) => (
          <PlanBenefitItem key={idx} benefit={benefit} />
        ))}
        <ProductButtons style={{ justify: { base: 'center', lg: 'left' } }} />
      </Stack>
    </Stack>
  );
};
