import React from 'react';
import { Box, Stack, Text } from '@chakra-ui/react';

import { useField } from 'formik';

import { PolicyStatementsCheckboxSection } from '~/components/insurance-policy-display-form/policy-statements-confirmation-view/policy-statements-checkbox-section/PolicyStatementsCheckboxSection';
import { ScreenWithTitleContainer } from '~/components/common/ScreenWithTitleContainer';
import { ActionButton } from '~/components/common/ActionButton';
import { FloatingContainer } from '~/components/common/FloatingContainer';
import { Footer } from '~/components/common/Footer';

import {
  ARE_POLICY_AND_BUSINESS_DOCUMENTS_READ_FIELD,
  ARE_TERMS_OF_COVER_CONFIRMED_FIELD,
} from '~/components/insurance-policy-display-form/insurance-policy-display-questionnaire.schema';

import { useHideFooter } from '~/hooks/common/useHideFooter';

import { trackEvent } from '~/helpers/analytics/trackEvent';

import { Colors } from '~/constants/colors';
import { AnalyticsEvent } from '~/constants/analyticsEvent';
export interface PolicyStatementsConfirmationViewProps {
  onSubmit: () => void;
}

export const PolicyStatementsConfirmationView: React.FC<PolicyStatementsConfirmationViewProps> =
  function PolicyStatementsConfirmationView({ onSubmit }) {
    useHideFooter();

    const [fieldsWithWarning, setFieldsWithWarning] = React.useState({
      [ARE_TERMS_OF_COVER_CONFIRMED_FIELD]: false,
      [ARE_POLICY_AND_BUSINESS_DOCUMENTS_READ_FIELD]: false,
    });

    const [areCoverTermsConfirmedField] = useField(ARE_TERMS_OF_COVER_CONFIRMED_FIELD);
    const [arePolicyAndBusinessDocumentsReadField] = useField(ARE_POLICY_AND_BUSINESS_DOCUMENTS_READ_FIELD);

    const confirmPolicyDeclarations = React.useCallback(() => {
      trackEvent({ eventName: AnalyticsEvent.QuotePolicyDeclarationConfirmed });
      trackEvent({ eventName: AnalyticsEvent.QuotePurchaseClick });

      if (!areCoverTermsConfirmedField.value || !arePolicyAndBusinessDocumentsReadField.value) {
        setFieldsWithWarning({
          [ARE_TERMS_OF_COVER_CONFIRMED_FIELD]: !areCoverTermsConfirmedField.value,
          [ARE_POLICY_AND_BUSINESS_DOCUMENTS_READ_FIELD]: !arePolicyAndBusinessDocumentsReadField.value,
        });

        return;
      }

      onSubmit();
    }, [areCoverTermsConfirmedField.value, arePolicyAndBusinessDocumentsReadField.value, onSubmit]);

    return (
      <ScreenWithTitleContainer
        style={{ spacing: '36px', paddingBottom: '106px' }}
        title="It’s important to read and confirm the following statements"
      >
        <Stack
          spacing={{ base: '36px', lg: '102px' }}
          direction={{ base: 'column', lg: 'row' }}
          justify="center"
          align="flex-start"
        >
          <PolicyStatementsCheckboxSection fieldsWithWarning={fieldsWithWarning} />

          <Box padding="8px 16px 16px 16px" bg={Colors.Beige} width="340px">
            <Text textAlign="left" fontSize="14px" lineHeight="32px" color={Colors.Black} fontWeight="900">
              Notice
            </Text>

            <Box marginTop="8px">
              <Text
                textAlign="left"
                fontSize="12px"
                lineHeight="20px"
                color={Colors.Black}
                fontWeight="400"
                whiteSpace="pre-line"
              >
                There is no cover for pre-existing conditions including any illnesses which developed before/within the
                first 14 days of the policy start date and or injuries which occurred before/within the first 48 hours
                of the policy start date. There is also no cover for cruciate ligament problems which occurred
                before/within the first 30 days of the policy start date.{'\n\n'} At each renewal the Insurer will
                recalculate the cost of cover and we let you know the quote for the upcoming year. There are a number of
                factors that can impact your renewal including the age of your pet and the increasing cost of veterinary
                treatment. The Insurer may also consider the amount claimed and the likelihood a condition will be
                ongoing when calculating your renewal. This will mean that the premium you pay will increase at renewal.
              </Text>
            </Box>
          </Box>
        </Stack>

        <Footer />

        <FloatingContainer>
          <ActionButton label="Confirm" onPress={confirmPolicyDeclarations} />
        </FloatingContainer>
      </ScreenWithTitleContainer>
    );
  };
