import React from 'react';
import { Box, Stack } from '@chakra-ui/react';

import { PolicyPricingSummarySchema } from '~/components/insurance-policy-display-form/insurance-policy-display-questionnaire.schema';
import { PolicySummaryKeyValue } from '~/components/insurance-policy-display-form/policy-summary-view/policy-summary-key-value/PolicySummaryKeyValue';
import { PolicySummaryContainerWithTitle } from '~/components/insurance-policy-display-form/policy-summary-view/policy-summary-container-with-title/PolicySummaryContainerWithTitle';

import { Colors } from '~/constants/colors';

interface PolicyPricingSummary {
  summaryValues: PolicyPricingSummarySchema;
}

export const PolicyPricingSummary: React.FC<PolicyPricingSummary> = function PolicyPricingSummary({ summaryValues }) {
  const content = React.useMemo(() => {
    const values = Object.values(summaryValues);
    return values.map(({ displayKey, value }, idx) => {
      const currentLine =
        value && idx !== values.length - 1 ? (
          <PolicySummaryKeyValue key={idx} summaryKey={displayKey} value={value} />
        ) : (
          <React.Fragment key={idx}>
            <Box w="100%" h="1px" background={Colors.Black} style={{ margin: '6px 0px 4px 0' }} />
            <PolicySummaryKeyValue summaryKey={displayKey} value={value} />
          </React.Fragment>
        );

      return currentLine;
    });
  }, [summaryValues]);

  return (
    <PolicySummaryContainerWithTitle title="Pricing summary">
      <Stack spacing="2px" marginTop="8px">
        {content}
      </Stack>
    </PolicySummaryContainerWithTitle>
  );
};
