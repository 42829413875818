import React from 'react';
import { Detail } from 'react-calendar';
import { useField } from 'formik';
import moment from 'moment';
import { Stack } from '@chakra-ui/react';

import DatePicker from 'react-date-picker/dist/entry.nostyle';

import {
  CAT_MAX_AGE_FOR_INSURANCE_IN_YEARS,
  DOG_MAX_AGE_FOR_INSURANCE_IN_YEARS,
  MIN_AGE_FOR_INSURANCE_IN_WEEKS,
} from '@shared/constants/validation';

import { ActionButton } from '~/components/common/ActionButton';
import { FormikInputErrorText } from '~/components/form/formik-input-error-text/FormikInputErrorText';
import { TitleText } from '~/components/common/TitleText';
import {
  CAT_OR_DOG_FIELD,
  PET_DATE_OF_BIRTH_FIELD,
} from '~/components/insurance-questionnaire/insurance-questionnaire.schema';

import { getTodaysDate } from '~/helpers/date';

import '~/components/questions/DateInputQuestion.scss';
import { DATE_OF_OWNER_BIRTH_FIELD } from '../insurance-policy-display-form/insurance-policy-display-questionnaire.schema';

const CALENDAR_DATE_FORMAT_MAP: Record<CalendarUnitsToPick, 'DD/MM/YYYY' | 'MM/YYYY' | 'YYYY'> = {
  day: 'DD/MM/YYYY',
  month: 'MM/YYYY',
  year: 'YYYY',
};

const CALENDAR_DETAILS_MAP: Record<
  CalendarUnitsToPick,
  {
    maxDetail: Detail;
    minDetail: Detail;
    defaultView: Detail;
  }
> = {
  day: {
    maxDetail: 'month',
    minDetail: 'year',
    defaultView: 'year',
  },
  month: {
    maxDetail: 'year',
    minDetail: 'year',
    defaultView: 'year',
  },
  year: {
    maxDetail: 'decade',
    minDetail: 'decade',
    defaultView: 'decade',
  },
};

type DateQuestionFieldName = typeof PET_DATE_OF_BIRTH_FIELD | typeof DATE_OF_OWNER_BIRTH_FIELD;
export type CalendarUnitsToPick = 'day' | 'month' | 'year';
const PET_OWNER_MAX_AGE_IN_YEARS = 150;

interface DateInputQuestionProps {
  name: DateQuestionFieldName; // is used to bind this question with Formik
  questionText: string; // label for question input
  placeholder?: string;
  actionButtonLabel: string;
  onActionButtonClick: () => void;
  calendarDateFormat?: string;
  unitsToPick?: CalendarUnitsToPick;
  className?: string;
}

export const DateInputQuestion: React.FC<DateInputQuestionProps> = function DateInputQuestion({
  name,
  questionText,
  actionButtonLabel,
  onActionButtonClick,
  calendarDateFormat,
  unitsToPick = 'day',
  className,
}) {
  const isPetDateOfBirthQuestion = name === PET_DATE_OF_BIRTH_FIELD;
  const dateFormat = calendarDateFormat ?? CALENDAR_DATE_FORMAT_MAP[unitsToPick];

  const [catOrDogFormikField] = useField(CAT_OR_DOG_FIELD);

  const [formikField, meta, formikHelpers] = useField(name);
  const dateValue = formikField.value ? moment(formikField.value, dateFormat).toDate() : null;

  // React DatePicker could accept date range as an input param
  const onDateChange = (nextValue: Date | [Date] | [Date, Date] | null) => {
    // Date picker can return arrays for date ranges, which we don't need,
    // but we have to check to make TS happy
    if (Array.isArray(nextValue)) {
      return;
    }

    formikHelpers.setTouched(true);
    if (!nextValue) {
      formikHelpers.setValue('');
    } else {
      formikHelpers.setValue(moment(nextValue).format(dateFormat));
    }
  };

  const minDate = getTodaysDate()
    .subtract(
      isPetDateOfBirthQuestion
        ? // The pet is not too old for insurance
          catOrDogFormikField.value === 'cat'
          ? CAT_MAX_AGE_FOR_INSURANCE_IN_YEARS
          : DOG_MAX_AGE_FOR_INSURANCE_IN_YEARS
        : PET_OWNER_MAX_AGE_IN_YEARS,
      'years',
    )
    // to not include the last date
    .add(1, 'days')
    .toDate();

  const maxDate = (
    isPetDateOfBirthQuestion
      ? // The pet is old enough for insurance
        getTodaysDate().subtract(MIN_AGE_FOR_INSURANCE_IN_WEEKS, 'weeks')
      : getTodaysDate()
  ).toDate();

  return (
    <Stack spacing={{ base: '80px', lg: '172px' }} align="center" className={className}>
      <Stack spacing={{ base: '80px', lg: '172px' }} align="center">
        <TitleText text={questionText} />
        <Stack spacing={1}>
          <DatePicker
            className="date-picker-input"
            value={dateValue}
            onChange={onDateChange}
            minDate={minDate}
            maxDate={maxDate}
            {...CALENDAR_DETAILS_MAP[unitsToPick]}
            dayPlaceholder="DD"
            monthPlaceholder="MM"
            yearPlaceholder="YYYY"
            {...(!dateValue ? { clearIcon: null } : {})}
            showLeadingZeros
          />

          <FormikInputErrorText fieldName={name} />
        </Stack>
      </Stack>

      <ActionButton
        label={actionButtonLabel}
        onPress={onActionButtonClick}
        disabled={Boolean(!meta.value || meta.error)}
      />
    </Stack>
  );
};

// Todo (later):
//  4. Update "manual input change" validation
//   - it's a problem for now, because "onChange" method is not triggered in case the input is not valid (more than maxDate / less than minDate)
//   - will be fixed later
//  6. Add onKeyPress Enter handler: keyEnterHandler={onActionButtonClick} OR useKeyEnterScreenHandler()
