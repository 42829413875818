import React from 'react';
import { useField } from 'formik';

import { Stack } from '@chakra-ui/react';

import { findPetBreedByName } from '@joinfluffy/common';

import { ActionButton } from '~/components/common/ActionButton';
import { AutoCompleteInput } from '~/components/form/auto-complete-input/AutoCompleteInput';
import { ChooseOptionFormikItemsList } from '~/components/questions/ChooseOptionFormikItemsList';
import { TitleText } from '~/components/common/TitleText';
import {
  CAT_OR_DOG_FIELD,
  InsuranceFormValues,
} from '~/components/insurance-questionnaire/insurance-questionnaire.schema';

import { useFormikInsuranceAvailableBreedOptions } from '~/hooks/useFormikInsuranceAvailableBreedOptions';
import { useKeyEnterScreenHandler } from '~/hooks/common/useKeyEnterScreenHandler';
import { useInsuranceFormBreedQuestionBackPressHandler } from '~/hooks/useInsuranceFormBreedQuestionBackPressHandler';
import { useInvokeModal } from '~/hooks/useInvokeModal';

import { trackEvent } from '~/helpers/analytics/trackEvent';

import { AnalyticsEvent } from '~/constants/analyticsEvent';
import { ModalTypes } from '~/constants/modalTypes';

interface BreedQuestionProps {
  formikName: string;
  fullListQuestionText: string; // label for question input
  mixedBreedQuestionText: string;
  placeholder?: string;
  actionButtonLabel: string;
  onActionButtonClick: () => void;
}

export const BreedQuestion: React.FC<BreedQuestionProps> = function BreedQuestion({
  formikName,
  fullListQuestionText,
  mixedBreedQuestionText,
  placeholder,
  actionButtonLabel,
  onActionButtonClick,
}) {
  const { invokeModal } = useInvokeModal();

  const [formikField, meta, formikHelpers] = useField(formikName);

  const initialRenderValueRef = React.useRef<string>(formikField.value ?? '');
  const [isPickingMixedBreed, setIsPickingMixedBreed] = React.useState<boolean>(false);

  const toggleIsPickingMixedBreed = React.useCallback(() => {
    if (formikField.value !== initialRenderValueRef.current) {
      formikHelpers.setValue('', false);
    }

    setIsPickingMixedBreed((prevState) => !prevState);
  }, [formikField.value, formikHelpers]);

  useInsuranceFormBreedQuestionBackPressHandler({
    isPickingMixedBreed,
    pickingMixedBreedToggler: toggleIsPickingMixedBreed,
  });

  const selectBreedHandler = React.useCallback(() => {
    const breed = findPetBreedByName(formikField.value);

    if (breed?.aliasToBreedId === 'N/A') {
      trackEvent({ eventName: AnalyticsEvent.QuoteSelectNotSupportedBreed, breed: breed.breedName });
      invokeModal(ModalTypes.NotSupportedBreed, { breed });
    } else {
      onActionButtonClick();
    }
  }, [formikField.value, invokeModal, onActionButtonClick]);

  // don't use `React.useMemo` here, because we will update .value / .error fields on every render
  const isActionDisabled = Boolean(!meta.value || meta.error);
  useKeyEnterScreenHandler({ actionToCall: selectBreedHandler, isActionDisabled });

  const breedOptions = useFormikInsuranceAvailableBreedOptions({ needPickMixedBreed: isPickingMixedBreed });

  const [catOrDogFormikField] = useField<InsuranceFormValues[typeof CAT_OR_DOG_FIELD]>(CAT_OR_DOG_FIELD);
  const petLabel = React.useMemo(() => {
    switch (catOrDogFormikField.value) {
      case 'cat':
        return 'cat';
      case 'dog':
        return 'dog';
      default:
        return 'pet';
    }
  }, [catOrDogFormikField.value]);

  const canNotFindBreedHandler = React.useCallback(() => {
    trackEvent({ eventName: AnalyticsEvent.QuoteCanNotFindBreed });

    invokeModal(ModalTypes.NotSupportedBreed, { breed: { type: catOrDogFormikField.value } });
  }, [catOrDogFormikField.value, invokeModal]);

  return (
    <Stack spacing={8} minW={320} alignItems="center">
      <Stack spacing={6} width="100%" alignItems="center">
        <TitleText text={isPickingMixedBreed ? mixedBreedQuestionText : fullListQuestionText} />
        {isPickingMixedBreed ? (
          <ChooseOptionFormikItemsList
            name={formikName}
            options={breedOptions}
            onSelect={selectBreedHandler}
            style={{ paddingTop: '44px' }}
          />
        ) : (
          <AutoCompleteInput
            name={formikName}
            options={breedOptions}
            placeholder={placeholder}
            variant="flushed"
            size="md"
          />
        )}
        <ActionButton
          onPress={toggleIsPickingMixedBreed}
          label={isPickingMixedBreed ? 'Back to breed list' : `I have a mixed breed ${petLabel}`}
          isSecondary
        />
        <ActionButton onPress={canNotFindBreedHandler} label="I can't find my breed" isSecondary />
      </Stack>

      {!isPickingMixedBreed && (
        <ActionButton onPress={selectBreedHandler} label={actionButtonLabel} disabled={isActionDisabled} />
      )}
    </Stack>
  );
};
