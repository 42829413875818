import { useField } from 'formik';

import { CONFIRM_EMAIL_FIELD, EMAIL_FIELD } from '~/components/insurance-questionnaire/insurance-questionnaire.schema';

export function useIsOwnerEmailFormActionButtonEnabled() {
  const [emailField, emailFieldMeta] = useField(EMAIL_FIELD);
  const [confirmEmailField, confirmEmailFieldMeta] = useField(CONFIRM_EMAIL_FIELD);

  return Boolean(emailField.value && !emailFieldMeta.error && confirmEmailField.value && !confirmEmailFieldMeta.error);
}
