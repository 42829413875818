import React from 'react';
import { useNavigate, useRouteError } from 'react-router-dom';
import { Box, Flex, Image } from '@chakra-ui/react';

import { ScreenWithTitleContainer } from '~/components/common/ScreenWithTitleContainer';
import { Header } from '~/components/common/Header';
import { TitleText } from '~/components/common/TitleText';

import { captureAndLogException } from '~/helpers/monitoring/captureAndLogException';

const IMAGE_PATH = '/images/dogImages/30-chill-dog/30-chill-dog-800.png';

export function ErrorPage() {
  const navigate = useNavigate();

  // todo: refactor (set the right type and ? remove cast)
  const error = useRouteError() as { statusText?: string; message?: string };
  captureAndLogException(`[Route Error]: ${JSON.stringify(error)}`, 'Error');

  return (
    <div id="error-page">
      <Header />
      <Flex marginTop={{ base: '60px', lg: '84px' }} direction="column" align="center" padding="0 16px 120px 16px">
        <ScreenWithTitleContainer
          title="Oops! This page can't be found"
          spacing="20px"
          buttonLabel="Back to the main page"
          onActionButtonClick={() => navigate('/quote')}
        >
          <Box marginTop="36px" maxWidth={{ lg: '600px' }}>
            <TitleText text="Seems like the pawesome page that you've requested doesn't exist" type="SUBTITLE" />
          </Box>

          <Box height={{ base: '240px', lg: '360px' }} width={{ base: '240px', lg: '360px' }}>
            <Image src={IMAGE_PATH} height="100%" width="100%" />
          </Box>
        </ScreenWithTitleContainer>
      </Flex>
    </div>
  );
}
