import React from 'react';
import { useField } from 'formik';

import { Select, SelectProps, Stack } from '@chakra-ui/react';

import { FormikInputErrorText } from '~/components/form/formik-input-error-text/FormikInputErrorText';
import { SelectOption } from '~/components/form/formik.schema';
import { useInputStyle } from '~/components/form/formik.style';

interface FormikSelectProps<T extends string> extends SelectProps {
  name: string; // re-declare here to make it required
  options: SelectOption<T>[];
}

export function FormikSelect<T extends string>({ name, options, ...selectProps }: FormikSelectProps<T>) {
  // second parameter (meta) is not used now
  const [formikField, , formikHelper] = useField(name);

  const inputStyles = useInputStyle(selectProps.variant);

  const onChangeHandler = React.useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const value = event.target.value;
      formikHelper.setTouched(true);
      formikHelper.setValue(value);
    },
    [formikHelper],
  );

  return (
    <Stack spacing={1}>
      <Select
        {...selectProps}
        {...inputStyles}
        {...formikField}
        value={formikField.value ?? undefined}
        onChange={onChangeHandler}
      >
        {options.map(({ value, label }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </Select>

      <FormikInputErrorText fieldName={name} />
    </Stack>
  );
}
