import React from 'react';
import { useField } from 'formik';
import { Stack } from '@chakra-ui/react';

import { ActionButton } from '~/components/common/ActionButton';
import {
  InsuranceFormValues,
  PET_DATE_OF_BIRTH_FIELD,
} from '~/components/insurance-questionnaire/insurance-questionnaire.schema';
import { CalendarUnitsToPick, DateInputQuestion } from '~/components/questions/DateInputQuestion';
import { ChooseOptionItem } from '~/components/questions/ChooseOptionItem';

import { useFormikInsuranceFormDisplayFormatPetName } from '~/hooks/useFormikInsuranceFormDisplayFormatPetName';
import { usePetBirthDateMissedFormikFields } from '~/hooks/insurance-form/usePetBirthDateMissedFormikFields';

import '~/components/questions/PetAgeQuestion.scss';

interface PetAgeQuestionProps {
  actionButtonLabel: string;
  onActionButtonClick: () => void;
}

export const PetAgeQuestion: React.FC<PetAgeQuestionProps> = function PetAgeQuestion({
  actionButtonLabel,
  onActionButtonClick,
}) {
  const { isDdMissedField, ddMissedHelper, isDdMmMissedField, ddMmMissedHelper } = usePetBirthDateMissedFormikFields();
  const [isIncompleteDate, setIsIncompleteDate] = React.useState<boolean>(
    isDdMissedField.value || isDdMmMissedField.value,
  );

  const [, , petDateOfBirthHelper] =
    useField<InsuranceFormValues[typeof PET_DATE_OF_BIRTH_FIELD]>(PET_DATE_OF_BIRTH_FIELD);

  const toggleDateIncomplete = React.useCallback(() => {
    // reset form's flag with missed data
    ddMissedHelper.setValue(false);
    ddMmMissedHelper.setValue(false);

    setIsIncompleteDate((prevState) => !prevState);
    petDateOfBirthHelper.setValue('');
  }, [ddMissedHelper, ddMmMissedHelper, petDateOfBirthHelper]);

  return (
    <Stack spacing={2} className="pet-age-question">
      {isIncompleteDate ? (
        <IncompleteDatePetAgeQuestion actionButtonLabel={actionButtonLabel} onActionButtonClick={onActionButtonClick} />
      ) : (
        <PetAgeInput
          unitsToPick="day"
          actionButtonLabel={actionButtonLabel}
          onActionButtonClick={onActionButtonClick}
        />
      )}

      <ActionButton
        label={isIncompleteDate ? `I know the exact date of birth` : `I don't know the exact date of birth`}
        onPress={toggleDateIncomplete}
        isSecondary
      />
    </Stack>
  );
};

const IncompleteDatePetAgeQuestion: React.FC<PetAgeQuestionProps> = function IncompleteDatePetAgeQuestion({
  actionButtonLabel,
  onActionButtonClick,
}) {
  const { isDdMissedField, ddMissedHelper, isDdMmMissedField, ddMmMissedHelper } = usePetBirthDateMissedFormikFields();

  const pickKnowMmYyyyOnly = React.useCallback(() => {
    ddMissedHelper.setValue(true);
    ddMmMissedHelper.setValue(false);
  }, [ddMissedHelper, ddMmMissedHelper]);

  const pickKnowYyyyOnly = React.useCallback(() => {
    ddMissedHelper.setValue(false);
    ddMmMissedHelper.setValue(true);
  }, [ddMissedHelper, ddMmMissedHelper]);

  // user hasn't picked what piece of info they know
  if (!isDdMissedField.value && !isDdMmMissedField.value) {
    return (
      <Stack spacing={2} alignItems="center">
        <ChooseOptionItem label="I know the month and year" onSelect={pickKnowMmYyyyOnly} />
        <ChooseOptionItem label="I only know the year" onSelect={pickKnowYyyyOnly} />
      </Stack>
    );
  }

  const unitToPick: CalendarUnitsToPick = isDdMmMissedField.value
    ? // user knows YYYY only
      'year'
    : // user knows MM/YYYY only
      'month';

  return (
    <PetAgeInput
      unitsToPick={unitToPick}
      actionButtonLabel={actionButtonLabel}
      onActionButtonClick={onActionButtonClick}
    />
  );
};

const PetAgeInput: React.FC<
  {
    unitsToPick: CalendarUnitsToPick;
  } & PetAgeQuestionProps
> = function PetAgeInput({ unitsToPick, actionButtonLabel, onActionButtonClick }) {
  const petName = useFormikInsuranceFormDisplayFormatPetName();

  return (
    <DateInputQuestion
      name={PET_DATE_OF_BIRTH_FIELD}
      unitsToPick={unitsToPick}
      questionText={`What's ${petName}'s date of birth?`}
      actionButtonLabel={actionButtonLabel}
      onActionButtonClick={onActionButtonClick}
    />
  );
};
