import React from 'react';

import { Box, Text } from '@chakra-ui/react';

import { Colors } from '~/constants/colors';

export const Footer: React.FC = function Footer() {
  return (
    <Box>
      <Text fontSize={{ base: '10px', lg: '12px' }} lineHeight="16px" fontWeight="400" color={Colors.PaleBrow}>
        Strelka LTD, trading as Fluffy, Firm Reference No. 986188, is an appointed representative of Innovative Risk
        Labs Ltd, which is authorised and regulated by the Financial Conduct Authority, Firm Reference No. 609155.
        Cover-More Blue Insurance Services Limited, trading as InsureYourPaws.co.uk is a private limited company
        incorporated in Ireland with company number 345681 and with its UK branch office registered at 82 Oxford Road,
        Uxbridge, Middlesex, UB8 1UX. It is authorised and regulated in the United Kingdom by the Financial Conduct
        Authority, Firm Reference No. 984290.
      </Text>
    </Box>
  );
};
