import React from 'react';

import { useUiStateContextPatchUiState } from '~/hooks/useUiStateContextUpdateUi';

interface InsuranceFormBreedQuestionBackPressHandlerProps {
  isPickingMixedBreed: boolean;
  pickingMixedBreedToggler: (value?: boolean) => void;
}

export function useInsuranceFormBreedQuestionBackPressHandler({
  isPickingMixedBreed,
  pickingMixedBreedToggler,
}: InsuranceFormBreedQuestionBackPressHandlerProps) {
  const patchUiState = useUiStateContextPatchUiState();

  const cleanUibackButtonPressHandler = React.useCallback(() => {
    patchUiState && patchUiState({ backButtonPressHandler: undefined });
  }, [patchUiState]);

  React.useEffect(() => {
    if (!patchUiState) {
      return;
    }

    if (isPickingMixedBreed) {
      patchUiState({
        backButtonPressHandler: () => {
          pickingMixedBreedToggler(false);
        },
      });
    } else {
      cleanUibackButtonPressHandler();
    }

    return () => {
      cleanUibackButtonPressHandler();
    };
  }, [isPickingMixedBreed, patchUiState, cleanUibackButtonPressHandler, pickingMixedBreedToggler]);
}
