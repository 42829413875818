import React from 'react';

import * as Sentry from '@sentry/react';

import { IntercomProvider } from 'react-use-intercom';

import { AppRouter } from '~/components/pages/AppRouter';

import { AnalyticsProviders } from '~/providers/AnalyticsProviders';
import { FeatureFlagsProvider } from '~/providers/FeatureFlagsProvider';
import { UIStateProvider } from '~/providers/UIStateProvider';

import { trackEvent } from '~/helpers/analytics/trackEvent';

import { AnalyticsEvent } from '~/constants/analyticsEvent';

import './App.scss';

const INTERCOM_APP_ID = 'm07vkb2u';

function App() {
  return (
    <AnalyticsProviders>
      <FeatureFlagsProvider>
        <IntercomProvider
          appId={INTERCOM_APP_ID}
          autoBoot
          onShow={() => trackEvent({ eventName: AnalyticsEvent.QuoteOpenChat })}
          onHide={() => trackEvent({ eventName: AnalyticsEvent.QuoteCloseChat })}
        >
          <div className="App">
            <main>
              <UIStateProvider>
                <AppRouter />
              </UIStateProvider>
            </main>
          </div>
        </IntercomProvider>
      </FeatureFlagsProvider>
    </AnalyticsProviders>
  );
}

export default Sentry.withProfiler(App);
