export const PRIVACY_POLICY_FLUFFY_URL = 'https://www.joinfluffy.com/privacy-policy';
export const PRIVACY_POLICY_CM_URL = 'https://www.blueinsurance.ie/PrivacyPolicy/UK/';
export const MIN_VET_COVER_PRODUCT_INFORMATION_URL =
  'https://www.insureyourpaws.co.uk/Content/documents/IPID/IPID_IYP_LT_5K_140920.pdf';
export const MID_VET_COVER_PRODUCT_INFORMATION_URL =
  'https://www.insureyourpaws.co.uk/Content/documents/IPID/IPID_IYP_LT_7.5K_140920.pdf';
export const MAX_VET_COVER_PRODUCT_INFORMATION_URL =
  'https://www.insureyourpaws.co.uk/Content/documents/IPID/IPID_IYP_LT_10K_140920.pdf';
export const TERMS_OF_BUSINESS_URL = 'https://www.insureyourpaws.co.uk/Documents/Toba';
export const POLICY_DOCUMENT_STATEMENTS_URL =
  'https://www.insureyourpaws.co.uk/Content/documents/PolicyDoc/2076-IYP-UK-Max-Lifetime-Benefit-Policy-A5.pdf';
export const INSURANCE_ASSUMPTIONS_URL = 'https://www.joinfluffy.com/insurance-assumptions';
