import React from 'react';
import { Box, HStack, Text } from '@chakra-ui/react';

import { Colors } from '~/constants/colors';

interface PolicySummaryKeyValueProps {
  summaryKey?: string;
  value?: string;
}

// let's use min 12px fontSize - especially for mobile devices (to have readable text)
// still can use 10px for tooltips (should be super-rare case)
type FontSize = `${'12' | '14'}px`;
type LineHeight = `${'14' | '16' | '20'}px`;

export const PolicySummaryKeyValue: React.FC<PolicySummaryKeyValueProps> = function PolicySummaryKeyValue({
  summaryKey,
  value,
}) {
  const fontSize: FontSize = '12px';
  const lineHeight: LineHeight = '20px';

  return (
    <HStack spacing="8px">
      {summaryKey && (
        <Box minW="72px">
          <Text textAlign="left" fontSize={fontSize} lineHeight={lineHeight} fontWeight="700" color={Colors.Black}>
            {summaryKey}
          </Text>
        </Box>
      )}

      <Box>
        <Text textAlign="left" fontSize={fontSize} lineHeight={lineHeight} fontWeight="400" color={Colors.Black}>
          {value}
        </Text>
      </Box>
    </HStack>
  );
};
