import React from 'react';
import { Box, calc, Flex, HStack, Text } from '@chakra-ui/react';
import { useField, useFormikContext } from 'formik';

import { Currency } from '@shared/constants/currency';
import { PolicyCodes } from '@shared/constants/policyCodes';

import { ActionButton } from '~/components/common/ActionButton';
import { PolicyItem } from '~/components/insurance-policy-display-form/policy-display-plans-view/policy-item/PolicyItem';
import { PlanBenefitsSection } from '~/components/insurance-policy-display-form/policy-display-plans-view/plan-benefits-section/PlanBenefitsSection';
import { PolicyPerksSection } from '~/components/insurance-policy-display-form/policy-display-plans-view/plan-perks-section/PolicyPerksSection';
import { PolicyPriceLabel } from '~/components/insurance-policy-display-form/policy-display-plans-view/policy-price-label/PolicyPriceLabel';
import { PolicyViewStepProps } from '~/components/insurance-policy-display-form/insurance-policy-display-questionnaire/InsurancePolicyDisplayQuestionnaire';
import {
  InsurancePolicyFormValues,
  POLICY_CODE_FIELD,
} from '~/components/insurance-policy-display-form/insurance-policy-display-questionnaire.schema';

import { useInsurancePolicyList } from '~/hooks/insurance-data-provider/policy/useInsurancePolicyList';
import { useFilledInsuranceFormValues } from '~/hooks/insurance-data-provider/useFilledInsuranceFormValues';
import { useSelectedPolicyPerks } from '~/hooks/insurance-data-provider/policy/useSelectedPolicyPerks';
import { useSelectedPolicyCoverLabels } from '~/hooks/insurance-data-provider/policy/useSelectedPolicyCoverLabels';
import { useSelectedPolicyMonthlyPrice } from '~/hooks/insurance-data-provider/policy/useSelectedPolicyMonthlyPrice';
import { useSelectedPolicyData } from '~/hooks/insurance-data-provider/policy/useSelectedPolicyData';

import { getPolicyBenefitData } from '~/helpers/insuranceCoveragePolicies/insuranceCoveragePolicies';
import { trackEvent } from '~/helpers/analytics/trackEvent';
import { captureAndLogException } from '~/helpers/monitoring/captureAndLogException';
import { formatPetNameForDisplay } from '~/helpers/pet/petName';

import { Colors } from '~/constants/colors';

import { AnalyticsWithoutParamsSchema } from '~/models/analyticsParams';

import { POLICY_SELECTION_TRACKING_EVENTS_CONFIG } from '~/configs/tracking';
import { FEE_CONFIG } from '~/configs/fee';

export const PolicyDisplayPlansView: React.FC<PolicyViewStepProps> = function PolicyDisplayPlansView({
  onValueSelect,
}) {
  const formik = useFormikContext<InsurancePolicyFormValues>();
  const [field] = useField<PolicyCodes>(POLICY_CODE_FIELD);

  const selectPlan = React.useCallback(
    (policyCode: string) => () => {
      formik.setFieldValue(POLICY_CODE_FIELD, policyCode);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formik.setFieldValue],
  );

  const policyList = useInsurancePolicyList();
  const insuranceFormData = useFilledInsuranceFormValues();
  const perksData = useSelectedPolicyPerks(field.value);
  const selectedPolicy = useSelectedPolicyData(field.value);
  const policyCoverageLabels = useSelectedPolicyCoverLabels(field.value);
  const policyPlanMonthlyPrice = useSelectedPolicyMonthlyPrice(field.value);

  const petName = formatPetNameForDisplay(insuranceFormData?.petName ?? 'your pet');
  const actionButtonLabel = `Let's get ${petName} covered`;
  const { currency, amount: feeAmount } = FEE_CONFIG['ADMIN'];

  const confirmPolicySelection = React.useCallback(() => {
    trackEvent({ eventName: POLICY_SELECTION_TRACKING_EVENTS_CONFIG[field.value] } as AnalyticsWithoutParamsSchema);

    onValueSelect();
  }, [field.value, onValueSelect]);

  if (!policyList) {
    captureAndLogException('[PolicyDisplayPlansView]: policyList is not defined', 'Warn');

    return null;
  }

  return (
    <Flex
      minHeight={{ lg: calc('100vh').subtract('106px').toString() }}
      direction="column"
      justify="space-between"
      alignItems="center"
      marginTop={{ base: '66px', lg: '44px' }}
    >
      <Box>
        <Text
          fontSize={{ base: '24px', lg: '36px' }}
          lineHeight={{ base: '32px', lg: '36px' }}
          color={Colors.Brown}
          fontWeight="700"
        >
          Pick a plan for {petName}
        </Text>
        <HStack spacing="8px" marginTop={{ base: '32px', lg: '28px' }} justifyContent="center">
          {policyList.map((policy) => (
            <PolicyItem
              key={policy.productCode}
              policyBenefitData={getPolicyBenefitData(policy, 'VET_FEES')}
              onPress={selectPlan(policy.productCode)}
              isActive={policy.productCode === field.value}
            />
          ))}
        </HStack>

        <Flex direction="column" align="center">
          <Flex direction={{ base: 'column', lg: 'row-reverse' }}>
            {policyCoverageLabels && (
              <Box marginTop="36px" marginLeft={{ lg: '40px' }}>
                <PlanBenefitsSection benefits={policyCoverageLabels} />
              </Box>
            )}
            {perksData && (
              <Box marginTop="36px">
                <PolicyPerksSection perks={perksData} />
              </Box>
            )}
          </Flex>
        </Flex>
      </Box>

      <Box marginY="32px">
        <Box paddingBottom="4px">
          <PolicyPriceLabel policyPeriodTitle="Monthly price" price={`${Currency.GBP}${policyPlanMonthlyPrice}`} />
        </Box>
        <Box paddingX="20px" paddingY="12px">
          <Text fontSize={{ base: '10px', lg: '12px' }} color={Colors.Brown}>
            Annual price is {currency}
            {selectedPolicy?.annualPrice} includes 12% IPT and {currency}
            {feeAmount} admin fee
          </Text>
        </Box>
        <ActionButton label={actionButtonLabel} onPress={confirmPolicySelection} disabled={!field.value} />
      </Box>

      <Box>
        <Text fontSize={{ base: '10px', lg: '12px' }} lineHeight="16px" fontWeight="400" color={Colors.PaleBrow}>
          This pet insurance is underwritten by Covea Insurance plc. This quote is valid for 30 days provided all the
          information you have given to us is correct and does not change.
        </Text>
      </Box>
    </Flex>
  );
};
