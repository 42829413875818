import React from 'react';
import {
  Button,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Stack,
  StyleProps,
  Text,
} from '@chakra-ui/react';
import { PlacementWithLogical } from '@chakra-ui/popper/dist/popper.placement';

import { Colors } from '~/constants/colors';

import { ReactComponent as InfoIcon } from '~/assets/icons/info-icon.svg';

interface PopoverInfoButtonProps {
  popoverTextContent?: string;
  size?: StyleProps['height'];
  placement?: PlacementWithLogical;
}

export const PopoverInfoButton: React.FC<PopoverInfoButtonProps> = function PopoverInfoButton({
  popoverTextContent,
  size = {
    base: '32px',
    lg: '52px',
  },
  placement = 'top-end',
}) {
  if (!popoverTextContent) {
    return null;
  }

  return (
    <Popover arrowSize={12} arrowPadding={12} placement={placement}>
      <PopoverTrigger>
        <Button
          _hover={{ opacity: 0.5 }}
          _active={{}} // resets default chakra _active styles
          bg={Colors.Transparent}
          height={size}
          width={size}
          padding={0}
        >
          <InfoIcon width="100%" height="100%" />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        bg={Colors.Brown}
        color={Colors.White}
        w="345px"
        padding="20px 20px 12px 20px"
        borderRadius="16px"
      >
        <PopoverArrow bg={Colors.Brown} />
        <PopoverCloseButton bg={Colors.Transparent} size="20px" color={Colors.Grey} />
        <PopoverBody whiteSpace="pre-line" textAlign="left">
          <Stack spacing={2}>
            <Text fontSize="14px" lineHeight="24px" fontWeight="400">
              {popoverTextContent}
            </Text>
          </Stack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
