import React from 'react';

import { createAnalyticsBrowser } from '~/helpers/analytics/analytics';
import { captureAndLogException } from '~/helpers/monitoring/captureAndLogException';

export function useInitSegmentAnalytics() {
  const [initFinished, setInitFinished] = React.useState<boolean>(false);

  React.useEffect(() => {
    const analyticsBrowser = createAnalyticsBrowser();

    try {
      analyticsBrowser.page();
    } catch (err) {
      captureAndLogException(`[Segment]: Page method call error: "{${JSON.stringify(err)}}"`, 'Warn');
    } finally {
      setInitFinished(true);
    }
  }, []);

  return { initFinished };
}
