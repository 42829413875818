import { PolicyCodes } from '@shared/constants/policyCodes';

import { Title } from '@shared/models/customer';

import { CUSTOMER_TITLE_LIST } from '@shared/data/customer';

import { SelectOption } from '~/components/form/formik.schema';

export const CUSTOMER_TITLE_SELECTION_OPTION_LIST: SelectOption<Title>[] = CUSTOMER_TITLE_LIST.map((title) => {
  return {
    value: title,
    label: title,
  };
});

export interface PolicySummaryParameterSchema {
  displayKey: string;
  value: string;
}

export interface PolicyPricingSummarySchema {
  monthlyPrice: PolicySummaryParameterSchema;
  annualInsurancePrice: PolicySummaryParameterSchema;
  adminFee: PolicySummaryParameterSchema;
  discount: PolicySummaryParameterSchema;
  totalAnnualPrice: PolicySummaryParameterSchema;
}

export const HAS_ALREADY_INSURANCE_POLICY = 'hasAlreadyInsurancePolicy';
export type PolicyDisplayFormQuestionType =
  | 'HAS_ALREADY_A_POLICY'
  | 'POLICY_SUMMARY_CONFIRMATION'
  | 'POLICY_DECLARATION'
  | 'OWNER_INFO'
  | 'OWNER_ADDRESS'
  | 'IS_PET_MICROCHIPPED'
  | 'DATE_OF_OWNER_BIRTH';

export type PolicyDisplayFormField =
  | typeof POLICY_CODE_FIELD
  | typeof IS_POLICY_SUMMARY_CONFIRMED_FIELD
  | typeof ARE_TERMS_OF_COVER_CONFIRMED_FIELD
  | typeof ARE_POLICY_AND_BUSINESS_DOCUMENTS_READ_FIELD
  | typeof TITLE_FIELD
  | typeof FIRST_NAME_FIELD
  | typeof LAST_NAME_FIELD
  | typeof PHONE_NUMBER_FIELD
  | typeof ADDRESS_LINE_1_FIELD
  | typeof ADDRESS_LINE_2_FIELD
  | typeof ADDRESS_LINE_3_FIELD
  | typeof DATE_OF_OWNER_BIRTH_FIELD
  | typeof IS_MICROCHIPPED_FIELD
  | typeof HAS_ALREADY_INSURANCE_POLICY;

export const POLICY_CODE_FIELD = 'policyCode';
export const IS_POLICY_SUMMARY_CONFIRMED_FIELD = 'isPolicySummaryConfirmed';
export const ARE_TERMS_OF_COVER_CONFIRMED_FIELD = 'areTermsOfCoverConfirmed';
export const ARE_POLICY_AND_BUSINESS_DOCUMENTS_READ_FIELD = 'arePolicyAndBusinessDocumentRead';
export const TITLE_FIELD = 'title';
export const FIRST_NAME_FIELD = 'firstName';
export const LAST_NAME_FIELD = 'lastName';
export const PHONE_NUMBER_FIELD = 'phoneNumber';
export const ADDRESS_LINE_1_FIELD = 'addressLine1';
export const ADDRESS_LINE_2_FIELD = 'addressLine2';
export const ADDRESS_LINE_3_FIELD = 'addressLine3';
export const DATE_OF_OWNER_BIRTH_FIELD = 'dateOfOwnerBirth';
export const IS_MICROCHIPPED_FIELD = 'isMicroChipped';

export interface InsurancePolicyFormValues {
  [HAS_ALREADY_INSURANCE_POLICY]?: boolean;
  [POLICY_CODE_FIELD]: PolicyCodes;
  [IS_POLICY_SUMMARY_CONFIRMED_FIELD]: boolean;
  [ARE_TERMS_OF_COVER_CONFIRMED_FIELD]: boolean;
  [ARE_POLICY_AND_BUSINESS_DOCUMENTS_READ_FIELD]: boolean;
  [IS_MICROCHIPPED_FIELD]: boolean | null;
  [ADDRESS_LINE_1_FIELD]: string;
  [ADDRESS_LINE_2_FIELD]: string;
  [ADDRESS_LINE_3_FIELD]: string;
  [DATE_OF_OWNER_BIRTH_FIELD]: string;
  [TITLE_FIELD]: Title | null;
  [FIRST_NAME_FIELD]: string;
  [LAST_NAME_FIELD]: string;
  [PHONE_NUMBER_FIELD]: string;
}

export const INSURANCE_POLICY_FORM_INITIAL_VALUE: InsurancePolicyFormValues = {
  [HAS_ALREADY_INSURANCE_POLICY]: undefined,
  [POLICY_CODE_FIELD]: PolicyCodes.LT2,
  [IS_POLICY_SUMMARY_CONFIRMED_FIELD]: false,
  [ARE_TERMS_OF_COVER_CONFIRMED_FIELD]: false,
  [ARE_POLICY_AND_BUSINESS_DOCUMENTS_READ_FIELD]: false,
  [IS_MICROCHIPPED_FIELD]: null,
  [ADDRESS_LINE_1_FIELD]: '',
  [ADDRESS_LINE_2_FIELD]: '',
  [ADDRESS_LINE_3_FIELD]: '',
  [DATE_OF_OWNER_BIRTH_FIELD]: '',
  [TITLE_FIELD]: null,
  [FIRST_NAME_FIELD]: '',
  [LAST_NAME_FIELD]: '',
  [PHONE_NUMBER_FIELD]: '',
};

export type LocallyStoredInsurancePolicyFormValues = Pick<
  InsurancePolicyFormValues,
  | typeof HAS_ALREADY_INSURANCE_POLICY
  | typeof POLICY_CODE_FIELD
  | typeof IS_POLICY_SUMMARY_CONFIRMED_FIELD
  | typeof ARE_TERMS_OF_COVER_CONFIRMED_FIELD
  | typeof ARE_POLICY_AND_BUSINESS_DOCUMENTS_READ_FIELD
>;
