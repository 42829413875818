import React from 'react';
import { Box, Flex, Text, Wrap, WrapItem } from '@chakra-ui/react';

import { FluffyAppBenefits } from '@shared/data/policyBenefits';
import { ProductBenefitCode, ProductBenefitSchema } from '@shared/models/product';

import { PerkItem } from '~/components/common/PerkItem';

import {
  formatPolicyPerkAmountLabel,
  formatPolicyPerkNameLabel,
} from '~/helpers/insuranceCoveragePolicies/insuranceCoveragePolicies';

import { useInvokeModal } from '~/hooks/useInvokeModal';

import { Colors } from '~/constants/colors';
import { ModalTypes } from '~/constants/modalTypes';

import { PerkInfoConfig } from '~/configs/perkInfoConfig';

interface PolicyPerksSectionProps {
  perks: ProductBenefitSchema[];
}

export const PolicyPerksSection: React.FC<PolicyPerksSectionProps> = function PolicyPerksSection({ perks }) {
  const { invokeModal } = useInvokeModal();

  const openPerkInfoModal = React.useCallback(
    (code: ProductBenefitCode | FluffyAppBenefits) => () => {
      invokeModal(ModalTypes.PerkInfo, { code });
    },
    [invokeModal],
  );

  return (
    <Flex direction="column" alignItems="flex-start">
      <Box>
        <Text fontSize="18px" lineHeight="32px" color={Colors.Brown} fontWeight="900">
          Every policy includes these benefits and more
        </Text>
      </Box>
      <Wrap marginTop="18px" spacing="16px" maxW={{ lg: '471px' }} justify="center">
        {perks.map(({ code, amount }) => (
          <WrapItem key={code}>
            <Box as="button" onClick={openPerkInfoModal(code)}>
              <PerkItem
                imgSrc={PerkInfoConfig[code].smallImgSrc}
                title={formatPolicyPerkNameLabel(PerkInfoConfig[code].title)}
                subTitle={formatPolicyPerkAmountLabel(amount)}
              />
            </Box>
          </WrapItem>
        ))}
      </Wrap>
      <Box marginTop="20px">
        <Text fontSize="18px" lineHeight="32px" color={Colors.Brown} fontWeight="900">
          Get 20+ perks with the Fluffy app
        </Text>
      </Box>
      <Wrap marginTop="18px" spacing="16px" maxW={{ lg: '471px' }} justify="center">
        {Object.values(FluffyAppBenefits).map((perkName, idx) => (
          <WrapItem key={idx}>
            <Box as="button" onClick={openPerkInfoModal(perkName)}>
              <PerkItem imgSrc={PerkInfoConfig[perkName].smallImgSrc} title={formatPolicyPerkNameLabel(perkName)} />
            </Box>
          </WrapItem>
        ))}
      </Wrap>
    </Flex>
  );
};
