import React from 'react';

import { useUiStateContextModal } from '~/hooks/useUiStateContextModal';

import { ModalTypes } from '~/constants/modalTypes';

import { useInvokeModal } from '~/hooks/useInvokeModal';

export function useInvokeErrorModal() {
  const currentModal = useUiStateContextModal();
  const { invokeModal } = useInvokeModal();

  const invokeErrorModal = React.useCallback(
    (error: Error, customAction?: () => void) => {
      if (!(currentModal?.type === ModalTypes.ErrorInfo)) {
        invokeModal(ModalTypes.ErrorInfo, { error, customAction });
      }
    },
    [currentModal?.type, invokeModal],
  );

  return { invokeErrorModal };
}
