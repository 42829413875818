import React from 'react';
import { useField, useFormikContext } from 'formik';

import { Center, HStack, Stack, Text } from '@chakra-ui/react';

import { TitleText } from '~/components/common/TitleText';
import { ActionableItemProps, ChooseItemOption } from '~/components/questions/questions.schema';

import { Colors } from '~/constants/colors';

interface BinaryQuestionProps<OptionValueType extends string | boolean> extends ActionableItemProps<OptionValueType> {
  options: [ChooseItemOption<OptionValueType>, ChooseItemOption<OptionValueType>]; // to have only 2 options
}

export function BinaryQuestion<OptionValueType extends string | boolean>({
  name,
  questionText,
  options,
  onSelect,
}: BinaryQuestionProps<OptionValueType>) {
  const [, meta] = useField(name);
  const formik = useFormikContext();

  const getItemSelectionHandler = (value: OptionValueType) => async () => {
    formik.setFieldTouched(name);
    formik.setFieldValue(name, value);

    onSelect(value);
  };

  return (
    <Stack spacing={{ base: '44px', lg: '64px' }} align="center">
      <TitleText text={questionText} />

      <Stack>
        <HStack spacing="16px">
          {options.map(({ value, label }) => (
            <BinaryQuestionItem
              key={value.toString()}
              label={label}
              onSelect={getItemSelectionHandler(value)}
              isSelected={meta.value === value}
            />
          ))}
        </HStack>

        <Text fontSize="xs" color="red.600" maxWidth="320px">
          {meta.touched && meta.error ? meta.error : null}
        </Text>
      </Stack>
    </Stack>
  );
}

const BinaryQuestionItem: React.FC<{
  label: string;
  isSelected?: boolean;
  onSelect: () => void;
}> = function BinaryQuestionItem({ isSelected, label, onSelect }) {
  return (
    <Center
      onClick={onSelect}
      width="156px"
      height="216px"
      borderRadius="20px"
      bg={isSelected ? Colors.OrangePale : Colors.White}
      cursor="pointer"
      _hover={isSelected ? undefined : { borderColor: '#999' }}
    >
      <Text fontSize="32px" lineHeight="64px" color={Colors.Orange} fontWeight="700">
        {label}
      </Text>
    </Center>
  );
};
