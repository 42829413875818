import { ProductBenefitCode } from '@shared/models/product';

export enum PolicyBenefits {
  VetFees = 'Vet Fees',
  Excess = 'Excess',
  DeathFromAccident = 'Death from Accident',
  ThirdPartyLiabilityDogsOnly = 'Third Party Liability (Dogs Only)',
  DeathFromIllness = 'Death from Illness',
  BoardingFees = 'Boarding Fees',
  TheftOrStraying = 'Theft / Straying',
  HolidayCancellation = 'Holiday Cancellation',
  AdvertisingAndReward = 'Advertising & Reward',
  LossOfPetPassport = 'Loss of Pet Passport',
  OverseasTravel = 'Overseas Travel',
  Quarantine = 'Quarantine',
  EmergencyExpensesCoverAbroad = 'Emergency Expenses Cover Abroad',
  AccidentalDamage = 'Accidental Damage',
  VetHelpline = 'Vet Helpline',
}

export const PolicyBenefitCodeByValueConfig: Record<PolicyBenefits, ProductBenefitCode> = {
  [PolicyBenefits.VetFees]: 'VET_FEES',
  [PolicyBenefits.Excess]: 'EXCESS',
  [PolicyBenefits.DeathFromAccident]: 'ACCIDENT_DEATH',
  [PolicyBenefits.ThirdPartyLiabilityDogsOnly]: 'THIRD_PARTY_LIABILITY',
  [PolicyBenefits.DeathFromIllness]: 'ILLNESS_DEATH',
  [PolicyBenefits.BoardingFees]: 'BOARDING',
  [PolicyBenefits.TheftOrStraying]: 'THEFT',
  [PolicyBenefits.HolidayCancellation]: 'HOLIDAY_CANCELLATION',
  [PolicyBenefits.AdvertisingAndReward]: 'ADVERTISING',
  [PolicyBenefits.LossOfPetPassport]: 'PET_PASSPORT',
  [PolicyBenefits.OverseasTravel]: 'OVERSEAS_TRAVEL',
  [PolicyBenefits.Quarantine]: 'QUARANTINE',
  [PolicyBenefits.EmergencyExpensesCoverAbroad]: 'EMERGENCY_ABROAD',
  [PolicyBenefits.AccidentalDamage]: 'ACCIDENTAL_DAMAGE',
  [PolicyBenefits.VetHelpline]: 'VET_HELPLINE',
};

// export enum AppBenefits {
//   FreeDogTraining = 'FreeDogTraining',
//   ChatsWithVets = '24/7 Chats With Vets',
//   ChatsWithTrainers = '24/7 Chats With Trainers',
//   PuppyGrowthPlans = 'Puppy Growth Plans',
//   FoodDiscount = 'Up To 50% Off On Pet Food & Treats',
//   ToysDiscount = 'Up To 50% Off On Pet Toys & Cloth',
// }

export enum FluffyAppBenefits {
  FreeDogTraining = 'Free dog training courses',
  VetAndBehaviouristSupport = 'Free 24/7 vet & behaviourist support',
  FoodDiscount = 'Up to 50% discount on food',
  AccessToExclusiveCommunity = 'Access to exclusive community',
  FreePersonalizedPuppyGrowthPlans = 'Free personalised puppy growth plans',
  DiscountOnPetServices = 'Up to 50% discount on pet services',
}

export function isPolicyBenefit(policyBenefitCandidate: string): policyBenefitCandidate is PolicyBenefits {
  return policyBenefitCandidate in PolicyBenefits;
}
