import React from 'react';
import { useField } from 'formik';

import { Stack } from '@chakra-ui/react';
import { InputProps } from '@chakra-ui/input/dist/input';

import { FormikInput } from '~/components/form/formik-input/FormikInput';
import { TitleText } from '~/components/common/TitleText';
import { ActionButton } from '~/components/common/ActionButton';

interface TextInputQuestionProps extends InputProps {
  name: string; // is used to bind this question with Formik
  questionText: string; // label for question input
  placeholder?: string;
  actionButtonLabel: string;
  type?: InputProps['type'];
  onActionButtonClick: () => void;
  inputFormatter?: (input: string) => string;
}

export const TextInputQuestion: React.FC<TextInputQuestionProps> = function TextInputQuestion({
  name,
  questionText,
  placeholder,
  actionButtonLabel,
  type = 'text',
  onActionButtonClick,
  inputFormatter,
  ...inputProps
}) {
  const [, meta] = useField(name);

  // todo [INSR-67]: Refactor Text input components
  return (
    <Stack spacing={{ base: '80px', lg: '172px' }} align="center">
      <Stack spacing={{ base: '80px', lg: '172px' }} align="center">
        <TitleText text={questionText} />
        <FormikInput
          name={name}
          placeholder={placeholder}
          type={type}
          variant="flushed"
          size="md"
          {...inputProps}
          inputFormatter={inputFormatter}
          keyEnterHandler={onActionButtonClick}
        />
      </Stack>

      <ActionButton
        label={actionButtonLabel}
        onPress={onActionButtonClick}
        disabled={Boolean(!meta.value || meta.error)}
      />
    </Stack>
  );
};
