import React from 'react';
import { Image, Text, Progress, Box, Stack } from '@chakra-ui/react';

import { TitleText } from '~/components/common/TitleText';

import { Colors } from '~/constants/colors';

const IMG_URL = '/images/dogImages/03-goal-achieving-dog/03-goal-achieving-dog-1080.png';

export const PaymentPageLoaderView: React.FC = function PaymentPageLoaderView() {
  return (
    <Stack spacing="32px" align="center" justify="center">
      <Box>
        <TitleText text="Redirecting to the payment page" />
      </Box>
      <Image src={IMG_URL} boxSize="400px" fit="contain" />
      <Progress
        isIndeterminate
        borderRadius="10px"
        height="10px"
        width={{ base: '90%', lg: '600px' }}
        colorScheme={Colors.Brown}
        bg="#EBE0DB"
      />
      <Text
        paddingBottom="20px"
        fontSize="16px"
        lineHeight="20px"
        color={Colors.Brown}
        fontWeight="700"
        maxWidth="700px"
      >
        The payment page is operated by Cover-More Blue Insurance Services Limited and you are subject to the terms and
        conditions of that site.
      </Text>
    </Stack>
  );
};
