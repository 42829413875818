import React from 'react';
import { useLocation } from 'react-router-dom';
import { useFormikContext } from 'formik';
import { Box } from '@chakra-ui/react';

import { Header } from '~/components/common/Header';
import { Stepper } from '~/components/stepper/Stepper';
import { Step } from '~/components/stepper/Step';
import { PolicyDisplayPlansView } from '~/components/insurance-policy-display-form/policy-display-plans-view/PolicyDisplayPlansView';
import { PolicySummaryView } from '~/components/insurance-policy-display-form/policy-summary-view/PolicySummaryView';
import { PolicyStatementsConfirmationView } from '~/components/insurance-policy-display-form/policy-statements-confirmation-view/PolicyStatementsConfirmationView';
import { PaymentPageLoaderView } from '~/components/insurance-policy-display-form/payment-page-loader-view/PaymentPageLoaderView';
import { BinaryQuestion } from '~/components/questions/BinaryQuestion';
import { AddressQuestion } from '~/components/questions/AddressQuestion';
import { InsuranceOwnerForm } from '~/components/insurance-policy-display-form/insurance-owner-form/InsuranceOwnerForm';
import { DateInputQuestion } from '~/components/questions/DateInputQuestion';

import {
  IS_MICROCHIPPED_FIELD,
  DATE_OF_OWNER_BIRTH_FIELD,
  InsurancePolicyFormValues,
  PolicyDisplayFormQuestionType,
} from '~/components/insurance-policy-display-form/insurance-policy-display-questionnaire.schema';

import { useInsuranceDataContext } from '~/hooks/insurance-data-provider/useInsuranceDataContext';
import { useFilledInsuranceFormValuesFormattedPetName } from '~/hooks/insurance-data-provider/useFilledInsuranceFormValuesFormattedPetName';
import { useInvokeErrorModal } from '~/hooks/useInvokeErrorModal';
import { NOT_MICROCHIPPED_ERROR_MESSAGE } from '~/constants/errorMessage';
import { useFilledInsuranceFormValuesField } from '~/hooks/insurance-data-provider/useFilledInsuranceFormValuesField';

import {
  getBinaryQuestionInsuranceQuestionnaireFormTraits,
  getCurrentInsurancePolicyQuestionnaireFormQuestionTraits,
} from '~/helpers/analytics/userAnalyticsIdentity';
import { debounce } from '~/helpers/common';
import { trackEventAndIdentify } from '~/helpers/analytics/trackEvent';

import { ADDRESS_LINE_1_FIELD } from '~/components/insurance-policy-display-form/insurance-policy-display-questionnaire.schema';
import { ADDRESS_LINE_2_FIELD } from '~/components/insurance-policy-display-form/insurance-policy-display-questionnaire.schema';
import { CAT_OR_DOG_FIELD, EMAIL_FIELD } from '~/components/insurance-questionnaire/insurance-questionnaire.schema';
import { ADDRESS_LINE_3_FIELD } from '~/components/insurance-policy-display-form/insurance-policy-display-questionnaire.schema';
import { HAS_ALREADY_INSURANCE_POLICY } from '~/components/insurance-policy-display-form/insurance-policy-display-questionnaire.schema';

import {
  POLICY_DISPLAY_FORM_IDENTITY_FIELDS_CONFIG,
  POLICY_DISPLAY_FORM_TRACKING_EVENTS_CONFIG,
} from '~/configs/tracking';

import { AnalyticsWithoutParamsSchema } from '~/models/analyticsParams';

export interface PolicyViewStepProps {
  onValueSelect: () => void;
}

const FIRST_STEP_IDX = 0;
const LAST_STEP_IDX_BEFORE_LOADING = 7;
const DEBOUNCE_TIMEOUT = 300;

const POPOVER_CONTENT_CONFIG: Record<number, string> = {
  0: 'Lifetime Vet Fee cover is for illnesses and injuries. Subject to the underwriter offering renewal and premiums being paid on time with no break in cover, illnesses and injuries will continue to be covered. Pre-existing conditions are excluded from cover. Terms and conditions apply.',
  2: "Since 6 April 2016, all dogs in England, Scotland and Wales must be microchipped. As of that date, owners of dogs and puppies over the age of eight weeks must also have registered their pet's microchip details on one of the authorised databases.",
};

export const InsurancePolicyDisplayQuestionnaire: React.FC = function InsurancePolicyDisplayQuestionnaire() {
  const formik = useFormikContext<InsurancePolicyFormValues>();

  const location = useLocation();
  const isPolicyFormFulfilled = Boolean(location.state && location.state.isPolicyFormFulfilled);

  const [currentStep, setCurrentStep] = React.useState<number>(
    isPolicyFormFulfilled ? LAST_STEP_IDX_BEFORE_LOADING : FIRST_STEP_IDX,
  );
  const [isBackButtonVisible, setIsBackButtonVisible] = React.useState<boolean>(true);

  const petName = useFilledInsuranceFormValuesFormattedPetName();
  const petType = useFilledInsuranceFormValuesField(CAT_OR_DOG_FIELD);
  const email = String(useFilledInsuranceFormValuesField(EMAIL_FIELD));
  const { invokeErrorModal } = useInvokeErrorModal();

  React.useEffect(() => {
    // start the next question from the top of the page
    window.scrollTo(0, 0);
  }, [currentStep]);

  const trackAnalytics = (currentQuestionType: PolicyDisplayFormQuestionType, traits?: object) => {
    trackEventAndIdentify(
      {
        eventName: POLICY_DISPLAY_FORM_TRACKING_EVENTS_CONFIG[currentQuestionType],
      } as AnalyticsWithoutParamsSchema,
      traits,
      email,
    );
  };

  const openNextStep = React.useCallback(() => {
    setCurrentStep((prevStep) => prevStep + 1);
  }, []);

  const openNextQuestion =
    ({ currentQuestionType }: { currentQuestionType: PolicyDisplayFormQuestionType }) =>
    () => {
      const traits = {
        ...getCurrentInsurancePolicyQuestionnaireFormQuestionTraits(
          POLICY_DISPLAY_FORM_IDENTITY_FIELDS_CONFIG[currentQuestionType],
          formik,
        ),
      };

      trackAnalytics(currentQuestionType, traits);

      openNextStep();
    };

  const openPaymentPage = React.useCallback(() => {
    // TODO: refactor neatly to include the timeout logic here
    formik.submitForm();
    setIsBackButtonVisible(false);
    openNextStep();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openNextStep, formik.submitForm]);

  const binaryQuestionOpenNextStep = (
    { currentQuestionType }: { currentQuestionType: PolicyDisplayFormQuestionType },
    value?: string | boolean,
  ) => {
    const fields = POLICY_DISPLAY_FORM_IDENTITY_FIELDS_CONFIG[currentQuestionType];
    const traits =
      !Array.isArray(fields) && value !== undefined
        ? getBinaryQuestionInsuranceQuestionnaireFormTraits(fields, value)
        : undefined;

    trackAnalytics(currentQuestionType, traits);

    debounce(openNextStep, DEBOUNCE_TIMEOUT)();
  };

  const openPreviousStep = React.useCallback(() => setCurrentStep((prevStep) => prevStep - 1), []);

  const { cleanPolicyList } = useInsuranceDataContext();
  const onBackClick = React.useCallback(() => {
    if (currentStep === FIRST_STEP_IDX) {
      // clean policy list to show the Insurance Form again
      cleanPolicyList();
    } else {
      openPreviousStep();
    }
  }, [currentStep, cleanPolicyList, openPreviousStep]);

  return (
    <Box>
      <Header
        popoverTextContent={POPOVER_CONTENT_CONFIG[currentStep]}
        onBackButtonPress={onBackClick}
        isBackButtonVisible={isBackButtonVisible}
      />
      <Box marginX="auto" alignSelf="center" marginTop={{ base: '40px', lg: '65px' }}>
        <Stepper step={currentStep}>
          <Step>
            <PolicyDisplayPlansView onValueSelect={openNextStep} />
          </Step>
          <Step>
            <BinaryQuestion<boolean>
              name={HAS_ALREADY_INSURANCE_POLICY}
              questionText="Do you already have an insurance policy?"
              options={[
                { label: 'Yes', value: true },
                { label: 'No', value: false },
              ]}
              onSelect={() => binaryQuestionOpenNextStep({ currentQuestionType: 'HAS_ALREADY_A_POLICY' })}
            />
          </Step>
          <Step>
            <BinaryQuestion<boolean>
              name={IS_MICROCHIPPED_FIELD}
              questionText={`Is ${petName} microchipped?`}
              options={[
                { label: 'Yes', value: true },
                { label: 'No', value: false },
              ]}
              onSelect={(isMicrochipped) => {
                if (!isMicrochipped && petType === 'dog') {
                  invokeErrorModal(new Error(NOT_MICROCHIPPED_ERROR_MESSAGE));
                } else {
                  binaryQuestionOpenNextStep({ currentQuestionType: 'IS_PET_MICROCHIPPED' });
                }
              }}
            />
          </Step>

          <Step>
            <AddressQuestion<InsurancePolicyFormValues>
              addressLineParams={[
                { formikName: ADDRESS_LINE_1_FIELD, placeholder: 'Address Line 1' },
                { formikName: ADDRESS_LINE_2_FIELD, placeholder: 'Address Line 2' },
                { formikName: ADDRESS_LINE_3_FIELD, placeholder: 'Address Line 3' },
              ]}
              questionText="Provide your address"
              actionButtonLabel="Continue"
              onActionButtonClick={openNextQuestion({ currentQuestionType: 'OWNER_ADDRESS' })}
            />
          </Step>

          <Step>
            <DateInputQuestion
              className="default-date-input"
              name={DATE_OF_OWNER_BIRTH_FIELD}
              questionText="Please confirm your date of birth"
              actionButtonLabel="Complete"
              onActionButtonClick={openNextQuestion({ currentQuestionType: 'DATE_OF_OWNER_BIRTH' })}
            />
          </Step>
          <Step>
            <InsuranceOwnerForm openNextStep={openNextQuestion({ currentQuestionType: 'OWNER_INFO' })} />
          </Step>
          <Step>
            <PolicySummaryView
              openNextStep={openNextQuestion({ currentQuestionType: 'POLICY_SUMMARY_CONFIRMATION' })}
            />
          </Step>
          <Step>
            <PolicyStatementsConfirmationView onSubmit={openPaymentPage} />
          </Step>
          <Step>
            <PaymentPageLoaderView />
          </Step>
        </Stepper>
      </Box>
    </Box>
  );
};
