import React from 'react';

import * as Sentry from '@sentry/react';

import { ProductQuoteSchema, QuoteSchema } from '@shared/models/product';

import {
  FORM_INITIAL_VALUE,
  InsuranceFormValues,
} from '~/components/insurance-questionnaire/insurance-questionnaire.schema';
import {
  ARE_POLICY_AND_BUSINESS_DOCUMENTS_READ_FIELD,
  ARE_TERMS_OF_COVER_CONFIRMED_FIELD,
  HAS_ALREADY_INSURANCE_POLICY,
  INSURANCE_POLICY_FORM_INITIAL_VALUE,
  InsurancePolicyFormValues,
  IS_POLICY_SUMMARY_CONFIRMED_FIELD,
  POLICY_CODE_FIELD,
} from '~/components/insurance-policy-display-form/insurance-policy-display-questionnaire.schema';

import {
  cleanUnsupportedPolicies,
  normalizePoliciesOrderByVetFeeAmount,
} from '~/helpers/insuranceCoveragePolicies/insuranceCoveragePolicies';
import { createPartialQuoteDtosFromFormValues, createQuoteDtoFromFormsValues } from '~/helpers/dto';
import { captureAndLogException } from '~/helpers/monitoring/captureAndLogException';
import { identify } from '~/helpers/analytics/identify';
import { buildQuotingIdentityFieldName } from '~/helpers/analytics/userAnalyticsIdentity';

import { useInvokeErrorModal } from '~/hooks/useInvokeErrorModal';
import { useUiStateUpdateIsLoading } from '~/hooks/useUiStateUpdateIsLoading';
import { useExternalQuoteRedirect } from '~/hooks/quote/useExternalQuoteRedirect';

import {
  invokeFetchQuoteDataApi,
  invokeFetchQuoteWithPartialRequestDataApi,
  navigateToQuotePaymentPage,
} from '~/helpers/quote/quote';
import { savePolicyFormValuesLocally } from '~/helpers/quote/locallyStoredPolicyFormValues';

import { LocalStorageKey } from '~/constants/localStorageKey';

export interface InsuranceDataContextSchema {
  quoteId?: string;
  policyList?: ProductQuoteSchema[];
  filledInsuranceFormValues: InsuranceFormValues;
  filledInsurancePolicyFormValues: InsurancePolicyFormValues;
  petDataFormSubmitHandler: (petFormData: InsuranceFormValues) => void;
  policyFormSubmitHandler: (policyFormData: InsurancePolicyFormValues) => Promise<void>;
  cleanPolicyList: () => void;
}

export const InsuranceDataContext = React.createContext<InsuranceDataContextSchema | undefined>(undefined);

export const InsuranceDataProvider: React.FC<React.PropsWithChildren> = function InsuranceDataProvider({ children }) {
  const [policyList, setPolicyList] = React.useState<ProductQuoteSchema[] | undefined>(undefined);
  const [quoteId, setQuoteId] = React.useState<string | undefined>(undefined);

  const [filledInsuranceFormValues, setFilledInsuranceFormValues] =
    React.useState<InsuranceFormValues>(FORM_INITIAL_VALUE);
  const [filledInsurancePolicyFormValues, setFilledInsurancePolicyFormValues] =
    React.useState<InsurancePolicyFormValues>(INSURANCE_POLICY_FORM_INITIAL_VALUE);

  const updateUiIsLoading = useUiStateUpdateIsLoading();

  const { invokeErrorModal } = useInvokeErrorModal();

  const processQuotesList = React.useCallback((quotesList: ProductQuoteSchema[]) => {
    const policyListData = cleanUnsupportedPolicies(quotesList);

    Sentry.addBreadcrumb({
      category: 'log',
      message: 'cleanUnsupportedPolicies successful',
      data: policyListData,
      level: 'info',
    });

    const sortedPolicyList = normalizePoliciesOrderByVetFeeAmount(policyListData);

    Sentry.addBreadcrumb({
      category: 'log',
      message: 'normalizePoliciesOrderByVetFeeAmount successful',
      data: sortedPolicyList,
      level: 'info',
    });

    setPolicyList(sortedPolicyList);

    Sentry.addBreadcrumb({
      category: 'log',
      message: 'setPolicyList successful',
      level: 'info',
    });

    return sortedPolicyList;
  }, []);

  const processQuoteData = React.useCallback(
    (quoteData: unknown): ProductQuoteSchema[] => {
      if (quoteData) {
        const quoteDataTyped = quoteData as QuoteSchema;
        localStorage.setItem(LocalStorageKey.SavedQuoteId, quoteDataTyped.quoteId);

        setQuoteId(quoteDataTyped.quoteId);

        return processQuotesList(quoteDataTyped.petQuotes[0].productQuotes);
      } else {
        throw { name: 'Error', message: '[fetchQuoteData]: API call returned no policies' };
      }
    },
    [processQuotesList],
  );

  useExternalQuoteRedirect({
    setFilledInsuranceFormValues,
    setQuoteId,
    setFilledInsurancePolicyFormValues,
    processQuotesList,
  });

  const petDataFormSubmitHandler = React.useCallback(
    async (insuranceFormData: InsuranceFormValues) => {
      updateUiIsLoading(true);
      setFilledInsuranceFormValues(insuranceFormData);

      const dtosCreationResult = createPartialQuoteDtosFromFormValues(insuranceFormData);

      if (dtosCreationResult) {
        await invokeFetchQuoteWithPartialRequestDataApi(dtosCreationResult.customerDto, dtosCreationResult.petPolicyDto)
          .catch((e) => {
            invokeErrorModal(e);

            captureAndLogException('[fetchQuoteData]: issue with fetching a quote. ' + e.message, 'Error');
          })
          .then(processQuoteData)
          .then((productQuotes) => addProductPricesToIdentity(productQuotes, dtosCreationResult.customerDto.email))
          .catch((e) => {
            invokeErrorModal(e);

            captureAndLogException('[fetchQuoteData]: issue with processing quote data. ' + e.message, 'Error');
          });
      }

      updateUiIsLoading(false);
    },
    [invokeErrorModal, processQuoteData, updateUiIsLoading],
  );

  const policyFormSubmitHandler = React.useCallback(
    async (policyFormData: InsurancePolicyFormValues) => {
      savePolicyFormValuesLocally({
        [HAS_ALREADY_INSURANCE_POLICY]: policyFormData[HAS_ALREADY_INSURANCE_POLICY],
        [POLICY_CODE_FIELD]: policyFormData[POLICY_CODE_FIELD],
        [IS_POLICY_SUMMARY_CONFIRMED_FIELD]: policyFormData[IS_POLICY_SUMMARY_CONFIRMED_FIELD],
        [ARE_TERMS_OF_COVER_CONFIRMED_FIELD]: policyFormData[ARE_TERMS_OF_COVER_CONFIRMED_FIELD],
        [ARE_POLICY_AND_BUSINESS_DOCUMENTS_READ_FIELD]: policyFormData[ARE_POLICY_AND_BUSINESS_DOCUMENTS_READ_FIELD],
      });

      const dtoCreationResult = createQuoteDtoFromFormsValues(filledInsuranceFormValues, policyFormData);

      if (dtoCreationResult && quoteId) {
        await invokeFetchQuoteDataApi(dtoCreationResult.customerDto, dtoCreationResult.petPolicyDto, quoteId)
          .catch((e) => {
            invokeErrorModal(e);

            captureAndLogException('[policyFormSubmitHandler]: issue with fetching a quote. ' + e.message, 'Error');
          })
          .then((quoteData: unknown) => {
            const typedQuote = quoteData as QuoteSchema;

            navigateToQuotePaymentPage(policyFormData[POLICY_CODE_FIELD], typedQuote);
          })
          .catch((e) => {
            invokeErrorModal(e);

            captureAndLogException(
              '[policyFormSubmitHandler]: issue with processing quote data. ' + e.message,
              'Error',
            );
          });
      } else {
        captureAndLogException(
          '[policyFormSubmitHandler]: issue with processing quote data: dtoCreationResult or previosly fetched quoteId is not defined',
          'Error',
        );
      }
    },
    [filledInsuranceFormValues, invokeErrorModal, quoteId],
  );

  async function addProductPricesToIdentity(productQuotes: ProductQuoteSchema[], email: string) {
    await Promise.all(
      productQuotes.map(async (policy: ProductQuoteSchema) => {
        const monthlyIdentityFieldName = buildQuotingIdentityFieldName(policy.productCode + '_monthly_price');
        const annualIdentityFieldName = buildQuotingIdentityFieldName(policy.productCode + '_annual_price');
        await identify(email, {
          [monthlyIdentityFieldName]: policy.monthlyPrice,
          [annualIdentityFieldName]: policy.annualPrice,
        });
      }),
    );
  }

  return (
    <InsuranceDataContext.Provider
      value={{
        policyList,
        filledInsuranceFormValues,
        filledInsurancePolicyFormValues,
        quoteId,
        cleanPolicyList: () => setPolicyList(undefined),
        petDataFormSubmitHandler,
        policyFormSubmitHandler,
      }}
    >
      {children}
    </InsuranceDataContext.Provider>
  );
};
