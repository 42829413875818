import React from 'react';
import { Box, Text } from '@chakra-ui/react';

import { PolicySummaryContainerWithTitle } from '~/components/insurance-policy-display-form/policy-summary-view/policy-summary-container-with-title/PolicySummaryContainerWithTitle';

import { Colors } from '~/constants/colors';

interface PolicySummaryContainerWithTitleDescriptionProps {
  title: string;
  description: string;
}

export const PolicySummaryContainerWithTitleDescription: React.FC<PolicySummaryContainerWithTitleDescriptionProps> =
  function PolicySummaryContainerWIthTitleDescription({ title, description }) {
    return (
      <PolicySummaryContainerWithTitle style={{ width: { base: '336px', lg: '500px' } }} title={title}>
        <Box marginTop="8px">
          <Text textAlign="left" fontSize="12px" lineHeight="20px" color={Colors.Black} fontWeight="400">
            {description}
          </Text>
        </Box>
      </PolicySummaryContainerWithTitle>
    );
  };
