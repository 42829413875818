import React from 'react';

import { BreedSchema } from '@joinfluffy/common';

import { BaseModal } from '~/components/common/BaseModal';

import { openUrlInNewTab } from '~/helpers/common';
import { trackEvent } from '~/helpers/analytics/trackEvent';

import { AnalyticsEvent } from '~/constants/analyticsEvent';
import { ModalTypes } from '~/constants/modalTypes';

const IMAGE_PATH = '/images/dogImages/30-chill-dog/30-chill-dog-800.png';

interface NotSupportedBreedModalProps {
  isOpen: boolean;
  onClose: () => void;
  breed: BreedSchema | { type: BreedSchema['type'] };
}

export function isFullBreedSchema(breed: BreedSchema | { type: BreedSchema['type'] }): breed is BreedSchema {
  return 'breedName' in breed;
}

function openJoinBreedWaitlistTypeform() {
  trackEvent({ eventName: AnalyticsEvent.QuoteOpenJoinBreedWaitListLink });
  openUrlInNewTab('https://quiz.typeform.com/to/mKjl195N');
}

const getPetTypeLabel = (petType: BreedSchema['type']) =>
  ({
    cat: 'cats',
    dog: 'dogs',
  }[petType]);

export const NotSupportedBreedModal: React.FC<NotSupportedBreedModalProps> = function NotSupportedBreedModal({
  isOpen,
  onClose,
  breed,
}) {
  return (
    <BaseModal
      type={ModalTypes.NotSupportedBreed}
      isOpen={isOpen}
      imgSrc={IMAGE_PATH}
      title={"Looks like Fluffy doesn't insure your breed at the moment"}
      description={
        isFullBreedSchema(breed)
          ? `Thanks for requesting the quote! Please join the waitlist to be the first one to hear when we start insuring ${
              breed.breedName
            } ${getPetTypeLabel(breed.type)}`
          : 'Please let us know which breed we should support next by clicking the button below'
      }
      onClose={onClose}
      actionButtonLabel="Request coverage"
      onActionButtonPress={openJoinBreedWaitlistTypeform}
      secondaryActionButtonLabel="Close"
      onSecondaryActionButtonPress={onClose}
    />
  );
};
