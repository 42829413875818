import React from 'react';
import { useField } from 'formik';

import { Box, Text } from '@chakra-ui/react';

import { Colors } from '~/constants/colors';

interface FormikInputErrorTextProps {
  fieldName: string;
}

export const FormikInputErrorText: React.FC<FormikInputErrorTextProps> = function FormikInputErrorText({ fieldName }) {
  const [, meta] = useField(fieldName);

  if (!meta.touched || !meta.error) {
    return null;
  }

  return (
    <Box height={{ base: '24px', lg: '36px' }} paddingTop={{ base: '8px' }}>
      <Text textAlign="left" fontSize="13px" lineHeight="16px" color={Colors.Red}>
        {meta.error}
      </Text>
    </Box>
  );
};
