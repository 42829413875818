import * as Sentry from '@sentry/react';

type LogLevel = 'Error' | 'Warn';

export function captureAndLogException(message: string, logLevel: LogLevel) {
  Sentry.captureException(message);

  switch (logLevel) {
    case 'Error':
      console.error(message);
      break;
    case 'Warn':
      console.warn(message);
      break;
  }
}
