import React from 'react';
import { useField } from 'formik';

import { ChooseOptionItem } from '~/components/questions/ChooseOptionItem';

interface ChooseOptionItemProps {
  name: string;
  value: string;
  label: string;
  onSelect?: () => void;
}

export const ChooseOptionFormikItem: React.FC<ChooseOptionItemProps> = function ChooseOptionFormikItem({
  name,
  value,
  label,
  onSelect,
}) {
  // second parameter (meta) is not used now
  const [formikField, , formikHelper] = useField(name);

  const isSelected = React.useMemo(() => formikField.value === value, [formikField.value, value]);
  const selectItem = React.useCallback(async () => {
    formikHelper.setTouched(true);
    formikHelper.setValue(value);

    await Promise.resolve();

    onSelect && onSelect();
  }, [formikHelper, value, onSelect]);

  return <ChooseOptionItem label={label} isSelected={isSelected} onSelect={selectItem} />;
};
