import React from 'react';

import { defaultFeaturesConfig, FeaturesConfigSchema } from '@shared/models/featureFlags';

import { OverlayLoader } from '~/components/common/OverlayLoader';

import { captureAndLogException } from '~/helpers/monitoring/captureAndLogException';

import { getHttpsCallableFunction } from '~/hooks/useInitFirebase';

import { CloudFunction } from '~/constants/functionName';

export const FeatureFlagsContext = React.createContext<FeaturesConfigSchema | undefined>(undefined);

const TIMEOUT_3_MIN_IN_MS = 180000;

export const FeatureFlagsProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [isFeatureConfigInit, setIsFeatureConfigInit] = React.useState<boolean>(false);
  const [featureFlags, setFeatureFlags] = React.useState<FeaturesConfigSchema>(defaultFeaturesConfig);

  React.useEffect(() => {
    getHttpsCallableFunction(CloudFunction.fetchFeatureFlagsConfig, { timeout: TIMEOUT_3_MIN_IN_MS })()
      .then((result) => result.data as FeaturesConfigSchema)
      .then((featureFlagResult) => {
        setFeatureFlags(featureFlagResult);
        setIsFeatureConfigInit(true);
      })
      .catch((error) => {
        captureAndLogException(`[Error during fetching Feature flags]: ${JSON.stringify(error)}`, 'Error');
      });
  }, []);

  if (!isFeatureConfigInit) {
    return <OverlayLoader />;
  }

  return <FeatureFlagsContext.Provider value={featureFlags}>{children}</FeatureFlagsContext.Provider>;
};
