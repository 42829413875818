import React from 'react';

import * as Sentry from '@sentry/react';

import { Box, Flex } from '@chakra-ui/react';

import { InsuranceQuestionnaire } from '~/components/insurance-questionnaire/InsuranceQuestionnaire';
import { InsurancePolicyDisplayForm } from '~/components/insurance-policy-display-form/InsurancePolicyDisplayForm';
import { Footer } from '~/components/common/Footer';
import { Modal } from '~/components/modal/Modal';

import { useCheckForExistingQuote } from '~/hooks/quote/useCheckForExistingQuote';
import { useInsurancePolicyList } from '~/hooks/insurance-data-provider/policy/useInsurancePolicyList';
import { useUiStateContextUiSchema } from '~/hooks/useUiStateContextUiSchema';
import { useInvokeErrorModal } from '~/hooks/useInvokeErrorModal';

export const AppWrapper: React.FC = function AppWrapper() {
  useCheckForExistingQuote();

  const policyList = useInsurancePolicyList();
  const uiSchema = useUiStateContextUiSchema();

  const { invokeErrorModal } = useInvokeErrorModal();

  const onSentryError = React.useCallback(
    (error: Error) => {
      invokeErrorModal(error);
    },
    [invokeErrorModal],
  );

  if (uiSchema?.isQuoteDataForExternalRouteLoading) {
    return null;
  }

  return (
    <>
      <Modal />

      <Sentry.ErrorBoundary onError={onSentryError}>
        <Flex
          direction="column"
          justify="space-between"
          width={{ base: '375px', lg: '1100px' }}
          minHeight="100vh"
          padding={{ base: '16px' }}
          marginX="auto"
          alignSelf="center"
        >
          <Box>{policyList ? <InsurancePolicyDisplayForm /> : <InsuranceQuestionnaire />}</Box>

          {!uiSchema?.isFooterHidden && (
            <Box marginTop="16px">
              <Footer />
            </Box>
          )}
        </Flex>
      </Sentry.ErrorBoundary>
    </>
  );
};
