import React from 'react';

import { Stack, StackProps } from '@chakra-ui/react';

import { ChooseOptionFormikItem } from '~/components/questions/ChooseOptionFormikItem';
import { FormikInputErrorText } from '~/components/form/formik-input-error-text/FormikInputErrorText';
import { ChooseItemOption } from '~/components/questions/questions.schema';

interface ChooseOptionFormikItemsListProps<OptionValueType extends string> {
  name: string; // is used to bind this question with Formik
  options: ChooseItemOption<OptionValueType>[];
  onSelect: () => void;
  style?: StackProps;
}

export function ChooseOptionFormikItemsList<OptionValueType extends string>({
  name,
  options,
  onSelect,
  style,
}: ChooseOptionFormikItemsListProps<OptionValueType>) {
  return (
    <Stack spacing="12px" justifyContent="space-between" minW={240} {...style}>
      {options.map(({ value, label }) => (
        <ChooseOptionFormikItem key={value} name={name} value={value} label={label} onSelect={onSelect} />
      ))}

      <FormikInputErrorText fieldName={name} />
    </Stack>
  );
}
