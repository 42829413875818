import React from 'react';

import * as Sentry from '@sentry/react';

import { BrowserTracing } from '@sentry/tracing';

const BROWSER_TRACING = new BrowserTracing();
const DSN = 'https://2a5bd861f0c24096b37573b29cd67d65@o1361432.ingest.sentry.io/4504672202981376';

export function useInitMonitoring() {
  const [initFinished, setInitFinished] = React.useState<boolean>(false);

  React.useEffect(() => {
    Sentry.init({
      dsn: DSN,
      integrations: [BROWSER_TRACING],
      tracesSampleRate: Number(process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE),
      environment: process.env.REACT_APP_ENVIRONMENT,
    });

    setInitFinished(true);
  }, []);

  return { initFinished };
}
