import React from 'react';
import { Field, useField } from 'formik';

import { Input, Stack } from '@chakra-ui/react';
import { InputProps } from '@chakra-ui/input/dist/input';

import { FormikInputErrorText } from '~/components/form/formik-input-error-text/FormikInputErrorText';

import { useInputStyle } from '~/components/form/formik.style';

import { useKeyEnterFormikHandler } from '~/hooks/formik-common/useKeyEnterFormikHandler';

interface FormikInputProps extends InputProps {
  name: string; // re-declare here to make it required
  inputFormatter?: (input: string) => string;
  keyEnterHandler?: () => void;
}

export const FormikInput: React.FC<FormikInputProps> = function FormikInput({
  name,
  inputFormatter,
  keyEnterHandler,
  ...inputProps
}) {
  // second parameter (meta) is not used now
  const [formikField, , formikHelper] = useField(name);

  const inputStyles = useInputStyle(inputProps.variant);

  const onChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      const valueToSave = inputFormatter ? inputFormatter(value) : value;

      formikHelper.setTouched(true);
      formikHelper.setValue(valueToSave);
    },
    [formikHelper, inputFormatter],
  );

  const inputKeyEnterHandler = useKeyEnterFormikHandler(name, keyEnterHandler);

  return (
    <Stack spacing={1}>
      <Field
        as={Input}
        id={name}
        {...inputProps}
        {...inputStyles}
        {...formikField}
        value={formikField.value}
        onChange={onChange}
        onKeyDown={keyEnterHandler ? inputKeyEnterHandler : undefined}
      />

      <FormikInputErrorText fieldName={name} />
    </Stack>
  );
};
