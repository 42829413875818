import React from 'react';
import { useFormikContext } from 'formik';

import { Stack } from '@chakra-ui/react';

import { FormikInput } from '~/components/form/formik-input/FormikInput';
import { ScreenWithTitleContainer } from '~/components/common/ScreenWithTitleContainer';

import { useKeyEnterScreenHandler } from '~/hooks/common/useKeyEnterScreenHandler';

interface AddressQuestionProps<FormikFormType> {
  addressLineParams: {
    formikName: keyof FormikFormType;
    placeholder?: string;
    inputFormatter?: (input: string) => string;
  }[];
  questionText: string; // label for question input
  actionButtonLabel: string;
  onActionButtonClick: () => void;
}

export function AddressQuestion<FormikFormType>({
  addressLineParams,
  questionText,
  actionButtonLabel,
  onActionButtonClick,
}: AddressQuestionProps<FormikFormType>) {
  const formik = useFormikContext<FormikFormType>();
  const hasErrorFormikField = addressLineParams.some(({ formikName }) => Boolean(formik.errors[formikName]));
  const hasTouchedFormikField = addressLineParams.some(({ formikName }) => Boolean(formik.touched[formikName]));
  const hasFilledFormikField = addressLineParams.some(({ formikName }) => Boolean(formik.values[formikName]));

  // don't use `React.useMemo` here, because we will update .errors / .touched / .values fields on every render
  const isActionDisabled = Boolean(hasErrorFormikField || !(hasTouchedFormikField || hasFilledFormikField));
  useKeyEnterScreenHandler({ actionToCall: onActionButtonClick, isActionDisabled });

  if (!addressLineParams.length) {
    return null;
  }

  return (
    <ScreenWithTitleContainer
      title={questionText}
      buttonLabel={actionButtonLabel}
      onActionButtonClick={onActionButtonClick}
      isButtonDisabled={isActionDisabled}
    >
      <Stack spacing="12px">
        {addressLineParams.map(({ formikName, placeholder, inputFormatter }, idx) => (
          <FormikInput
            key={idx}
            name={formikName as string}
            placeholder={placeholder}
            inputFormatter={inputFormatter}
            variant="outline"
            size="md"
          />
        ))}
      </Stack>
    </ScreenWithTitleContainer>
  );
}
