import { AnalyticsBrowser } from '@segment/analytics-next';

import { captureAndLogException } from '~/helpers/monitoring/captureAndLogException';

let analyticsBrowser: AnalyticsBrowser | null = null;

export function getAnalyticsBrowser() {
  if (!analyticsBrowser) {
    return;
  }

  return analyticsBrowser;
}

export function createAnalyticsBrowser() {
  if (!process.env.REACT_APP_SEGMENT_WRITE_KEY) {
    captureAndLogException('[createAnalyticsBrowser]: Segment write key is not defined', 'Error');

    throw Error('[createAnalyticsBrowser]: Segment write key is not defined');
  }

  if (analyticsBrowser) {
    analyticsBrowser = null;
  }

  analyticsBrowser = AnalyticsBrowser.load({ writeKey: process.env.REACT_APP_SEGMENT_WRITE_KEY });

  return analyticsBrowser;
}
