import React from 'react';

import { ErrorInfoModal } from '~/components/modal/error-info-modal/ErrorInfoModal';
import { PerkInfoModal } from '~/components/modal/perk-info-modal/PerkInfoModal';
import { NotSupportedBreedModal } from '~/components/modal/NotSupportedBreedModal';

import { ModalTypes } from '~/constants/modalTypes';

export const MODAL_CONFIG: Record<ModalTypes | '', React.ComponentType<any> | null> = {
  [ModalTypes.ErrorInfo]: ErrorInfoModal,
  [ModalTypes.PerkInfo]: PerkInfoModal,
  [ModalTypes.NotSupportedBreed]: NotSupportedBreedModal,
  '': null,
};
