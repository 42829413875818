export const LOGIN_FIELD = 'login';
export const PASSWORD_FIELD = 'password';

export interface AuthFormValues {
  [LOGIN_FIELD]: string;
  [PASSWORD_FIELD]: string;
}

export const AUTH_FORM_INITIAL_VALUE: AuthFormValues = {
  [LOGIN_FIELD]: '',
  [PASSWORD_FIELD]: '',
};
