import {
  DD_MM_YYYY_REGEXP,
  MM_YYYY_REGEXP,
  UK_PHONE_NUMBER_FORMATTED_REGEXP,
  YYYY_REGEXP,
} from '@shared/constants/regexp';
import { Currency } from '@shared/constants/currency';

const DATE_INPUT_FORMAT_MAX_CHAR_COUNT = 8;
const MISSED_DD_DATE_INPUT_FORMAT_MAX_CHAR_COUNT = 6;
const MISSED_DD_MM_DATE_INPUT_FORMAT_MAX_CHAR_COUNT = 4;
const UK_PHONE_NUMBER_CODE = '+44';

export function phoneNumberFormatter(input: string): string {
  if (UK_PHONE_NUMBER_FORMATTED_REGEXP.test(input)) {
    return input;
  }

  return addPrefixToInput(UK_PHONE_NUMBER_CODE, input)
    .replace(/(?!^\+)[^\d]+/g, '') // remove all symbols besides '+' and digits
    .replace(/(\+)(\++)(.*)/, '$1$3') // remove all extra '+' signs from the beginning
    .replace(/^(\+44)(.{0,4})(.{0,6})(.*)/, (_, code, group1, group2, extraGroup) =>
      [code, group1, group2, extraGroup].filter(Boolean).join(' '),
    );
}

export function numbersOnlyFormatter(input: string): string {
  return input.replace(/\D/g, '');
}

export function currencyFormatter(input: string) {
  return addPrefixToInput(Currency.GBP, input).replace(/(?!^£)[^\d]+/g, '');
}

export function dateInputFormatter(input: string): string {
  if (DD_MM_YYYY_REGEXP.test(input)) {
    return input;
  }

  const cleanInput = input.replace(/\D/g, '').slice(0, DATE_INPUT_FORMAT_MAX_CHAR_COUNT);

  return cleanInput.replace(/(.{2})(.{0,2})(.{0,4})/, (_, dd, mm, yyyy) => [dd, mm, yyyy].filter(Boolean).join('/'));
}

export function dateWithMissedDDInputFormatter(input: string): string {
  if (MM_YYYY_REGEXP.test(input)) {
    return input;
  }

  const cleanInput = input.replace(/\D/g, '').slice(0, MISSED_DD_DATE_INPUT_FORMAT_MAX_CHAR_COUNT);

  return cleanInput.replace(/(.{2})(.{0,4})/, (_, mm, yyyy) => [mm, yyyy].filter(Boolean).join('/'));
}

export function dateWithMissedDdMmInputFormatter(input: string): string {
  if (YYYY_REGEXP.test(input)) {
    return input;
  }

  return input.replace(/\D/g, '').slice(0, MISSED_DD_MM_DATE_INPUT_FORMAT_MAX_CHAR_COUNT);
}

export function uppercaseFormatter(input: string): string {
  return input.toUpperCase();
}

function addPrefixToInput(prefix: string, input: string) {
  if (input.length < prefix.length) {
    return prefix;
  }

  return `${prefix}${input.startsWith(prefix) ? input.substring(prefix.length) : input}`;
}
