import moment from 'moment';
import * as yup from 'yup';

import { titles } from '@shared/models/customer';
import { UK_PHONE_NUMBER_FORMATTED_REGEXP, DD_MM_YYYY_REGEXP } from '@shared/constants/regexp';
import {
  DEFAULT_MAX_STRING_LENGTH,
  PHONE_NUMBER_MAX_STRING_LENGTH,
  POLICY_OWNER_MAX_AGE_IN_YEARS,
  POLICY_OWNER_MIN_AGE_IN_YEARS,
} from '@shared/constants/validation';

import {
  ADDRESS_LINE_1_FIELD,
  ADDRESS_LINE_2_FIELD,
  ADDRESS_LINE_3_FIELD,
  DATE_OF_OWNER_BIRTH_FIELD,
  FIRST_NAME_FIELD,
  IS_MICROCHIPPED_FIELD,
  LAST_NAME_FIELD,
  PHONE_NUMBER_FIELD,
  TITLE_FIELD,
} from '~/components/insurance-policy-display-form/insurance-policy-display-questionnaire.schema';

import { getTodaysDate } from '~/helpers/date';

import { DATE_OF_BIRTH_FORMAT } from '~/constants/date';

export const insurancePolicyDisplayQuestionnaireValidationSchema = yup.object({
  [DATE_OF_OWNER_BIRTH_FIELD]: yup
    .string()
    .trim()
    .required('This question is required')
    .matches(DD_MM_YYYY_REGEXP, 'Date format should be: DD/MM/YYYY')
    .test('Check that string is a valid date', 'Please enter valid date of birth', (value?: string) => {
      const dateOfBirth = moment(value, DATE_OF_BIRTH_FORMAT);

      return dateOfBirth.isValid() && !dateOfBirth.isAfter(getTodaysDate());
    })
    .test('Check that user is not older then 200', 'Please enter valid date of birth', (value?: string) =>
      moment(value, DATE_OF_BIRTH_FORMAT).isAfter(getTodaysDate().subtract(POLICY_OWNER_MAX_AGE_IN_YEARS, 'years')),
    )
    .test('Check that user is older than 18', 'You need to be over 18 to purchase the policy', (value?: string) =>
      moment(value, DATE_OF_BIRTH_FORMAT).isSameOrBefore(
        getTodaysDate().subtract(POLICY_OWNER_MIN_AGE_IN_YEARS, 'years'),
      ),
    ),
  [IS_MICROCHIPPED_FIELD]: yup.bool().nullable().required('This question is required'),
  [ADDRESS_LINE_1_FIELD]: yup
    .string()
    .trim()
    .required(`Please provide the Address`)
    .max(DEFAULT_MAX_STRING_LENGTH, `Address Line 1 should not be longer than ${DEFAULT_MAX_STRING_LENGTH} symbols`),
  [ADDRESS_LINE_2_FIELD]: yup
    .string()
    .trim()
    .max(DEFAULT_MAX_STRING_LENGTH, `Address Line 2 should not be longer than ${DEFAULT_MAX_STRING_LENGTH} symbols`)
    .when(ADDRESS_LINE_1_FIELD, {
      is: (value?: string) => !value?.trim(), // if Address Line 1 is empty
      then: (schema) => schema.matches(/^$/, 'Start with Address Line 1'),
    }),
  [ADDRESS_LINE_3_FIELD]: yup
    .string()
    .trim()
    .max(DEFAULT_MAX_STRING_LENGTH, `Address Line 3 should not be longer than ${DEFAULT_MAX_STRING_LENGTH} symbols`)
    .when(ADDRESS_LINE_2_FIELD, {
      is: (value?: string) => !value?.trim(), // if Address Line 2 is empty
      then: (schema) => schema.matches(/^$/, 'Start with Address Line 2'),
    }),
  [TITLE_FIELD]: yup.string().trim().nullable().oneOf(titles).required('Please choose your title'),
  [FIRST_NAME_FIELD]: yup
    .string()
    .trim()
    .max(DEFAULT_MAX_STRING_LENGTH, `First name should not be longer than ${DEFAULT_MAX_STRING_LENGTH} symbols`)
    .required('Please provide your first name'),
  [LAST_NAME_FIELD]: yup
    .string()
    .trim()
    .max(DEFAULT_MAX_STRING_LENGTH, `Last name should not be longer than ${DEFAULT_MAX_STRING_LENGTH} symbols`)
    .required('Please provide your last name'),
  [PHONE_NUMBER_FIELD]: yup
    .string()
    .trim()
    .max(
      PHONE_NUMBER_MAX_STRING_LENGTH,
      `Phone number should not be longer than ${PHONE_NUMBER_MAX_STRING_LENGTH} symbols`,
    )
    .required('Please provide your phone number')
    .matches(UK_PHONE_NUMBER_FORMATTED_REGEXP, 'Phone number format: +44 #### ######'),
});
