import React from 'react';
import { VStack, Stack } from '@chakra-ui/react';
import { useField, useFormikContext } from 'formik';

import { PolicyCodes } from '@shared/constants/policyCodes';

import { PolicySummary } from '~/components/insurance-policy-display-form/policy-summary-view/policy-summary/PolicySummary';
import { ScreenWithTitleContainer } from '~/components/common/ScreenWithTitleContainer';
import { PolicyPricingSummary } from '~/components/insurance-policy-display-form/policy-summary-view/policy-pricing-summary/PolicyPricingSummary';
import {
  InsurancePolicyFormValues,
  IS_POLICY_SUMMARY_CONFIRMED_FIELD,
  POLICY_CODE_FIELD,
} from '~/components/insurance-policy-display-form/insurance-policy-display-questionnaire.schema';
import { PolicySummaryContainerWithTitleDescription } from '~/components/insurance-policy-display-form/policy-summary-view/policy-summary-container-with-title-description/PolicySummaryContainerWithTitleDescription';
import { ProductButtons } from '~/components/insurance-policy-display-form/product-buttons/ProductButtons';
import { FloatingContainer } from '~/components/common/FloatingContainer';
import { ActionButton } from '~/components/common/ActionButton';
import { Footer } from '~/components/common/Footer';

import { useHideFooter } from '~/hooks/common/useHideFooter';
import { useFilledInsuranceFormValues } from '~/hooks/insurance-data-provider/useFilledInsuranceFormValues';
import { useSelectedPolicyData } from '~/hooks/insurance-data-provider/policy/useSelectedPolicyData';
import { useIsInsuranceDataContextDogPetType } from '~/hooks/insurance-data-provider/useIsInsuranceDataContextDogPetType';

import {
  buildPolicyPricingSummary,
  buildPolicySummary,
} from '~/helpers/insuranceCoveragePolicies/insuranceCoveragePolicies';
import { trackEvent } from '~/helpers/analytics/trackEvent';

import { AnalyticsEvent } from '~/constants/analyticsEvent';

export interface PolicySummaryViewProps {
  openNextStep: () => void;
}

export const PolicySummaryView: React.FC<PolicySummaryViewProps> = function PolicySummaryView({ openNextStep }) {
  useHideFooter();

  const formik = useFormikContext<InsurancePolicyFormValues>();

  const [selectedPolicyCodeField] = useField<PolicyCodes>(POLICY_CODE_FIELD);
  const insuranceFormData = useFilledInsuranceFormValues();
  const selectedPlanData = useSelectedPolicyData(selectedPolicyCodeField.value);

  const policySummary = buildPolicySummary(insuranceFormData, selectedPlanData);
  const pricingSummary = buildPolicyPricingSummary(selectedPlanData);

  const isDogsSelectedPetType = useIsInsuranceDataContextDogPetType();

  const confirmPolicySummary = React.useCallback(() => {
    formik.setFieldValue(IS_POLICY_SUMMARY_CONFIRMED_FIELD, true);

    trackEvent({ eventName: AnalyticsEvent.QuotePolicySummaryConfirmed });

    openNextStep();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.setFieldValue, openNextStep]);

  return (
    <ScreenWithTitleContainer
      style={{ spacing: '32px', paddingBottom: '106px' }}
      title="Here’s a summary of your policy"
    >
      <VStack spacing="16px">
        <Stack spacing={{ base: '16px', lg: '10px' }} direction={{ base: 'column', lg: 'row' }}>
          {policySummary && <PolicySummary summaryValues={policySummary} />}
          <PolicySummaryContainerWithTitleDescription
            title="Renewal"
            description="To make sure you have continuous cover under your policy, we will aim to automatically renew (auto-renew) your policy when it runs out, unless you tell us not to. Each year we will notify you by email 5 weeks before the renewal date of your policy, and tell you about any changes to the price or the policy terms & conditions. If you do not want us to auto-renew your policy please contact us on 0344 273 2777 or info@insureyourpaws.co.uk after you have purchased your policy."
          />
        </Stack>

        <Stack spacing={{ base: '16px', lg: '12px' }} direction={{ base: 'column', lg: 'row' }}>
          {pricingSummary && <PolicyPricingSummary summaryValues={pricingSummary} />}
          <PolicySummaryContainerWithTitleDescription
            title="Demands and Needs Statement"
            description={`This lifetime product meets the demands and needs of pet owners seeking insurance which covers their pet against medical illnesses and injuries, up to a set amount each year for as long as the policy remains in force - as well as ${
              isDogsSelectedPetType ? 'third party liability and' : ''
            } a package of additional covers including theft/straying and overseas travel`}
          />
        </Stack>

        <ProductButtons />

        <Footer />
      </VStack>

      <FloatingContainer>
        <ActionButton label="Confirm" onPress={confirmPolicySummary} />
      </FloatingContainer>
    </ScreenWithTitleContainer>
  );
};
