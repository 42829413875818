import React from 'react';
import { useField } from 'formik';

import { getBreedList } from '@joinfluffy/common';

import { FeatureFlag } from '@shared/models/featureFlags';

import { useFeatureFlag } from '~/hooks/useFeatureFlag';

import type { InsuranceFormValues } from '~/components/insurance-questionnaire/insurance-questionnaire.schema';
import type { AutoCompleteOption } from '~/components/form/formik.schema';
import { CAT_OR_DOG_FIELD } from '~/components/insurance-questionnaire/insurance-questionnaire.schema';

// be sure you call this hook inside the Insurance Formik provider
// otherwise you will have an error
export function useFormikInsuranceAvailableBreedOptions(options?: {
  needPickMixedBreed?: boolean;
}): AutoCompleteOption[] {
  const hasExtraCrossBreedFeature = useFeatureFlag(FeatureFlag.extraCrossBreeds, false)!;

  const needPickMixedBreed = options?.needPickMixedBreed ?? false;

  const [formikField] = useField<InsuranceFormValues[typeof CAT_OR_DOG_FIELD]>(CAT_OR_DOG_FIELD);

  return React.useMemo(
    () =>
      getBreedList({
        catOrDog: formikField.value,
        needPickMixedBreed,
        flags: { hasExtraCrossBreedFeature },
      }),
    [formikField.value, needPickMixedBreed, hasExtraCrossBreedFeature],
  );
}
