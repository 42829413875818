import React from 'react';
import { Box, BoxProps, Text } from '@chakra-ui/react';

import { Colors } from '~/constants/colors';

interface PolicySummaryContainerWithTitleProps extends React.PropsWithChildren {
  title: string;
  style?: BoxProps;
}

const DEFAULT_STYLES: BoxProps = {
  width: { base: '336px', lg: '336px' },
  padding: '16px 20px 16px 20px',
  bg: Colors.Beige,
};

export const PolicySummaryContainerWithTitle: React.FC<PolicySummaryContainerWithTitleProps> =
  function PolicySummaryContainerWithTitle({ children, title, style }) {
    const containerStyle: BoxProps = {
      ...DEFAULT_STYLES,
      ...style,
    };

    return (
      <Box {...containerStyle}>
        <Box>
          <Text textAlign="left" fontSize="14px" lineHeight="32px" fontWeight="900" color={Colors.Black}>
            {title}
          </Text>
        </Box>
        {children}
      </Box>
    );
  };
