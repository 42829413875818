import React from 'react';
import { Box, Stack, StackProps } from '@chakra-ui/react';
import { SystemProps } from '@chakra-ui/system';

import { TitleText } from '~/components/common/TitleText';
import { ActionButton } from '~/components/common/ActionButton';

export interface ScreenWithTitleContainerProps extends React.PropsWithChildren {
  title: string;
  buttonLabel?: string;
  isButtonDisabled?: boolean;
  isActionButtonHidden?: boolean;
  style?: StackProps;
  onActionButtonClick?: () => void;
  spacing?: SystemProps['margin'];
}

export const ScreenWithTitleContainer: React.FC<ScreenWithTitleContainerProps> = function ScreenWithTitleContainer({
  children,
  title,
  buttonLabel,
  isButtonDisabled,
  isActionButtonHidden,
  style,
  onActionButtonClick,
  spacing,
}) {
  return (
    <Stack spacing={spacing ?? '52px'} align="center" justify="center" {...style}>
      <Box>
        <TitleText text={title} />
      </Box>
      {children}
      {!isActionButtonHidden && buttonLabel && onActionButtonClick && (
        <ActionButton label={buttonLabel} onPress={onActionButtonClick} disabled={isButtonDisabled} />
      )}
    </Stack>
  );
};
