import { CatOrDog, PetGender } from '@joinfluffy/common';

export const LAST_QUESTION_STEP_IDX = 10;

export type InsuranceFormQuestionType =
  | 'PET_NAME'
  | 'PET_TYPE'
  | 'PET_GENDER'
  | 'PET_BREED'
  | 'PET_BIRTH_DATE'
  | 'IS_PET_NEUTERED'
  | 'PET_VALUE'
  | 'EMAIL'
  | 'POST_CODE'
  | 'PET_POLICY_START_DATE'
  | 'IS_PET_OWNER_OVER_EIGHTEEN';

export const POLICY_START_DATE_FIELD = 'policyStartDateField';
export const EMAIL_FIELD = 'email';
export const CONFIRM_EMAIL_FIELD = 'confirmEmail';
export const IS_PET_DATE_OF_BIRTH_DD_MISSED_FIELD = 'isPetDateOfBirthDdMissed';
export const IS_PET_DATE_OF_BIRTH_DD_MM_MISSED_FIELD = 'isPetDateOfBirthDdMmMissed';
export const PET_DATE_OF_BIRTH_FIELD = 'dateOfBirth';
export const PET_NAME_FIELD = 'petName';
export const CAT_OR_DOG_FIELD = 'catOrDog';
export const GENDER_FIELD = 'gender';
export const PET_BREED_FIELD = 'petBreed';
export const IS_NEUTERED_FIELD = 'isNeutered';
export const IS_OVER_EIGHTEEN_FIELD = 'isOverEighteen';
export const PET_VALUE_FIELD = 'petValue';
export const POSTCODE_FIELD = 'postCode';

export type InsuranceFormField =
  | typeof POLICY_START_DATE_FIELD
  | typeof EMAIL_FIELD
  | typeof CONFIRM_EMAIL_FIELD
  | typeof PET_DATE_OF_BIRTH_FIELD
  | typeof PET_NAME_FIELD
  | typeof CAT_OR_DOG_FIELD
  | typeof GENDER_FIELD
  | typeof PET_BREED_FIELD
  | typeof IS_NEUTERED_FIELD
  | typeof PET_VALUE_FIELD
  | typeof POSTCODE_FIELD
  | typeof IS_OVER_EIGHTEEN_FIELD;

export const CURRENT_QUESTION_STEP_IDX = 'currentQuestionStepIdx';

export type InsuranceFormValues = {
  [PET_NAME_FIELD]: string;
  [CAT_OR_DOG_FIELD]: CatOrDog | null;
  [GENDER_FIELD]: PetGender | null;
  [PET_BREED_FIELD]: string;
  [IS_NEUTERED_FIELD]: boolean | null;
  [PET_VALUE_FIELD]: string;
  [POSTCODE_FIELD]: string; // example: E3 4EF
  [IS_PET_DATE_OF_BIRTH_DD_MISSED_FIELD]: boolean;
  [IS_PET_DATE_OF_BIRTH_DD_MM_MISSED_FIELD]: boolean;
  [PET_DATE_OF_BIRTH_FIELD]: string;
  [POLICY_START_DATE_FIELD]: string | null;
  [EMAIL_FIELD]: string;
  [CONFIRM_EMAIL_FIELD]: string;
  [IS_OVER_EIGHTEEN_FIELD]: boolean | null;

  [CURRENT_QUESTION_STEP_IDX]: number;
};

export const FORM_INITIAL_VALUE: InsuranceFormValues = {
  [PET_NAME_FIELD]: '',
  [CAT_OR_DOG_FIELD]: null,
  [GENDER_FIELD]: null,
  [PET_BREED_FIELD]: '',
  [IS_NEUTERED_FIELD]: null,
  [PET_VALUE_FIELD]: '',
  [POSTCODE_FIELD]: '',
  [IS_PET_DATE_OF_BIRTH_DD_MISSED_FIELD]: false,
  [IS_PET_DATE_OF_BIRTH_DD_MM_MISSED_FIELD]: false,
  [PET_DATE_OF_BIRTH_FIELD]: '',
  [POLICY_START_DATE_FIELD]: null,
  [EMAIL_FIELD]: '',
  [CONFIRM_EMAIL_FIELD]: '',
  [IS_OVER_EIGHTEEN_FIELD]: null,

  [CURRENT_QUESTION_STEP_IDX]: 0,
};
