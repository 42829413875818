import { useField } from 'formik';

import { PET_NAME_FIELD } from '~/components/insurance-questionnaire/insurance-questionnaire.schema';
import type { InsuranceFormValues } from '~/components/insurance-questionnaire/insurance-questionnaire.schema';

// be sure you call this hook inside the Formik provider
// otherwise you will have an error
export function useFormikInsuranceFormPetName(): string {
  const [formikField] = useField<InsuranceFormValues[typeof PET_NAME_FIELD]>(PET_NAME_FIELD);

  return formikField.value ?? 'pet';
}
