import * as yup from 'yup';
import { AnyObject } from 'yup/lib/types';

import { petBreedValueList, petTypeValueList, petGenderValueList } from '@joinfluffy/common';

import { UK_POSTCODE_REGEXP } from '@shared/constants/regexp';
import { DEFAULT_MAX_STRING_LENGTH, POSTCODE_MAX_STRING_LENGTH } from '@shared/constants/validation';

import {
  CAT_OR_DOG_FIELD,
  CONFIRM_EMAIL_FIELD,
  EMAIL_FIELD,
  GENDER_FIELD,
  IS_NEUTERED_FIELD,
  IS_OVER_EIGHTEEN_FIELD,
  IS_PET_DATE_OF_BIRTH_DD_MISSED_FIELD,
  IS_PET_DATE_OF_BIRTH_DD_MM_MISSED_FIELD,
  PET_BREED_FIELD,
  PET_DATE_OF_BIRTH_FIELD,
  PET_NAME_FIELD,
  PET_VALUE_FIELD,
  POLICY_START_DATE_FIELD,
  POSTCODE_FIELD,
} from '~/components/insurance-questionnaire/insurance-questionnaire.schema';

import {
  checkPetDateFormatIsCorrect,
  checkPetDateIsValid,
  checkPetIsNotTooOldForInsurance,
  checkPetIsOldEnoughForInsurance,
} from '~/helpers/validation/petDateValidation';
import { getTodaysDate } from '~/helpers/date';

const CURRENCY_REGEXP = /(^£\d+)/;

export const insuranceQuestionnaireValidationSchema = yup.object({
  [PET_NAME_FIELD]: yup
    .string()
    .trim()
    .required('Please provide the name of your pet')
    .max(DEFAULT_MAX_STRING_LENGTH, `Pet name should not be longer than ${DEFAULT_MAX_STRING_LENGTH} symbols`),
  [CAT_OR_DOG_FIELD]: yup
    .string()
    .trim()
    .oneOf(petTypeValueList, 'Pet type must be cat or dog')
    .nullable()
    .required('Please indicate if you have a dog or a cat'),
  [GENDER_FIELD]: yup.string().trim().oneOf(petGenderValueList).nullable().required('Pet gender is required'),

  // Important: here we use **FullBreedsList** inside (with the extra cross-breeds)
  // because we can use feature flags inside the components only (and provide it to helpers from these components)
  // here we have "global" variables
  // todo [FeatureFlag.extraCrossBreeds]: remove comment once we will decide to apply the FeatureFlag.extraCrossBreeds
  //  for all customers and we can remove the behaviour without this flag
  [PET_BREED_FIELD]: yup.string().trim().oneOf(petBreedValueList).required('Please provide the Breed of your pet'),

  [IS_PET_DATE_OF_BIRTH_DD_MISSED_FIELD]: yup.boolean(), // it's a field to control inner state of the validation
  [IS_PET_DATE_OF_BIRTH_DD_MM_MISSED_FIELD]: yup.boolean(), // it's a field to control inner state of the validation
  [PET_DATE_OF_BIRTH_FIELD]: yup
    .string()
    .trim()
    .required('This question is required')
    .test('Check that string is a valid date', 'Please enter valid date of birth', checkPetDateIsValid)
    .test('Check that date has a valid format', checkPetDateFormatIsCorrect)
    .test(
      'Check that pet is not too old for insurance',
      "Your pet's age exceeds the maximum age",
      checkPetIsNotTooOldForInsurance,
    )
    .test(
      'Check that pet is old enough for insurance',
      "Your pet's age is below the minimum age",
      checkPetIsOldEnoughForInsurance,
    ),
  [IS_NEUTERED_FIELD]: yup.bool().nullable().required('This question is required'),
  [PET_VALUE_FIELD]: yup
    .string()
    .trim()
    .matches(CURRENCY_REGEXP, 'Please enter a valid value')
    .nullable()
    .required('This question is required'),
  [POSTCODE_FIELD]: yup
    .string()
    .trim()
    .max(POSTCODE_MAX_STRING_LENGTH, `Postcode should not be longer than ${POSTCODE_MAX_STRING_LENGTH} symbols`)
    .required(`Please provide the postcode`)
    .matches(UK_POSTCODE_REGEXP, 'Please enter valid UK post code e.g. E3 4EF'),
  [POLICY_START_DATE_FIELD]: yup
    .date()
    .nullable()
    .required('Please provide the date you want your policy to start')
    .min(getTodaysDate(), 'Policy start date must be not earlier than today')
    .max(getTodaysDate().add(30, 'days'), 'Policy start date must be not later than 30 days'),
  [EMAIL_FIELD]: yup
    .string()
    .trim()
    .max(DEFAULT_MAX_STRING_LENGTH, `Email should not be longer than ${DEFAULT_MAX_STRING_LENGTH} symbols`)
    .email('Invalid email format')
    .required('Please provide your email'),
  [CONFIRM_EMAIL_FIELD]: yup
    .string()
    .trim()
    .email('Invalid email format')
    .test(
      'Check email field is filled',
      'Please provide email first',
      (_, context?: AnyObject) => context && context.parent[EMAIL_FIELD],
    )
    .test(
      'Check that emails equal',
      'Please provide a matching email',
      (value?: string, context?: AnyObject) =>
        context && context.parent[EMAIL_FIELD] && context.parent[EMAIL_FIELD] === value,
    )
    .required('Please confirm your email'),
  [IS_OVER_EIGHTEEN_FIELD]: yup.bool().nullable().required('This question is required'),
});
