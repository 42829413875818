import React from 'react';
import { useField } from 'formik';
import { Stack } from '@chakra-ui/react';
import * as Sentry from '@sentry/react';

import { FormikInput } from '~/components/form/formik-input/FormikInput';
import { ScreenWithTitleContainer } from '~/components/common/ScreenWithTitleContainer';
import { CONFIRM_EMAIL_FIELD, EMAIL_FIELD } from '~/components/insurance-questionnaire/insurance-questionnaire.schema';

import { useKeyEnterScreenHandler } from '~/hooks/common/useKeyEnterScreenHandler';
import { useIsOwnerEmailFormActionButtonEnabled } from '~/hooks/useIsOwnerEmailFormActionButtonEnabled';

export interface InsuranceEmailQuestionProps {
  openNextStep: () => void;
}

export const InsuranceEmailQuestion: React.FC<InsuranceEmailQuestionProps> = function InsuranceEmailQuestion({
  openNextStep,
}) {
  const [emailField] = useField(EMAIL_FIELD);
  const isActionButtonEnabled = useIsOwnerEmailFormActionButtonEnabled();

  useKeyEnterScreenHandler({ actionToCall: openNextStep, isActionDisabled: !isActionButtonEnabled });

  return (
    <ScreenWithTitleContainer
      title="We’re nearly there. Please provide your email"
      buttonLabel="Confirm"
      isButtonDisabled={!isActionButtonEnabled}
      onActionButtonClick={() => {
        const sentryUser: Sentry.User = {
          email: emailField.value,
        };

        Sentry.setUser(sentryUser);
        openNextStep();
      }}
    >
      <Stack spacing="12px">
        <FormikInput name={EMAIL_FIELD} placeholder="Email" type="email" variant="outline" />
        <FormikInput name={CONFIRM_EMAIL_FIELD} placeholder="Confirm Email" type="email" variant="outline" />
      </Stack>
    </ScreenWithTitleContainer>
  );
};
