import { PolicyCodes } from '@shared/constants/policyCodes';

import {
  PolicyDisplayFormField,
  PolicyDisplayFormQuestionType,
} from '~/components/insurance-policy-display-form/insurance-policy-display-questionnaire.schema';
import {
  InsuranceFormField,
  InsuranceFormQuestionType,
} from '~/components/insurance-questionnaire/insurance-questionnaire.schema';

import { AnalyticsEvent } from '~/constants/analyticsEvent';

export const INSURANCE_FORM_TRACKING_EVENTS_CONFIG: Record<InsuranceFormQuestionType, AnalyticsEvent> = {
  PET_NAME: AnalyticsEvent.QuotePetNameConfirmed,
  PET_TYPE: AnalyticsEvent.QuotePetTypeConfirmed,
  PET_GENDER: AnalyticsEvent.QuotePetGenderConfirmed,
  PET_BREED: AnalyticsEvent.QuotePetBreedConfirmed,
  PET_BIRTH_DATE: AnalyticsEvent.QuotePetAgeFullConfirmed,
  PET_VALUE: AnalyticsEvent.QuotePetPurchaseValueConfirmed,
  IS_PET_NEUTERED: AnalyticsEvent.QuotePetIsSpayedConfirmed,
  POST_CODE: AnalyticsEvent.QuoteOwnerPostcodeConfirmed,
  EMAIL: AnalyticsEvent.QuoteOwnerEmailConfirmed,
  PET_POLICY_START_DATE: AnalyticsEvent.QuotePolicyStartDateConfirmed,
  IS_PET_OWNER_OVER_EIGHTEEN: AnalyticsEvent.QuoteOwnerOverEighteenConfirmed,
};

export const POLICY_DISPLAY_FORM_TRACKING_EVENTS_CONFIG: Record<PolicyDisplayFormQuestionType, AnalyticsEvent> = {
  HAS_ALREADY_A_POLICY: AnalyticsEvent.QuoteHasAlreadyInsurancePolicy,
  POLICY_SUMMARY_CONFIRMATION: AnalyticsEvent.QuotePolicySummaryConfirmed,
  POLICY_DECLARATION: AnalyticsEvent.QuotePolicyDeclarationConfirmed,
  OWNER_INFO: AnalyticsEvent.QuoteOwnerInfoConfirmed,
  OWNER_ADDRESS: AnalyticsEvent.QuoteOwnerAddressConfirmed,
  IS_PET_MICROCHIPPED: AnalyticsEvent.QuotePetIsMicrochipedConfirmed,
  DATE_OF_OWNER_BIRTH: AnalyticsEvent.QuoteOwnerDateOfBirthConfirmed,
};

export const INSURANCE_FORM_IDENTITY_FIELDS_CONFIG: Record<
  InsuranceFormQuestionType,
  InsuranceFormField | InsuranceFormField[]
> = {
  PET_NAME: 'petName',
  PET_TYPE: 'catOrDog',
  PET_GENDER: 'gender',
  PET_BREED: 'petBreed',
  PET_BIRTH_DATE: 'dateOfBirth',
  IS_PET_NEUTERED: 'isNeutered',
  PET_VALUE: 'petValue',
  POST_CODE: 'postCode',
  EMAIL: ['email', 'confirmEmail'],
  IS_PET_OWNER_OVER_EIGHTEEN: 'isOverEighteen',
  PET_POLICY_START_DATE: 'policyStartDateField',
};

export const POLICY_DISPLAY_FORM_IDENTITY_FIELDS_CONFIG: Record<
  PolicyDisplayFormQuestionType,
  PolicyDisplayFormField | PolicyDisplayFormField[]
> = {
  HAS_ALREADY_A_POLICY: 'hasAlreadyInsurancePolicy',
  POLICY_SUMMARY_CONFIRMATION: 'isPolicySummaryConfirmed',
  POLICY_DECLARATION: ['arePolicyAndBusinessDocumentRead', 'areTermsOfCoverConfirmed'],
  OWNER_INFO: ['firstName', 'lastName', 'title', 'phoneNumber'],
  OWNER_ADDRESS: ['addressLine1', 'addressLine2', 'addressLine3'],
  IS_PET_MICROCHIPPED: 'isMicroChipped',
  DATE_OF_OWNER_BIRTH: 'dateOfOwnerBirth',
};

export const POLICY_SELECTION_TRACKING_EVENTS_CONFIG: Record<PolicyCodes, AnalyticsEvent> = {
  LT1: AnalyticsEvent.QuotePricingPlanLt1Confirmed,
  LT2: AnalyticsEvent.QuotePricingPlanLt2Confirmed,
  LT3: AnalyticsEvent.QuotePricingPlanLt3Confirmed,
};
