import React from 'react';
import { Box, Text } from '@chakra-ui/react';

import { Colors } from '~/constants/colors';

interface PolicyPriceLabelProps {
  policyPeriodTitle: string;
  price: string;
}

export const PolicyPriceLabel: React.FC<PolicyPriceLabelProps> = function PlanPriceLabel({ policyPeriodTitle, price }) {
  return (
    <Box>
      <Text
        fontSize={{ base: '30px', lg: '22px' }}
        lineHeight={{ base: '32px', lg: '36px' }}
        fontWeight="700"
        color={Colors.Brown}
      >
        {policyPeriodTitle}: {price}
      </Text>
    </Box>
  );
};
