import React from 'react';

import { useUiStateContextPatchUiState } from '~/hooks/useUiStateContextUpdateUi';

export function useHideFooter() {
  const patchUiState = useUiStateContextPatchUiState();

  React.useEffect(() => {
    patchUiState && patchUiState({ isFooterHidden: true });

    return () => {
      patchUiState && patchUiState({ isFooterHidden: false });
    };
  }, [patchUiState]);
}
