import { FluffyAppBenefits, PolicyBenefits } from '@shared/data/policyBenefits';
import { ProductBenefitCode } from '@shared/models/product';

import { PerkInfo } from '~/models/ui';

export const PerkInfoConfig: Record<ProductBenefitCode | FluffyAppBenefits, PerkInfo> = {
  VET_FEES: {
    title: PolicyBenefits.VetFees,
    description:
      'Lifetime Vet Fee cover is for illnesses and injuries. Subject to the underwriter offering renewal and premiums being paid on time with no break in cover, illnesses and injuries will continue to be covered. Pre-existing conditions are excluded from cover. Terms and conditions apply.',
    imgSrc: '/images/dogImages/08-heart-dog/heart-dog-1080.png',
    smallImgSrc: '/images/dogImages/08-heart-dog/heart-dog-208.png',
  },
  EXCESS: {
    title: PolicyBenefits.Excess,
    description:
      'The amount you are required to pay towards the cost of any veterinary fees, including complementary medicine and prescription diet as part of a claim made under the policy. The excesses applicable are shown on your policy schedule of insurance and will be deducted from the claims settlement',
    imgSrc: '/images/dogImages/01-clever-dog/01-clever-dog-1080.png',
    smallImgSrc: '/images/dogImages/01-clever-dog/01-clever-dog-208.png',
  },
  ACCIDENT_DEATH: {
    title: PolicyBenefits.DeathFromAccident,
    description:
      'In the event that your pet dies from injury or due to humane destruction as a result of an injury, this will provide cover up to the limit detailed in your policy schedule.',
    imgSrc: '/images/dogImages/20-jumping-dog/20-jumping-dog-1080.png',
    smallImgSrc: '/images/dogImages/20-jumping-dog/20-jumping-dog-200.png',
  },
  THIRD_PARTY_LIABILITY: {
    title: PolicyBenefits.ThirdPartyLiabilityDogsOnly,
    description:
      'This will provide cover up to the limit detailed in your policy schedule if you become legally liable to pay a compensation for injury or accidental damage to property not owned by you or in the custody or control of you caused by your dog whilst in the UK',
    imgSrc: '/images/dogImages/23-hazard-dog/23-hazard-dog-1080.png',
    smallImgSrc: '/images/dogImages/23-hazard-dog/23-hazard-dog-208.png',
  },
  ILLNESS_DEATH: {
    title: 'Death from Illness / Accident',
    description:
      'In the event that your pet dies from illness or due to humane destruction as a result of an illness, this will provide cover up to the limit detailed in your policy schedule.',
    imgSrc: '/images/dogImages/38-dog-bubbles/38-dog-bubbles-1080.png',
    smallImgSrc: '/images/dogImages/38-dog-bubbles/38-dog-bubbles-200.png',
  },
  BOARDING: {
    title: PolicyBenefits.BoardingFees,
    description:
      'This will provide cover up to the limit detailed in your policy schedule for boarding kennel or cattery fees up to in the event that you or a member of your immediate family permanently residing with you, is hospitalised on medical advice for a period exceeding four consecutive days, or your home is uninhabitable.',
    imgSrc: '/images/dogImages/33-lead-handling-dog/33-lead-handling-dog-1080.png',
    smallImgSrc: '/images/dogImages/33-lead-handling-dog/33-lead-handling-dog-208.png',
  },
  THEFT: {
    title: PolicyBenefits.TheftOrStraying,
    description:
      'This will provide cover for the purchase price of your pet up to the limit detailed in your policy schedule, in respect of permanent loss due to your pet being lost or stolen and if no recovery has been made after 45 days, despite advertising and offering a reward.',
    imgSrc: '/images/dogImages/04-searching-dog/04-searching-dog-1080.png',
    smallImgSrc: '/images/dogImages/04-searching-dog/04-searching-dog-500.png',
  },
  HOLIDAY_CANCELLATION: {
    title: PolicyBenefits.HolidayCancellation,
    description:
      'This will provide cover for any non-recoverable cancellation costs up to the limit detailed in your policy schedule should your pet require life-saving surgery whilst you are away on holiday or within 7 days of your departure',
    imgSrc: '/images/dogImages/25-blanket-dog/blanket-dog-1080.png',
    smallImgSrc: '/images/dogImages/25-blanket-dog/blanket-dog-208.png',
  },
  ADVERTISING: {
    title: 'Missing Pet Advertising & Reward',
    description:
      'If your pet is lost or stolen, we will reimburse you for advertising costs and for a suitable reward to be offered for recovery of your pet up to the limit shown in your policy schedule. The reward reimbursement forms part of the overall limit.',
    imgSrc: '/images/dogImages/45-search-dog/45-search-dog-1080.png',
    smallImgSrc: '/images/dogImages/45-search-dog/45-search-dog-208.png',
  },
  PET_PASSPORT: {
    title: PolicyBenefits.LossOfPetPassport,
    description:
      'This will cover the cost of replacing lost pet passport documents or if your pet is quarantined up to the limit specified in your policy schedule',
    imgSrc: '/images/dogImages/04-searching-dog/04-searching-dog-1080.png',
    smallImgSrc: '/images/dogImages/04-searching-dog/04-searching-dog-500.png',
  },
  OVERSEAS_TRAVEL: {
    title: PolicyBenefits.OverseasTravel,
    description:
      'In the event that your pet requires veterinary treatment whilst temporarily located (for no more than 30 days per trip) in a Member Country of the PETS Travel Scheme (Excluding non EU Listed countries as defined by DEFRA) we will provide cover up to the limit shown on your Policy Schedule.',
    imgSrc: '/images/dogImages/03-goal-achieving-dog/03-goal-achieving-dog-1080.png',
    smallImgSrc: '/images/dogImages/03-goal-achieving-dog/03-goal-achieving-dog-208.png',
  },
  QUARANTINE: {
    title: PolicyBenefits.Quarantine,
    description:
      'This will cover quarantine kennelling costs and costs incurred in obtaining a replacement health certificate for your pet as detailed in your policy schedule',
    imgSrc: '/images/dogImages/29-jumping-over-dog/29-jumping-over-dog-1080.png',
    smallImgSrc: '/images/dogImages/29-jumping-over-dog/29-jumping-over-dog-208.png',
  },
  EMERGENCY_ABROAD: {
    title: PolicyBenefits.EmergencyExpensesCoverAbroad,
    description:
      'This will provide cover for the cost of a replacement Pet Passport should the original become lost during your trip up to the limit detailed in your policy schedule',
    imgSrc: '/images/dogImages/11-welcome-dog/11-welcome-dog-1080.png',
    smallImgSrc: '/images/dogImages/11-welcome-dog/11-welcome-dog-208.png',
  },
  ACCIDENTAL_DAMAGE: {
    title: PolicyBenefits.AccidentalDamage,
    description:
      'This will provide cover should your pet cause damage to a third parties’ personal property whilst visiting their property',
    imgSrc: '/images/dogImages/23-hazard-dog/23-hazard-dog-1080.png',
    smallImgSrc: '/images/dogImages/23-hazard-dog/23-hazard-dog-208.png',
  },
  VET_HELPLINE: {
    title: '24/7 Vet Helpline',
    description:
      'The 24/7 Vet Helpline, gives policyholders access to our national network of RCVS registered veterinary nurses, 24 hours a day, 365 days a year.',
    imgSrc: '/images/dogImages/07-health-dog/07-health-dog-1080.png',
    smallImgSrc: '/images/dogImages/07-health-dog/07-health-dog-208.png',
  },
  [FluffyAppBenefits.FreeDogTraining]: {
    title: FluffyAppBenefits.FreeDogTraining,
    description: 'Get access to over 100 training courses from leading experts',
    imgSrc: '/images/dogImages/01-clever-dog/01-clever-dog-1080.png',
    smallImgSrc: '/images/dogImages/01-clever-dog/01-clever-dog-208.png',
  },
  [FluffyAppBenefits.VetAndBehaviouristSupport]: {
    title: FluffyAppBenefits.VetAndBehaviouristSupport,
    description: 'Talk to a vet or a behaviourist for free anytime and anywhere',
    imgSrc: '/images/dogImages/07-health-dog/07-health-dog-1080.png',
    smallImgSrc: '/images/dogImages/07-health-dog/07-health-dog-208.png',
  },
  [FluffyAppBenefits.FoodDiscount]: {
    title: FluffyAppBenefits.FoodDiscount,
    description: 'Access special deals on pet food, treats and more\n',
    imgSrc: '/images/dogImages/14-eating-dog/14-eating-dog-1080.png',
    smallImgSrc: '/images/dogImages/14-eating-dog/14-eating-dog-208.png',
  },
  [FluffyAppBenefits.AccessToExclusiveCommunity]: {
    title: FluffyAppBenefits.AccessToExclusiveCommunity,
    description: 'Join a community of pet parents and get support from like-minded people',
    imgSrc: '/images/dogImages/09-dreamer-dog/09-dreamer-dog-1080.png',
    smallImgSrc: '/images/dogImages/09-dreamer-dog/09-dreamer-dog-208.png',
  },
  [FluffyAppBenefits.FreePersonalizedPuppyGrowthPlans]: {
    title: FluffyAppBenefits.FreePersonalizedPuppyGrowthPlans,
    description: 'Get a week-by-week growth plan personalised to your pup, created by world-leading experts',
    imgSrc: '/images/dogImages/03-goal-achieving-dog/03-goal-achieving-dog-1080.png',
    smallImgSrc: '/images/dogImages/03-goal-achieving-dog/03-goal-achieving-dog-208.png',
  },
  [FluffyAppBenefits.DiscountOnPetServices]: {
    title: FluffyAppBenefits.DiscountOnPetServices,
    description: 'Access special deals on pet food, treats and more\n',
    imgSrc: '/images/dogImages/33-lead-handling-dog/33-lead-handling-dog-1080.png',
    smallImgSrc: '/images/dogImages/33-lead-handling-dog/33-lead-handling-dog-208.png',
  },
};

export const AppPerkImageConfig: Record<FluffyAppBenefits, string> = {
  [FluffyAppBenefits.FreeDogTraining]: '/images/dogImages/01-clever-dog/01-clever-dog-208.png',
  [FluffyAppBenefits.VetAndBehaviouristSupport]: '/images/dogImages/07-health-dog/07-health-dog-208.png',
  [FluffyAppBenefits.FoodDiscount]: '/images/dogImages/14-eating-dog/14-eating-dog-208.png',
  [FluffyAppBenefits.AccessToExclusiveCommunity]: '/images/dogImages/09-dreamer-dog/09-dreamer-dog-208.png',
  [FluffyAppBenefits.FreePersonalizedPuppyGrowthPlans]:
    '/images/dogImages/03-goal-achieving-dog/03-goal-achieving-dog-208.png',
  [FluffyAppBenefits.DiscountOnPetServices]: '/images/dogImages/33-lead-handling-dog/33-lead-handling-dog-208.png',
};
